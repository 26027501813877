import React, { useState, useEffect } from "react";
import DotsMenu from "../DotsMenu";
import { LabelField } from "../../components/fields";
import {
    Anchor,
    Box,
    Item,
    Text,
    Icon,
    List,
    Image,
    Heading,
    Button,
    Input
  } from "../../components/elements";
  import Spinner from 'react-bootstrap/Spinner';
  import { useLocation } from 'react-router-dom';
  import api from "../../data/master/api.json";
  import ReactSimplyCarousel from 'react-simply-carousel';
  import { CustomerReview, RatingAnalytics } from "../../components/review";
  import { Breadcrumb, DivideTitle } from "../../components";
  import PageLayout from "../../layouts/PageLayout";
  import LabelTextarea from "../../components/fields/LabelTextarea";
  import CardLayout from "../../components/cards/CardLayout";
  import data from "../../data/master/customerView.json";
  import { useNavigate } from 'react-router-dom';

export default function ImageCard({ variant, trend, number, title, icon, percent, compare, is_editable = false, style, dotsMenu, saveFunction }) {
    
  const [selectedImage, setSelectedImage] = useState(null);
  const [busy, setBusy] = useState(true);
  const [token, setToken] = useState(null);
  const [images, setImages] = useState(null);
  const [imageCount, setImageCount] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  const search = useLocation().search;
  const customer_name = new URLSearchParams(search).get('name')
  const folder = 'images';

   function api_call_get_token() {
    fetch(api.base_url + api.get_token, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    }).then((response) => response.json())
    .then((data) => {
      setToken(data.token);
    })
    .catch((err) => {
        console.log(err.message);
    });
  }



  const api_call = () => {
    if (token === null){
      return;
    }
    fetch(api.base_url + api.file_list, {
        method: 'POST',
        body: JSON.stringify({
            name: customer_name,
            folder:folder,
            is_deleted:false
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    })
    .then((response) => response.json())
    .then((data) => {
        if (data['status'] === 'error') {
        } else if (data['status'] === 'success') {
          api_call_get_files(data['result_json']);
        }
    })
    .catch((err) => {
        console.log(err.message);
    });
}


const api_call_get_files = (files) => {
  let l_images = [];
  let count_files = files.length
  files.forEach((currentValue, index)=>{
  fetch(api.get_bucket_object_url +'images%2F' + customer_name + '%2F' + currentValue['file_name'], {
    method: 'GET',
    headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
  })
  .then((response) => response.json())
  .then((data) => {
    fetch(data['mediaLink'], {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        },
    })
    .then(resp => resp.blob())
    .then((blob ) => {
      l_images.push(URL.createObjectURL(blob));
      if(l_images.length === count_files){
        setImages(l_images);
        setImageCount(l_images.length)
      }
    })
    .catch((err) => {
        console.log(err.message);
    });
  })
  .catch((err) => {
      console.log(err.message);
    });
  });
}

useEffect(() => {
  api_call_get_token();
}, []);

useEffect(() => {
  api_call();
}, [token]);


const api_call2 = (image) => {
  setBusy(true);
  let formData = new FormData();
  formData.append("name", customer_name);    
  formData.append("folder", folder);
  formData.append(image.name, image);

  fetch(api.base_url + api.upload_file, {
      method: 'POST',
      body: formData,
      headers: {
          'Authorization': 'bearer ' + localStorage.getItem('session_id')
      },
  })
  .then((response) => response.json())
  .then((data) => {
    setBusy(false);
    api_call();
      if (data['status'] === 'error') {
          //     setAlertMessage(data['message']);
      } else if (data['status'] === 'success') {
          //    setAlertMessage('Signed in as ' + data['title']);
      }

  })
  .catch((err) => {
      console.log(err.message);
  });
}


  return (
<div>
      {images?
      <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        responsiveProps={[
          {
            itemsToShow: 0,
            itemsToScroll: 2,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
        delay={2000}
        autoplay={true}
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        
        {images.map((item, index) => (
          <Box className="mc-card" key={index}>
              <img alt="not fount" src={item} height={"128px"} />
          </Box>
        ))}
        
      </ReactSimplyCarousel>
    </div>:
    
    <Box className={`mc-ecommerce-card ${ variant }`}>
            <Icon className="mc-ecommerce-card-trend material-icons">{ trend }</Icon>
            <Box className="mc-ecommerce-card-head">
                <Heading as="h4" className="mc-ecommerce-card-meta">
                    <Text as="span">Market Photos</Text>
                    <Anchor>Upload Photos</Anchor>
                </Heading>
                
            </Box>
            <Box className="mc-ecommerce-card-foot">
                <Box className="mc-ecommerce-card-compare">
                    
                </Box>
                <DotsMenu dots={ dotsMenu.dots } dropdown={ dotsMenu.dropdown } />
            </Box>
        </Box>
    
    }
</div>
  );


}