// Reducer function to handle state updates for the counter
export const stateReducer = (
  state = { message : '',
            lead : [],
            sample_order_report : [],
            delivery_list : [],
            userList: [],
            fieldValues:[],
            fieldValuesSample:[],
            loading: false,
            country: '',
            country_code: '',
            view_lead:{},
            emails : [],
            customers_lead_list : [],
            products : []
          }, // Initial state with counter and budget
  action
) => {
  switch (action.type) {
    // Increment counter action
      case 'FETCH_LEAD':
        return {
          ...state,
          lead:action.payload.lead,
          loading:action.payload.loading,
          message: action.payload.message,
          leadColumn: action.payload.leadColumn
        }
        case 'FETCH_LEAD_WM':
            return {
              ...state,
              lead:action.payload.lead,
              delivery_list:action.payload.delivery_list,
              loading:action.payload.loading
            }
        case 'FETCH_SAMPLE_REQUEST_REPORT':
              return {
                ...state,
                sample_order_report:action.payload.sample_order_report,
                loading:action.payload.loading
              }    
      case 'FETCH_USER':
        return {
          ...state,
          userList: action.payload.user_list,
          loading: action.payload.loading,
          message: action.payload.message
        }
      case 'FETCH_PRODUCTS':
        return {
          ...state,
          products: action.payload.products,
          loading: action.payload.loading,
          message: action.payload.message
        }
      case 'FETCH_CUSTOMERS_LEADS':
        return {
          ...state,
          customers_lead_list: action.payload.customers_lead_list,
          loading: action.payload.loading,
          message: action.payload.message
        }
     case 'FETCH_LEAD_START':
            return {
              ...state,
              loading:action.payload.loading,
              message: action.payload.message
            }
    case 'SET_FIELDVALUES':
        return {
            ...state,
            fieldValues: action.payload.fieldValues
        }
        case 'SET_FIELDVALUES_SAMPLE':
          return {
              ...state,
              fieldValuesSample: action.payload.fieldValuesSample
          }
    case 'SET_COUNTRY':
      return{
        ...state,
        country: action.payload.country,
        country_code: action.payload.country_code
      }
    case 'FETCH_EMAIL':
      return {
        ...state,
        emails:action.payload.emails,
        loading:action.payload.loading,
        message: action.payload.message
      }
    default:
      return {...state};
  }
};
