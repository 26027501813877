import React, { useContext } from "react";
import { MultipleMenu, Logout } from "../components/sidebar";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import data from "../data/master/sidebar.json";

export default function Sidebar() {
    
    const { drawer } = useContext(DrawerContext);
    const permissions = JSON.parse(localStorage.getItem('permissions'));

    // Parse the JSON string into a JavaScript object
const parsedData = data;

// // Find the navigation item containing the "authorization" item
const navItem = parsedData.navs.find(nav => nav.menu.some(item => item.submenu.some(subItem => subItem.href === "/authorization")));

// If the item is found, remove it from the submenu
if (navItem && permissions && (permissions['Authorization'] !== 'Read' && permissions['Authorization'] !== 'Write') ) {
  navItem.menu.forEach(item => {
    item.submenu = item.submenu.filter(subItem => subItem.href !== "/authorization");
  });
}

// Register sub-menu
const navItem2 = parsedData.navs.find(nav => nav.menu.some(item => item.submenu.some(subItem => subItem.href === "/register")));

// // If the item is found, remove it from the submenu
if (navItem2 && permissions && (permissions['Registration'] !== 'Read' && permissions['Registration'] !== 'Write') ) {
  navItem2.menu.forEach(item => {
    item.submenu = item.submenu.filter(subItem => subItem.href !== "/register");
  });
}

// Main Group of Authorization
const navItem3 = parsedData.navs.find(nav => nav.menu.some(item => item.text === "authentication"));

// If the item is found, remove it from the menu
if (navItem3 && permissions && (permissions['Registration'] !== 'Read' && permissions['Registration'] !== 'Write')  && (permissions['Authorization'] !== 'Read' && permissions['Authorization'] !== 'Write') ) {
  navItem3.menu = navItem.menu.filter(item => item.text !== "authentication");
}

// Main Group of Leads
const navItem5 = parsedData.navs.find(nav => nav.menu.some(item => item.text === "Leads"));

// If the item is found, remove it from the menu
if (navItem5 && permissions && (permissions['Leads'] !== 'Read' && permissions['Leads'] !== 'Write')  && (permissions['Leads'] !== 'Read' && permissions['Leads'] !== 'Write') ) {
  navItem5.menu = navItem.menu.filter(item => item.text !== "Leads");
}

// Create Lead Sub Menu
const navItem6 = parsedData.navs.find(nav => nav.menu.some(item => item.submenu.some(subItem => subItem.href === "/create-lead")));

// // If the item is found, remove it from the submenu
if (navItem6 && permissions && (permissions['Leads'] !== 'Write') ) {
  navItem6.menu.forEach(item => {
    item.submenu = item.submenu.filter(subItem => subItem.href !== "/create-lead");
  });
}

// Reports
const navItem4 = parsedData.navs.find(nav => nav.menu.some(item => item.submenu.some(subItem => subItem.text.includes('DOMPRO'))));
// // If the item is found, remove it from the submenu
if (navItem4 && permissions && (permissions['Class DOM PRO'] !== 'Read' && permissions['Class DOM PRO'] !== 'Write') ) {
  navItem4.menu.forEach(item => {
    item.submenu = item.submenu.filter(subItem => !subItem.text.includes('DOMPRO'));
  });
}


// Customer Mapping sub-menu
const navItem7 = parsedData.navs.find(nav => nav.menu.some(item => item.submenu.some(subItem => subItem.href === "/customer-mapping")));

// // If the item is found, remove it from the submenu
if (navItem7 && permissions && (permissions['Customer Mapping'] !== 'Read' && permissions['Customer Mapping'] !== 'Write') ) {
  navItem7.menu.forEach(item => {
    item.submenu = item.submenu.filter(subItem => subItem.href !== "/customer-mapping");
  });
}

// Main Group of Accounting Reports
const navItem8 = parsedData.navs.find(nav => nav.menu.some(item => item.text === "Accounting Reports"));

// If the item is found, remove it from the menu
if (navItem8 && permissions && (permissions['Accounting Reports'] !== 'Read' && permissions['Accounting Reports'] !== 'Write') ) {
  navItem8.menu = navItem.menu.filter(item => item.text !== "Accounting Reports");
}

    return (
        <Section as="aside" className={`mc-sidebar thin-scrolling ${ drawer ? "active" : "" }`}>
            <MultipleMenu data={ parsedData.navs }  />
            <Logout data={ data?.button } />
        </Section>
    )
}