import React, {useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/create_lead.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, fetchUser, setMessage, saveLead,saveLeadSample, setFieldValues, fetchLeadView, updateLead, setFieldValuesSample} from '../../actions';
import { Alert } from "react-bootstrap";
import { IconField, IconTextarea, LabelField, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import Modal from 'react-bootstrap/Modal';

const { v4: uuidv4 } = require('uuid');


export default function LeadList() {
    const [alertModal, setAlertModal] = useState(false);
    let fieldValues = useSelector((state) => state.fieldValues);
    let fieldValuesSample = useSelector((state) => state.fieldValuesSample);
    const message = useSelector((state) => state.message);
    const userList = useSelector((state) => state.userList);
    const titleList = userList.map((user) => user.title);
    const loading_item = useSelector((state) => state.loading);
    const [readOnly, setReadOnly] = useState(true);

    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/lead-list");
    }
    const search = useLocation().search;
    const LEAD_UUID = new URLSearchParams(search).get('leaduuid');

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(setFieldValues([]));
        dispatch(fetchLeadView(LEAD_UUID));
      }, []);

    const dispatch = useDispatch();

    const handleChange = (column_name, event) => {
        fieldValues = ({ ...fieldValues, [column_name]: event.target.value });
        dispatch(setFieldValues(fieldValues));
        console.log(fieldValues)
      };

    const handleChangeSample = (column_name, event) => {
        fieldValuesSample = ({ ...fieldValuesSample, [column_name]: event.target.value });
        dispatch(setFieldValuesSample(fieldValuesSample));
        console.log(fieldValuesSample)
      };

    const saveForm = () =>{
        console.log('its here!')
        if (!fieldValues.hasOwnProperty('Company') || !fieldValues.Company || !fieldValues.hasOwnProperty('Last_Name') || !fieldValues.Last_Name){
            dispatch(setMessage('Required fields are missing!'));
        }else{
            fieldValues = ({ ...fieldValues });
            if (!fieldValues.hasOwnProperty('Lead_Owner')){
                fieldValues = ({ ...fieldValues, Lead_Owner_UUID: localStorage.getItem('user_uuid') });
            }else{
                fieldValues = ({ ...fieldValues, Lead_Owner_UUID: userList.find(item => item.title === fieldValues.Lead_Owner).user_uuid });
                delete fieldValues.Lead_Owner
            }
            dispatch(updateLead(fieldValues));
            // setTimeout(back_clicked, 2000); // Delay in milliseconds
        }
    }

    const saveFormSample = () =>{
        if (!fieldValuesSample.hasOwnProperty('Item_Count') || !fieldValuesSample.Item_Count 
        || !fieldValuesSample.hasOwnProperty('Tracking_Id') || !fieldValuesSample.Tracking_Id
        || !fieldValuesSample.hasOwnProperty('Delivery_Method') || !fieldValuesSample.Delivery_Method
        ){
            dispatch(setMessage('Required fields are missing!'));
        }else{
        fieldValuesSample = ({ ...fieldValuesSample, Sample_UUID: uuidv4(), Lead_UUID:LEAD_UUID });
        dispatch(saveLeadSample(fieldValuesSample)).then(result => {
            // Code after the save operation completes
            dispatch(setFieldValuesSample({}));
            dispatch(fetchLeadView(LEAD_UUID));
            setAlertModal(false);
        });
        
        }
    }

    function fn_build_columns(tableItem){
    const vleadColumn = (tableItem !== undefined && tableItem !== null && tableItem.length > 0) ? (Object.keys(tableItem[0])).map((th, index) => {
                return {
                accessorKey : th,
                header: th.replace(/_/g, ' '),
                size:100        
            };
            }
        ) : [];
        return vleadColumn;
    }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={'View / Edit Lead'}>
          {data?.breadcrumb_createlead.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      {readOnly===true?<Button key='btn3' className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{setReadOnly(false)}}>Edit</Button> : 
        null
      }
        <Button key='btn1' className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{back_clicked()}}>Cancel</Button>
        <Button key='btn2' className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{saveForm()}}>Save</Button>
      {(message !== '')? 
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
                :null
      }
      {loading_item? 
                <Spinner animation="border" />:null
      }

        {data?.create_lead.map((item, index) =>(
            <>
                
                <CardLayout className="p-lg-5 mb-4">
                <Row>
                    <Col xs={4} xl={2} >
                    <CardHeader title={Object.keys(item)[0]} /> 
                    </Col>
                    <Col>
                    {Object.keys(item)[0]==='Samples'?<Button key='btn2' style={{float:"left"}} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=> setAlertModal(true)}>Add</Button>:null
                    }
                    </Col>
                </Row>
                    <Row>
                        <Col xs={10} md={Object.keys(item)[0]==='Description Information'?11:5} xl={Object.keys(item)[0]==='Description Information'?9:4}>
                        {item[Object.keys(item)[0]].map((item2, index2) =>(
                            (index2+1)%2===1?
                            item2.component === 'LabelField'?
                                <LabelField readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChange(item2.column, event)}} />
                            :item2.component === 'LabelTextarea'?
                                <LabelTextarea readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChange(item2.column, event)}} />
                            :item2.component === 'DataGrid'?
                                <MaterialReactTable
                                    columns = { fn_build_columns(fieldValues[item2.column]?JSON.parse(fieldValues[item2.column]):[]) }
                                    data = { fieldValues[item2.column]?JSON.parse(fieldValues[item2.column]):[] }
                                    initialState={{
                                        pagination: {
                                        pageSize: 100,
                                        },
                                        showColumnFilters:true,
                                        density: 'compact'
                                    }}
                                    //optionally override the default column widths
                                    defaultColumn={{
                                        maxSize: 400,
                                        minSize: 1,
                                        size: 100, //default size is usually 180
                                    }}
                                    enableColumnResizing
                                    columnResizeMode="onChange" //default
                                />
                            :null
                            :null
                        ))}

                        </Col>
                        <Col xs={2} md={1} xl={1}>
                        </Col>
                        <Col xs={10} md={5} xl={4}>
                        {item[Object.keys(item)[0]].map((item2, index2) =>(
                            ((index2+1)%2)===0?
                                item2.component === 'LabelField'?
                                    <LabelField readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :item2.component === 'LabelTextarea'?
                                    <LabelTextarea readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :null
                            :null
                        ))}
                        </Col>
                    </Row>
                </CardLayout>
</>
        ))
        }

        <Modal show={ alertModal } onHide={()=> setAlertModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    {data?.create_sample.map((item, index) =>(                        
                        <CardLayout className="p-lg-5 mb-4">
                            <CardHeader title={Object.keys(item)[0]} />
                            {(message !== '')? 
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
                :null
      }
                            <Row>
                                <Col xs={12} md={12} xl={12}>
                                {item[Object.keys(item)[0]].map((item2, index2) =>(
                                    item2.component === 'LabelField'?
                                        <LabelField readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChangeSample(item2.column, event)}} />
                                    :item2.component === 'LabelTextarea'?
                                        <LabelTextarea readOnly={readOnly} label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-row mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} defaultValue={fieldValues[item2.column]} onChange={(event)=>{handleChangeSample(item2.column, event)}} />
                                    :null
                                ))}
                                </Col>
                            </Row>
                        </CardLayout>
                        ))
                    }
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setAlertModal(false)}>Close</Button>
                        <Button type="button" className="btn btn-primary" onClick={()=> {saveFormSample();}}>Create</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
    </PageLayout>
  );
}
;