import React, {useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/lead.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, setMessage, setCountry} from '../../actions';
import { Alert } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';


// Map Related Code End
export default React.memo(function LeadListMap() {

    const dispatch = useDispatch()


  const containerStyle = {
    width: 'auto',
    height: '300px'
  };
  
  const center = {
    lat: -3.745,
    lng: -38.523
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBAFrVkJjO6HGxkfFytNrySxFJREdfXAtc"
  })
  const [map, setMap] = React.useState(null)
  
  const coordinates = [[[[-69.99693762899992, 12.577582098000036], [-69.93639075399994, 12.53172435100005], [-69.92467200399994, 12.519232489000046], [-69.91576087099992, 12.497015692000076], [-69.88019771999984, 12.453558661000045], [-69.87682044199994, 12.427394924000097], [-69.88809160099993, 12.417669989000046], [-69.90880286399994, 12.417792059000107], [-69.93053137899989, 12.425970770000035], [-69.94513912699992, 12.44037506700009], [-69.92467200399994, 12.44037506700009], [-69.92467200399994, 12.447211005000014], [-69.95856686099992, 12.463202216000099], [-70.02765865799992, 12.522935289000088], [-70.04808508999989, 12.53115469000008], [-70.05809485599988, 12.537176825000088], [-70.06240800699987, 12.546820380000057], [-70.06037350199995, 12.556952216000113], [-70.0510961579999, 12.574042059000064], [-70.04873613199993, 12.583726304000024], [-70.05264238199993, 12.600002346000053], [-70.05964107999992, 12.614243882000054], [-70.06110592399997, 12.625392971000068], [-70.04873613199993, 12.632147528000104], [-70.00715084499987, 12.5855166690001], [-69.99693762899992, 12.577582098000036]]]].flat();
  // const formattedList = coordinates.map(([lat, lng]) => ({ lat, lng }));
  // console.log(formattedList)
  let countryBounds = coordinates.map(([lng, lat]) => ({ lat, lng }));
  // let countryBounds = [
  //   { lat: 25.774, lng: -80.19 },
  //   { lat: 18.466, lng: -66.118 },
  //   { lat: 32.321, lng: -64.757 },
  //   { lat: 25.774, lng: -80.19 },
  // ]
//   {Nh
//   : 
//   Zk {lo: 17.269875, hi: 17.27}
//   ci
//   : 
//   dl {lo: 14.890375, hi: 14.8905}
// };
function fn_listener(event) {
  try {
 
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: event.latLng }, (results, status) => {
      if (status === 'OK') {
        console.log(results)
        const countryResult = results.find(
          (result) => result.types.includes('country') && result.types.includes('political')
        );
    
        if (countryResult) {
          const country = countryResult.address_components[0]; // Access the 0th index
          dispatch(setCountry(country.long_name, country.short_name));
          console.log(country.long_name);
          console.log(country.short_name);
        } else {
          // Handle the case where no result matches the criteria
          console.log("Country component with types ['country', 'political'] not found in results.");
          // ... implement alternative logic if needed
        }
      } else {
        // Handle other errors
        console.error('Geocoding failed:', status);
      }
    });
  } catch (error) {
    console.error('Error in geocoding:', error);
  }
}

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])



  

  return (

                                <Col id="map">
                                {isLoaded ? (
                                  <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={2}
                                    onUnmount={onUnmount}
                                    onClick={(event)=>{fn_listener(event)}}
                                    restrictions = {['US']}
                                  >
        <Polygon
          key={'abc'}
          paths={countryBounds}
          // fillColor={'red'}
          // strokeColor={'red'}
        />

                                  </GoogleMap>
  ) : <></>
                                }
                                </Col>
  );
}
)
;
