import React, {useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/lead.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, setMessage, setCountry} from '../../actions';
import { Alert } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import LeadListMap from './LeadListMap';

// Map Related Code End
export default React.memo(function LeadList() {


    useEffect(() => {
        dispatch(fetchLead());
      }, []);

    
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/");
    }

    const dispatch = useDispatch()
    const loading_item = useSelector((state) => state.loading);
    let tableItem = useSelector((state) => state.lead);
    const delivery_list = useSelector((state) => state.delivery_list);
    const message = useSelector((state) => state.message);
    const country = useSelector((state) => state.country);
    const country_code = useSelector((state) => state.country_code);
    console.log(country_code)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async (vdata) => {
        const ws = XLSX.utils.json_to_sheet(vdata);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Excel Export' + fileExtension);
    }

const excluded_cols = ['Company_Org', 'Lead_UUID', 'Country_Code'];
const special_fileter_org = ['Company'];

console.log(tableItem)
tableItem = tableItem.map((item) => {
  // const { Lead_UUID, ...otherItemProps } = item;
  return { ...item, 
    Company: <Anchor href={"/lead-view?leaduuid=".concat(encodeURIComponent(item.Lead_UUID))} title={item.Company}>{item.Company}</Anchor>,
    Company_Org: item.Company
  };
})

console.log(tableItem)

    // More descriptive variable name
    const uniqueValuesSelect = {
        Lead_Owner_Title: new Set(),
        Prefix: new Set(),
        Lead_Source: new Set(),
        Lead_Status: new Set(),
        Class: new Set(),
        Rating: new Set(),
        Country: new Set(),
        State: new Set()
      };

      // Use forEach for iteration and direct Set modification Build Drop Down values for selection of filters
      tableItem.forEach(item => {
        for (const [key, value] of Object.entries(uniqueValuesSelect)) {
          value.add(item[key]); // Add item to the corresponding Set
        }
      });

      const filterFnCommonIncludes = (rowData, col_name, value) => {
        return rowData.original[col_name+'_Org'].toLowerCase().includes(value.toLowerCase());
      };
      const customNameSortingFunction = (row1, row2) => {
        if (row1.original.Company_Org === row2.original.Company_Org) {
            return 0;
          } else {
            return (row1.original.Company_Org).localeCompare(row2.original.Company_Org);
          }
      };
    const vleadColumn = tableItem.length > 0 ? (Object.keys(tableItem[0])).filter((th) => !excluded_cols.includes(th)).map((th, index) => {
        return {
        accessorKey : th,
        header: th.replace(/_/g, ' '),
        size:100,
        // filterVariant: Object.keys(uniqueValuesSelect).includes(th)?'select':null,
        // filterSelectOptions: Object.keys(uniqueValuesSelect).includes(th)?Array.from(uniqueValuesSelect[th]).sort().map((Value) => ({
        //     value: Value,
        //     text: Value
        //   })):null,
        filterFn: special_fileter_org.includes(th)?filterFnCommonIncludes:null,
        sortingFn: special_fileter_org.includes(th)?customNameSortingFunction:null
        };
    }
  ) : [];

  const v_delivery_list_Column = delivery_list.length > 0 ? (Object.keys(delivery_list[0])).filter((th) => th !== "Country_Code").map((th, index) => {
    return {
    accessorKey : th,
    header: th.replace(/_/g, ' '),
    size:100,
    filterVariant: Object.keys(uniqueValuesSelect).includes(th)?'select':null,
    filterSelectOptions: Object.keys(uniqueValuesSelect).includes(th)?Array.from(uniqueValuesSelect[th]).sort().map((Value) => ({
        value: Value,
        text: Value
      })):null,
    };
}
) : [];
 
 

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={'Lead Dashboard'}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      {/* <CardLayout> */}
        <Row>
        <Col xs={12} sm={12} xl={8}>
            {loading_item? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            (message && message !== '')? 
            <div className="d-flex justify-content-center">
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
            </div>: null }
                  <div className="mb-2"><b>All Leads</b>
                  <span className="m-lg-4"></span>
                  <Button key={0} className="btn btn-secondary btn-sm m-2" onClick={()=>{dispatch(fetchLead());}}>Refresh</Button>
                  <Button key={0} className="btn btn-secondary btn-sm m-2" onClick={()=>{navigate("/create-lead");}}>Add Lead</Button>
                  <Button key={0} className="btn btn-secondary btn-sm m-2" onClick={()=>{exportToExcel(tableItem);}}>Download</Button>
                  {country!== ''? <Alert key="abc123" style={{paddingRight:30}} className="btn btn-info btn-sm m-2" variant="info" onClose={() => {dispatch(setCountry('', ''));}} dismissible>{country}</Alert> : null}
                  </div>
                <MaterialReactTable
                                columns = { vleadColumn }
                                data = { tableItem.filter(lead =>
                                          lead.Country_Code === country_code || country_code === ''
                                        ) }
                                initialState={{
                                    pagination: {
                                    pageSize: 100,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                        />
            </Col>
            <Col xs={12} sm={12} xl={4}>
                                <Row>
                                  <LeadListMap />
                                </Row>
                                <Row>
                                  <Col>
                                  <MaterialReactTable
                                  columns = { v_delivery_list_Column }
                                  data = { delivery_list.filter(lead =>
                                    lead.Country_Code === country_code || country_code === ''
                                  ) }
                                  initialState={{
                                      pagination: {
                                      pageSize: 100,
                                      },
                                      showColumnFilters:true,
                                      density: 'compact'
                                  }}
                                  //optionally override the default column widths
                                  defaultColumn={{
                                      maxSize: 400,
                                      minSize: 1,
                                      size: 100, //default size is usually 180
                                  }}
                                  enableColumnResizing
                                  columnResizeMode="onChange" //default
                          />
                          </Col>
                                </Row>
            </Col>
        </Row>
    </PageLayout>
  );
}
)
;
