import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor} from "../../components/elements";
import { CustomerPriceListCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export default function CRM() {
    const [infoData, setInfoData] = useState(localStorage.getItem('infoData')?JSON.parse(localStorage.getItem('infoData')):null);
    const [infoDataSales, setInfoDataSales] = useState(localStorage.getItem('infoDataSales')?JSON.parse(localStorage.getItem('infoDataSales')):null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [budgetEdit, setBudgetEdit] = useState(false);

    const [classS, setClassS] = useState(localStorage.getItem('classS')?localStorage.getItem('classS'):'All')
    const [nameAddressS, setNameAddress] = useState(localStorage.getItem('nameAddressS')?localStorage.getItem('nameAddressS'):'')
    const [changeStats, setChangeStats] = useState(false)

    useEffect(() => {
      if(changeStats === true || infoData === null || infoDataSales === null){
        api_call();
        setChangeStats(false);
      }
  }, [changeStats]); 

  useEffect(() => {
    localStorage.setItem('infoData', JSON.stringify(infoData));
}, [infoData]);

useEffect(() => {
  localStorage.setItem('infoDataSales', JSON.stringify(infoDataSales));
}, [infoDataSales]); 

  const navigate = useNavigate();

  const api_call = () => {
    if  (localStorage.getItem("session_id")=== null)
      return
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth() + 1; // Months are zero-based
    const day = new Date().getUTCDate();
    const hour = new Date().getUTCHours();
    const minute = new Date().getUTCMinutes();
    const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);    
    const hasForwardParam = window.location.href.includes('forward=');
    if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
  
    setLoading1(true);
    fetch(api.base_url + api.main_dashboard_stats, {
      method: "POST",
      body: JSON.stringify({
        class : localStorage.getItem('classS'),
        nameAddress : localStorage.getItem('nameAddressS'),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
            setInfoData(data["result_json"]);
        }
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
      setLoading2(true);
      fetch(api.base_url + api.main_dashboard_sales_stats, {
        method: "POST",
        body: JSON.stringify({
          class : localStorage.getItem('classS'),
          nameAddress : localStorage.getItem('nameAddressS'),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "bearer " + localStorage.getItem("session_id"),
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["status"] === "error") {
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
          } else if (data["status"] === "success") {
            setInfoDataSales(data["result_json"][0]);
          }
          setLoading2(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
  };

  const budgetSaveFunction = (retVal) =>{
    let name = 'BUDGET-MTD'
    setLoading1(true);
    fetch(api.base_url + api.budget_insert, {
      method: "POST",
      body: JSON.stringify({
        name: name,
        budget_amount: parseInt(retVal.replace(/[^0-9.-]+/g,""))
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading1(false);
        if (data["status"] === "error") {
        } else if (data["status"] === "success") {
            setBudgetEdit(false);
            api_call();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

    useEffect(() => {
        setBudgetEdit(false);
      }, [infoData]);

    let vdotsMenu = {
        dots: "more_vert",
        dropdown: [
          { icon: "refresh", text: "Refresh", onClick:()=>{api_call(); } }
        ],
      };
    
      let vdotsMenuBudget = {
        dots: "more_vert",
        dropdown: [
          { icon: budgetEdit?"save":"edit", text: budgetEdit?"Save":"Edit", onClick:()=>{budgetEdit?setBudgetEdit(null):setBudgetEdit(true)} }
          ,{ icon: "refresh", text: "Refresh", onClick:()=>{api_call(); } }
        ],
      };

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.breadcrumb.title }>
                        {data?.breadcrumb.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>

                
                <Col xl={12}>
                    <CustomerPriceListCard 
                        title={data?.deals.title}
                        table={data?.deals.table}
                        setChangeStats = {setChangeStats}
                    />
                </Col>

            </Row>
        </PageLayout>
    )
}