import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/master/searchView.json";
import { CustomerInfoCard, MeetingNotesCard } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import api from "../../data/master/api.json";
import ReactHtmlParser from 'html-react-parser'; 
import Spinner from 'react-bootstrap/Spinner'
import { alignPropType } from "react-bootstrap/esm/types";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';
import { Archive, Download, Files, Upload } from 'react-bootstrap-icons';
import {stateToHTML} from 'draft-js-export-html';
import Highlighter from "react-highlight-words";

export default function CustomerView() {
    
    const search = useLocation().search;
    const [search_term, setSearchTerm] = useState(new URLSearchParams(search).get('search-term'))
    const search_term_arr = search_term.split(' ')
    let LIMIT  = (parseInt(new URLSearchParams(search).get('LIMIT')))
    let OFFSET = (parseInt(new URLSearchParams(search).get('OFFSET')))
    const itemsRef = useRef([]);
    const navigate = useNavigate();
    const [result_json, setResultJson] = useState(null);
    const [loading, setLoading] = useState(false)
    function api_call_get_token() {
      if  (localStorage.getItem("session_id")=== null)
        return
      const year = new Date().getUTCFullYear();
      const month = new Date().getUTCMonth() + 1; // Months are zero-based
      const day = new Date().getUTCDate();
      const hour = new Date().getUTCHours();
      const minute = new Date().getUTCMinutes();
      const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
      const hasForwardParam = window.location.href.includes('forward=');
      if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
      setLoading(true)
      fetch(api.base_url + api.main_search, {
        method: 'POST',
        body: JSON.stringify({
            LIMIT: LIMIT,
            OFFSET: OFFSET,
            searchTerm : search_term
        }),
          headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'Authorization': 'bearer ' + localStorage.getItem('session_id'),
              "Access-Control-Allow-Origin": "*"
          },
      }).then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
            setResultJson(data.result_json);
            setLoading(false)
        }    
  })
      .catch((err) => {
          console.log(err.message);
      });
    }

    useEffect(() => {
      api_call_get_token();
    }, [OFFSET]);
  
    const back_clicked = () => {
        navigate("/");
      }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={'Search'}>
          {data?.breadcrumbmemo.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                item.path==="/customer-view"?
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {item.text}
                </Anchor>
                :
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-lg-5 search-results">
        {result_json?.map((item, index) => (
            <Box key={1000000000+index} className="mc-card mc-mb25">
                <Anchor href={item.url_start.concat(encodeURIComponent(item.url_encode_candidate)) }>
                
                <Highlighter
                        searchWords={search_term_arr}
                        autoEscape={true}
                        textToHighlight={ item.text_output.replace(/<[^>]+>/g, '') }
                    />
                    
                </Anchor>
            </Box>
        ))}
        
        <Box key={1000000001} className="mc-card mc-mb25">
        <List className="mc-paginate-list">
                {OFFSET>0?
                <Item className="mc-paginate-item" >
                    <Anchor href={"/search?search-term="+encodeURIComponent(search_term)+"&LIMIT="+LIMIT+"&OFFSET="+(OFFSET-LIMIT)}><Icon type="chevron_left" href="/" /></Anchor>
                </Item>
                :""}
        
                {loading?
            <Spinner animation="border" className={'mc-ml10'}/>
            :""}

            {(result_json && (result_json.length === LIMIT))?
                <Item className="mc-paginate-item" >
                    <Anchor href={"/search?search-term="+encodeURIComponent(search_term)+"&LIMIT="+LIMIT+"&OFFSET="+(OFFSET+LIMIT)}><Icon type="chevron_right" /></Anchor>
                </Item>
            :""
            }
        </List>
        </Box>
       
      </CardLayout>
    </PageLayout>
  );
}
