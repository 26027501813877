import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Save } from 'react-bootstrap-icons';

  // Your data to export
  // const data = [
  //   { name: 'John', age: 28, city: 'New York' },
  //   { name: 'Jane', age: 22, city: 'San Francisco' },
  // ];

  function formatDate() {
    const today = new Date();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
    const dayOfWeek = dayNames[today.getDay()];
    const month = monthNames[today.getMonth()];
    const day = today.getDate();
    const year = today.getFullYear();
  
    return `${dayOfWeek}, ${month} ${day}, ${year}`;
  }

  // const dataH = data1.MortgageFixed.thead;
  // const data = data1.MortgageFixed.tbody;

  const exportDataSets = (worksheet, institutionRateDataSet, dataSetColIndexArr)=>{
    ///////// This Function adds a group in parallel to Excel Sheet for examle 3 datasets in parallel
    // Add Group of first 3 Groups
    let row = worksheet.addRow([]);
    console.log(row._number)
    let runningRowNumber = row._number+1
    let runningColNumber = 0
    let headingSubRowgroup1to3 = []
    //Sub HeaderRow 5
    Object.keys(institutionRateDataSet).forEach( function (group_key, index){
      if(dataSetColIndexArr.includes(index)){
        let headerRow5 = worksheet.getRow(runningRowNumber);
        headerRow5.getCell(runningColNumber+2).value = group_key;
        headerRow5.getCell(runningColNumber+2).alignment = { horizontal: 'center' };
        headerRow5.getCell(runningColNumber+2).style = {
          fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4472C4' }, // Change the color as needed (e.g., 'FFFF00' for yellow)
        },
        font :{ color:{argb : 'FFFFFF'}},
        alignment : { horizontal: 'center' }
      }

        let fromCol = toColumnName(runningColNumber+2) + runningRowNumber.toString()
        runningColNumber = runningColNumber + Object.keys(institutionRateDataSet[group_key][0]).length
        let toCol = toColumnName(runningColNumber) + runningRowNumber.toString()
        // Merge cells
        worksheet.mergeCells(fromCol+':'+toCol)
        console.log(fromCol+':'+toCol)
        headingSubRowgroup1to3 = [...headingSubRowgroup1to3, ...Object.keys(institutionRateDataSet[group_key][0])]
      }
     })

     // Processing SubRow
     headingSubRowgroup1to3 = headingSubRowgroup1to3.map((item,index) => (item === 'bauername' ? '' : item))
    // worksheet.mergeCells('B5:E5');
    const row6 = worksheet.addRow(headingSubRowgroup1to3);
    row6.height = 35;

    let column_formatting_arr = []
    headingSubRowgroup1to3.forEach( function (item, index){
      if(item !== ''){
        row6.getCell(index+1).style = { 
          fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'BDD7EE' },
              },
              alignment: {horizontal: 'center', wrapText: true} };
      }
      if(index === 0){
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 20 })
      }else{
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 9 })
      }
    })

     // Apply formatting and Width
     worksheet.columns = column_formatting_arr;
// Formatting Classes Started
     const firstDataRowFirstColStyle = { 
      fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE699' }
          } };
    const firstDataRowDataColStyle = {
      numFmt: '_(* #,##0.000_);_(* (#,##0.000);_(* "-"??_);_(@_)',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE699' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      }
    }
    const oddDataRowFirstColStyle = { 
      fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D9D9D9' }
          } };
    const oddDataRowDataColStyle = {
      numFmt: '_(* #,##0.000_);_(* (#,##0.000);_(* "-"??_);_(@_)',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      }
    }
    const evenDataRowFirstColStyle = { };
    const evenDataRowDataColStyle = {
      numFmt: '_(* #,##0.000_);_(* (#,##0.000);_(* "-"??_);_(@_)'
    }
    const lastDataRowFirstColStyle = { 
      fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'A6A6A6' }
          } };
    const lastDataRowDataColStyle = {
      numFmt: '_(* #,##0.000_);_(* (#,##0.000);_(* "-"??_);_(@_)',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A6A6A6' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      }
    }
// Formatting Classes End
         // Add data of first 3 Products in rows
    institutionRateDataSet[Object.keys(institutionRateDataSet)[dataSetColIndexArr[0]]].forEach( function (item_row, index){
      // console.log(item_row)

      let row_data_arr=[]
      dataSetColIndexArr.forEach(function(item_dataset_index){
        row_data_arr=[...row_data_arr 
          ,...Object.values(institutionRateDataSet[Object.keys(institutionRateDataSet)[item_dataset_index]][index]) 
        ]
      })
      // console.log(row_data_arr)
       row_data_arr = row_data_arr.map((item,index) => (index===0 ?item: isNaN(parseFloat(item))?null:parseFloat(item)))
       let row = worksheet.addRow(row_data_arr);

       headingSubRowgroup1to3.forEach( function (item_heading, indexCol){
        // Set first Row First Col Style
        if(indexCol === 0){
          if(index=== 0){
            row.getCell((indexCol+1)).style = firstDataRowFirstColStyle;
          }else if((index%2) === 0){
            row.getCell(indexCol+1).style = evenDataRowFirstColStyle;
          }else if((index%2) === 1){
            row.getCell(indexCol+1).style = oddDataRowFirstColStyle;
          }
          if(institutionRateDataSet[Object.keys(institutionRateDataSet)[0]].length-1 === index){
            row.getCell(indexCol+1).style = lastDataRowFirstColStyle
          }
        } else {
            if(index=== 0){
                if(item_heading !== ''){
                  row.getCell(indexCol+1).style = firstDataRowDataColStyle;
                }
            }
            else if((index%2)=== 0){
                if(item_heading !== ''){
                row.getCell(indexCol+1).style = evenDataRowDataColStyle;
              }
            }else if((index%2)=== 1){
              if(item_heading !== ''){
                row.getCell(indexCol+1).style = oddDataRowDataColStyle;
              }
        }
      
      if(institutionRateDataSet[Object.keys(institutionRateDataSet)[0]].length-1 === index){
        if(item_heading !== ''){
          row.getCell(indexCol+1).style = lastDataRowDataColStyle;
        }
      }
    }
      })  
    })

/////////////////////////////////////END
  }

  
  export const FuncexportToExcel2 = async (institutionRateDataSet, headers, tcol, filename) => {

    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();

    for (let key_sheet in institutionRateDataSet){
      let worksheet = workbook.addWorksheet(key_sheet);
      FuncexportToExcel2Sheet(institutionRateDataSet[key_sheet], headers, tcol, worksheet);
    }


    // Export the workbook to a Blob
    const buffer = await workbook.xlsx.writeBuffer();
    // Download the file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    const dt_YYMMDD_HHMMSS = getCurrentDateTime('YYMMDD-HHMMSS');
    const fileName2 = filename + ' - ' + dt_YYMMDD_HHMMSS + '.xlsx';

    saveAs(blob, fileName2);
  }

  export const FuncexportToExcel2Sheet = async (institutionRateDataSet, headers, tcol, worksheet) => {
    
    

    // Header Row
    worksheet.addRow(headers).height=60;
    const headerRowIndex = 1;
    const headerRow = worksheet.getRow(headerRowIndex);
    headerRow.alignment = { horizontal: 'center', wrapText: true };
    headerRow.font = { bold: true, size: 10 };
 



    let column_formatting_arr = []
    tcol.forEach( function (item, index){
      if(item === 'Account_Type')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 20 })
      else if (item === 'Customer_Name')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 40 })
      else
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 15 })
    })
     // Apply formatting and Width
     worksheet.columns = column_formatting_arr;


 // Add data of Report  
  (institutionRateDataSet).forEach( function (item_row, index){
    let row_data_arr = tcol.map((item2, index2) => (
                   (!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' )
                   ? item2.startsWith('percent')?parseFloat(item_row[item2])/100 :parseFloat(item_row[item2]):item_row[item2] 
                  //   ?parseFloat(item_row[item2]).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):(!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' && item2.startsWith('percent')?parseFloat(item_row[item2]).toFixed(2) + '%':item_row[item2])    
                  ))

    // let row_data_arr = Object.values(item_row)
    let row = worksheet.addRow(row_data_arr);


    // Styling Start
    const currencyStyle = {
      numFmt: '$#,##0.00'
    }
    const percentStyleRed = {
      numFmt: '0.00%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC0CB' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { color: { argb: 'FF0000' } }
    }
    const percentStyleGreen = {
      numFmt: '0.00%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '90EE90' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { color: { argb: '008000' } }
    }

    tcol.map((item2, index2) => (
      (!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' )
      ? item2.startsWith('percent')?
      item_row[item2]>=0?row.getCell(index2+1).style = percentStyleGreen
      : item_row[item2]<0?row.getCell(index2+1).style = percentStyleRed : null
      :row.getCell(index2+1).style = currencyStyle:null
     //   ?parseFloat(item_row[item2]).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):(!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' && item2.startsWith('percent')?parseFloat(item_row[item2]).toFixed(2) + '%':item_row[item2])    
     ))

    // row.getCell(indexCol+1).style = firstDataRowDataColStyle;




 })
    
 
  };

  function toColumnName(num) {
    // Returns the Column Name of Excel from number
    let ret = '';
    while (num > 0) {
        num--;
        ret = String.fromCharCode('A'.charCodeAt(0) + (num % 26)) + ret;
        num = Math.floor(num / 26);
    }
    return ret;
  }


  function getCurrentDateTime(format= 'YYMMDD-HHMMSS') {
    const date = new Date();
    let year = date.getFullYear().toString().slice(-2);
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let hour = ('0' + date.getHours()).slice(-2);
    let minute = ('0' + date.getMinutes()).slice(-2);
    let second = ('0' + date.getSeconds()).slice(-2);

    return `${year}${month}${day}-${hour}${minute}${second}`;
}