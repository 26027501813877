import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Pen } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";


export default function TasksCard({ title, name }) {

    const [dataSet, setDataSet] = useState(null)
    const [titleS, setTitleS] = useState(title)
    const [loading, setLoading] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [newTask, setNewTask] = useState(false)
    const [roleUUID, setRoleUUID] = useState(null)
    const [role, setRole] = useState(null)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [activeUpdateLIst, setActiveUpdateLIst] = useState([])
    const [permissions, setPermissions] = useState([])

    const api_call = () => {
        setLoading(true)
        fetch(api.base_url + api.list_roles, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    setDataSet(data['result_json'])
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const update_role = () => {
        
        if(role === null || role === '' ){
            setAlert('Missing Role Name')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        setLoading(true)
        fetch(api.base_url + api.update_role, {
            method: 'POST',
            body: JSON.stringify({
                role_uuid: roleUUID,
                role: role,
                permissions: permissions
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    setDataSet(data['result_json'])
                    setNewTask(false);
                    trOnClick(null);
                    setSuccess('Task Updated!')
                    setTimeout(()=>setSuccess(null), 3000)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });

    }

    function rpad(input, minLength = 10, padChar = ' ') {
        const inputStr = String(input);
        if (inputStr.length >= minLength) {
          return inputStr;
        }
      
        const padding = padChar.repeat(minLength - inputStr.length);
        return inputStr + padding;
      }

    useEffect(() => {
        api_call();
    }, []); 

    
function setStatePermission(permissions, role_name, role_uuid, none = false){
    var dict = {}
    JSON.parse(permissions)?.map((item2, index2) => (
        (none)?
            dict[item2.permission_description] = 'None'
        :
            dict[item2.permission_description] = item2.permission_value
        
    ))
    setPermissions(dict)
    setRole(role_name)
    setRoleUUID(role_uuid)
}

function updateStatePermissions(permission_description, permission_value){
    var dict = permissions;
    dict[permission_description] = permission_value;
    setPermissions(dict)
    }

    function trOnClick(index){
        setEditIndex(index)
    }
    const userPermissions = JSON.parse(localStorage.getItem('permissions'));
    return (
        <Box className="mc-card mc-mb25">
            
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}
            <Box className="thin-scrolling" style={{ height: "500px" }}>
            {userPermissions['Authorization'] === 'Write'?
                <PlusSquare className={'mc-mt10 mc-mb10'} title='Add' style={{'cursor':'pointer'}} size={24} onClick = {()=>{setRoleUUID(null); setNewTask(!newTask); trOnClick(null); setStatePermission(dataSet[0].permissions, null, null, true)}} />
                : null
            }
                <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :
                <Table>
                    <Thead className="mc-table-head primary sticky">
                    {dataSet?.map((item, index) => (
                    (index===0)?
                    // Headings of the table
                        <Tr key={ index-1 }> 
                            <Th key={ index }>
                                Role Name
                            </Th>
                            {JSON.parse(item.permissions)?.map((item2, index2) => (
                                <Th key={ index2 + 5 }>
                                    {rpad(item2.permission_description, 15, '.')}
                                </Th>
                            ))}
                        </Tr>
                    :null
                    )
                        )}
                    </Thead>
                    <Tbody className="mc-table-body even">
                        {dataSet?.map((item, index) => (
                            /* Body Rows of the table */
                                <Tr key={ index }> 
                                {/* Role Name of table body */}
                                    <Td key={index} title={ index + 1 }>
                                        {editIndex===index?
                                        /* Role Name Edited  */
                                            <div key={index} className="mc-float-left">
                                                <Input defaultValue={item.role_name} onChange={(e)=>{setRole(e.target.value); }} />
                                                <CheckLg className="mc-mt10 mc-ml10" size={24} title='Ok' style={{'cursor':'pointer'}} onClick={()=>{update_role(); }} />
                                                <FileEarmarkX className="mc-mt10 mc-ml10" size={24} title='Cancel' style={{'cursor':'pointer'}} onClick={()=>{setNewTask(false); trOnClick(null);}} />
                                            </div>
                                        :
                                        /* Role Name without Editing */
                                            <div key={index} onClick={(event)=>{if (event.detail===2) {setNewTask(false); setStatePermission(item.permissions, item.role_name, item.role_uuid); trOnClick(index); } }}>
                                                <Box className="mc-table-check">
                                                    {(!item.is_active || activeUpdateLIst.indexOf(index)>-1)?<strike><Text>{ item.role_name }</Text></strike>:
                                                        <Text >{ item.role_name }</Text>
                                                    }
                                                    {userPermissions['Authorization'] === 'Write' ? <Pen className="mc-mt10 mc-ml10" size={24} title='Edit' style={{'cursor':'pointer'}} onClick={()=>{setNewTask(false); setStatePermission(item.permissions, item.role_name, item.role_uuid); trOnClick(index); }} /> : null}
                                                </Box>
                                            </div>
                                        }
                                    </Td>
                                    {/* Role Permissions in the body */}
                                    {JSON.parse(item.permissions)?.map((item2, index2) => (
                                        <Td key={index2+5}>
                                        {editIndex===index?
                                            <LabelField
                                                    name={item.role_name}
                                                    option={["None", "Read", "Write"]}
                                                    fieldSize="w-100 h-md"
                                                    onChange={(e)=>{updateStatePermissions(item2.permission_description, e.target.value) }}
                                                    defaultValue={item2.permission_value}
                                             />
                                            :item2.permission_value
                                        }
                                        </Td>
                                    ))}
                                </Tr>
                        )
                        )}
                        
                        {newTask?
                                /* Create New Role */
                                    <Tr >
                                        <Td title={ 10000 }>
                                            <div>
                                                
                                                <div className="mc-float-left">
                                                    <Input placeholder={'New Role'} onChange={(e)=>{setRole(e.target.value); }} />
                                                    <CheckLg className="mc-mt10 mc-ml10" size={24} title='Ok' style={{'cursor':'pointer'}} onClick={()=>{update_role(); trOnClick(null); }} />
                                                    <FileEarmarkX className="mc-mt10 mc-ml10" size={24} title='Cancel' style={{'cursor':'pointer'}} onClick={()=>{setNewTask(false)}} />
                                                </div>
                                            </div>
                                        </Td>
                                        {/* Role Permissions in the body */}
                                        {JSON.parse(dataSet[0].permissions)?.map((item2, index2) => (
                                            <Td>
                                                <LabelField
                                                        name={'role_name'}
                                                        option={["None", "Read", "Write"]}
                                                        fieldSize="w-100 h-md"
                                                        onChange={(e)=>{updateStatePermissions(item2.permission_description, e.target.value) }}
                                                        defaultValue={'None'}
                                                />
                                            </Td>
                                        ))}
                                    </Tr>
                                    :null
                        }
                    </Tbody>
                </Table>
                }
            </Box>
        </Box>
    )
}

