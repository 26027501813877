import React, { useState, useEffect } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import api from "../../data/master/api.json";
import { Alert } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import { useLocation } from 'react-router-dom';

export default function Login() {

    const [user_name, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    const forward = search?(new URLSearchParams(search).get('forward')!=='null' || new URLSearchParams(search).get('forward') !== null)?new URLSearchParams(search).get('forward'):'/':'/'

    console.log(forward)
    
    const handleSubmit = (e) => {
        e.preventDefault();
        doLogin(user_name, password);
    }; 



    const doLogin = async (user_name, password) => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.login, {
            method: 'POST',
            body: JSON.stringify({
                user_name: user_name,
                password: password
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if ( data['status'] === 'error' ) {
                    setAlertMessage( data['message'] );
                } else if ( data['status'] === 'success' ) {
                    setAlertMessage('Signed in as ' + data['title']);
                    localStorage.setItem('session_id', data['session_id']);
                    localStorage.setItem('title', data['title']);
                    localStorage.setItem('user_name', user_name);
                    localStorage.setItem('user_uuid', data['user_uuid']);
                    localStorage.setItem('permissions', JSON.stringify(data['permissions_data_set'][0]));
                    const year = new Date().getUTCFullYear();
                    const month = new Date().getUTCMonth() + 1; // Months are zero-based
                    const day = new Date().getUTCDate();
                    const hour = new Date().getUTCHours();
                    const minute = new Date().getUTCMinutes();
                    const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
                    localStorage.setItem('session_id_date', utcMinus10Date);
            
                    // fetchPermissions().then(
                    //     ()=>{
                    //         navigate(forward);
                    //     }
                    // )

                    // setTimeout(function() {
                        navigate(forward);
                    //   }, 1000);
                }
                
            })
            .catch((err) => {
                console.log(err.message);
            });
        setLoading(false)
    };

    const fetchPermissions = async () => {
        await fetch(api.base_url + api.list_user_permissions_current_user, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem('permissions', JSON.stringify(data['result_json'][0]));
            })
            .catch((err) => {
                console.log(err.message);
            });
        
    }
    let valert;
    if (alertMessage !== '' ) {
        valert = <Alert key="abc123" variant="warning" onClose={() => setAlertMessage('')} dismissible>{alertMessage}</Alert>
    } else {
        valert = ""
    }

    const v_session_id = localStorage.getItem('session_id');
    useEffect(() => {
        if (v_session_id !== null) {
            navigate(forward);
        }
    }, []); 


    return (
        <Box className="mc-auth">
            
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern"  
            />
            <Box className="mc-auth-group">
                <Logo 
                    src = { data?.logo.src }
                    alt = { data?.logo.alt }
                    href = { data?.logo.path }
                    className = "mc-auth-logo"
                />
                <Heading as="h4" className="mc-auth-title">{data?.title}</Heading>
                {valert}

                <Form className="mc-auth-form" >
                    
                    {data?.input.map((item, index) => (
                        <IconField 
                            key={index}
                            icon={item.icon}
                            type={item.type}
                            option={item.option}
                            classes={item.fieldSize}
                            placeholder={item.placeholder}
                            passwordVisible={item.passwordVisible}
                            onChange={(item.setter === "setUserName") ? (e) => setUserName(e.target.value) : (e) => setPassword(e.target.value)}
                        />
                    ))}
                    <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} onClick={handleSubmit}> {loading ?  <Spinner animation="border" /> :  data?.button.text }</Button>
                    <Anchor className="mc-auth-forgot" href={ data?.forgot.path }>{ data?.forgot.text }</Anchor>
                </Form>

            </Box>
        </Box>
    );
}