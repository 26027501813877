import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Save } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";

export default function TasksCard({ title, name }) {

    const [dataSet, setDataSet] = useState(null)
    const [titleS, setTitleS] = useState(title)
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [newTask, setNewTask] = useState(false)
    const [userUUID, setUserUUID] = useState(null)
    const [role, setRole] = useState(null)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [activeUpdateLIst, setActiveUpdateLIst] = useState([])
    const [permissions, setPermissions] = useState([])

    const api_call = () => {
        setLoading(true)
        fetch(api.base_url + api.list_user_roles, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    var vdata = data['result_json']
                    var vpermissions = []
                    vdata?.map((item2, index2) => (
                        vdata[index2].roles = JSON.parse(item2.roles)
                    ))

                    vdata?.map((item2, index2) => (
                        vpermissions.push(setStatePermission(item2.roles, item2.user_uuid))
                    ))
                    setPermissions(vpermissions)

                    setDataSet(vdata)
                    console.log(vdata)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const update_role = () => {
        setLoading1(true)
        fetch(api.base_url + api.save_user_role, {
            method: 'POST',
            body: JSON.stringify({
                p_data: permissions
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    setLoading1(false);
                } else if (data['status'] === 'success') {
                    setLoading1(false);
                    setSuccess('User Roles are updated!')
                    setTimeout(()=>setSuccess(null), 3000)
                }
            })
            .catch((err) => {
                console.log(err.message);
                setLoading1(false);
            });

    }

    function rpad(input, minLength = 10, padChar = ' ') {
        const inputStr = String(input);
        if (inputStr.length >= minLength) {
          return inputStr;
        }
      
        const padding = padChar.repeat(minLength - inputStr.length);
        return inputStr + padding;
      }

    useEffect(() => {
        api_call();
    }, []); 

    
function setStatePermission(p_permissions, user_uuid){
    var dict = {}
    p_permissions?.map((item2, index2) => (
        dict[item2.role_name] = item2.role_exists       
    ))
    dict['user_uuid'] = user_uuid
    return dict
}

function updateStatePermissions(permission_description, permission_value, index){
        var dict = permissions;
        dict[index][permission_description] = permission_value;
        setPermissions(dict)
        console.log(dict)
    }

    function trOnClick(index){
        setEditIndex(index)
    }
    const userPermissions = JSON.parse(localStorage.getItem('permissions'));
    return (
        <Box className="mc-card mc-mb25">
            
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}
            
            {userPermissions['Authorization'] === 'Write'?
                <Save className={'mc-mt10 mc-mb10 mc-ml20'} title='Save' style={{'cursor':'pointer'}} size={24} onClick={update_role}/>
                :null
            }
            <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
            
            {loading1?<Spinner animation="border" style={{ marginLeft: '20px' }} />:null}
            
            <Box className="thin-scrolling" style={{ height: "500px" }}>
            {loading ? 
            <div>
                <Spinner animation="border" />
            </div>
            :
                <Table>

                <Thead className="mc-table-head primary sticky">
                    {dataSet?.map((item, index) => (
                            (index===0)?
                                <Tr key={ index-1 }> 
                                        <Th title={ index + 1 }>
                                            User Name
                                        </Th>
                                        <Th title={ index + 1 }>
                                            Title
                                        </Th>
                                    {(item.roles)?.map((item2, index2) => (
                                        <Th>
                                            {item2.role_name}
                                        </Th>
                                    ))}
                                </Tr>:''
                        ))
                    }
                </Thead>
                    <Tbody className="mc-table-body even">         
                        {dataSet?.map((item, index) => (
                            /* Body Rows of the table */
                                <Tr key={ index }> 
                                {/* Role Name of table body */}
                                    <Td key={index} title={ index + 1 }>
                                        {/* Role Name without Editing */}
                                        <div onClick={(event)=>{if (event.detail===2) {trOnClick(index); } }}>
                                            <Box className="mc-table-check">
                                                {
                                                    <Text>{ item.user_name }</Text>
                                                }
                                            </Box>
                                        </div>
                                    </Td>
                                    <Td key={index} title={ index + 1 }>
                                            <Box className="mc-table-check">
                                                <Text>{ item.title }</Text>
                                            </Box>
                                    </Td>
                                    {/* Role Permissions in the body */}
                                    {(item.roles)?.map((item2, index2) => (
                                        <Td key={index2}>
                                            {
                                                userPermissions['Authorization'] === 'Write'?
                                             <input key={index2} type="checkbox" defaultChecked={item2.role_exists===1} value="1" onChange={(e)=>{updateStatePermissions(item2.role_name, e.target.checked === true?1:0, index)}} />
                                             :
                                             <input disabled key={index2} type="checkbox" defaultChecked={item2.role_exists===1} value="1" onChange={(e)=>{updateStatePermissions(item2.role_name, e.target.checked === true?1:0, index)}} />
                                            }
                                        </Td>
                                    ))}
                                </Tr>
                        )
                        )}
        
                    </Tbody>
                </Table>
            }
            </Box>
        </Box>
    )
}