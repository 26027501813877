// Import the createAction function from Redux Toolkit for creating action creators
import { createAction } from '@reduxjs/toolkit';
import api_leads from "./data/master/api_leads.json";

// Action creators to manage counter state

export function fetchLead(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.get_leads, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
        
            dispatch({ type: 'FETCH_LEAD_WM', payload: { lead: data["result_json"], delivery_list: data["result_json_samples"], loading: false} });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message:err.toString() } });
      }
    };
  }

  export function fetchSampleRequestReport(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.sample_order_report, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
        
            dispatch({ type: 'FETCH_SAMPLE_REQUEST_REPORT', payload: { sample_order_report: data["result_json"], loading: false} });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message:err.toString() } });
      }
    };
  }

  export function fetchUser(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.get_user_list, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_USER', payload: { user_list: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
            dispatch({ type: 'FETCH_USER', payload: { user_list: data["result_json"], loading: false, message: ''} });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_USER', payload: { user_list: [], loading: false, message:err.toString() } });
      }
    };
  }

  export function fetchCustomersLeads(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.customers_and_leads, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_CUSTOMERS_LEADS', payload: { customers_lead_list: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
            dispatch({ type: 'FETCH_CUSTOMERS_LEADS', payload: { customers_lead_list: data["result_json"], loading: false, message: ''} });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_CUSTOMERS_LEADS', payload: { customers_lead_list: [], loading: false, message:err.toString() } });
      }
    };
  }

  export function fetchProducts(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.products, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_PRODUCTS', payload: { products: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
            dispatch({ type: 'FETCH_PRODUCTS', payload: { products: data["result_json"], loading: false, message: ''} });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_PRODUCTS', payload: { products: [], loading: false, message:err.toString() } });
      }
    };
  }
  

  export function saveLead(i_data){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.save_leads, 
            {
              method: 'POST',
              body: JSON.stringify(i_data),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
          dispatch({ type: 'FETCH_LEAD_START', payload: { user_list: [], loading: false, message: data["message"]} });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message:err.toString() } });
      }
    };
  }

  export function saveLeadSample(i_data){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.save_lead_Sample, 
            {
              method: 'POST',
              body: JSON.stringify(i_data),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
          dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message: data["message"]} });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message:err.toString() } });
      }
    };
  }

  export function updateLead(i_data){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.update_leads, 
            {
              method: 'POST',
              body: JSON.stringify(i_data),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
          dispatch({ type: 'FETCH_LEAD_START', payload: { user_list: [], loading: false, message: data["message"]} });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message:err.toString() } });
      }
    };
  }
  export function clearMessage(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message: '' } });
    };
  }

  export function setMessage(message){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: false, message: message } });
    };
  }

  export function setFieldValues(fieldValues){
    return async dispatch => {
      dispatch({ type: 'SET_FIELDVALUES', payload: {fieldValues: fieldValues } });
    };
  }

  export function setFieldValuesSample(fieldValuesSample){
    return async dispatch => {
      dispatch({ type: 'SET_FIELDVALUES_SAMPLE', payload: {fieldValuesSample: fieldValuesSample } });
    };
  }

  export function setCountry(country, country_code){
    return async dispatch => {
      dispatch({ type: 'SET_COUNTRY', payload: {country: country, country_code: country_code } });
    }
  }

  export function fetchLeadView(lead_uuid){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url + api_leads.get_lead, 
            {
              method: 'POST',
              body: JSON.stringify({
                Lead_UUID:lead_uuid
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
            dispatch({ type: 'SET_FIELDVALUES', payload: {fieldValues: data["result_json"][0]}});
            dispatch({ type: 'FETCH_LEAD_START', payload: {loading: false, message:''}});
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message:err.toString() } });
      }
    };
  }

  export function fetchEmail(){
    return async dispatch => {
      dispatch({ type: 'FETCH_LEAD_START', payload: { loading: true, message: '' } });
      try {
        const response = await fetch(api_leads.base_url_outlook + api_leads.fetch_outlook_emails, 
            {
              method: 'POST',
              body: JSON.stringify({
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          }
      );
        const data = await response.json();
        if (data["status"] === "error") {
          dispatch({ type: 'FETCH_EMAIL', payload: { emails: [], loading: false, message: data["message"]} });
        } else if (data["status"] === "success") {
        
            dispatch({ type: 'FETCH_EMAIL', payload: { emails: data["result_json"], loading: false}, message:'' });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'FETCH_LEAD', payload: { lead: [], loading: false, message:err.toString() } });
      }
    };
  }
