import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Save } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";

export default function TasksCard({ title, name }) {

    const [dataSet, setDataSet] = useState(null)
    const [titleS, setTitleS] = useState(title)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [permissions, setPermissions] = useState([])

    const api_call = () => {
        setLoading(true)
        fetch(api.base_url + api.list_user_permissions, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    setDataSet(data['result_json'])
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }



    useEffect(() => {
        api_call();
    }, []); 


function updateStatePermissions(permission_description, permission_value, index){
        var dict = permissions;
        dict[index][permission_description] = permission_value;
        setPermissions(dict)
        console.log(dict)
    }


    return (
        <Box className="mc-card mc-mb25">
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}
            <Box className="thin-scrolling" style={{ height: "500px" }}>
            <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
            {loading ? 
            <div>
                <Spinner animation="border" />
            </div>
            :
                <Table>
                    <Thead className="mc-table-head primary sticky">
                        {dataSet?.map((item, index) => (
                            (index===0)?
                            // Headings of the table
                                <Tr key={ index-1 }> 
                                    {(Object.keys(item))?.map((item2, index2) => (
                                        <Th>
                                            {item2}
                                        </Th>
                                    ))}
                                </Tr>
                            :null
                            )
                        )}
                    </Thead>
                    <Tbody className="mc-table-body even">
                        {/* Body Rows of the table */}
                        {dataSet?.map((item, index) => (
                            <Tr key={ index }> 
                                {/* Role Name of table body */}
                                {Object.values(item)?.map((item2, index2) => (
                                    <Td>
                                        {item2}
                                    </Td>
                                ))}
                            </Tr>
                            )
                        )}
                    </Tbody>
                </Table>
            }
            </Box>
        </Box>
    )
}