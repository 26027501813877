import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input  } from "../elements";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Pen, Save } from 'react-bootstrap-icons';
import { FileUploader } from "react-drag-drop-files";
import { Document, Page,pdfjs } from 'react-pdf';

export default function TasksCard({ product }) {

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [imgName, setImgName] = useState(product?product.img_name:"")
    const [addImgName, setAddImgName] = useState(product?product.additional_img_name:"")
    
    const [img, setImg] = useState(null)

    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const fileTypes = ["JPG", "PNG", "GIF"];

    function api_call_get_pdf_name() {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        fetch(api.base_url + api.lookup_fetch, {
            method: 'POST',
            body: JSON.stringify({
                "key":"mobile_promo_pdf"
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        }).then((response) => response.json())
        .then((data) => {
          if (data["status"] === "error") {
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
          } else if (data["status"] === "success") {
            setImgName(data.value)
          }
    })
        .catch((err) => {
            console.log(err.message);
        });
      }

    function api_call_get_token() {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        fetch(api.base_url + api.get_token, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        }).then((response) => response.json())
        .then((data) => {
          if (data["status"] === "error") {
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
          } else if (data["status"] === "success") {
            setToken(data.token);
          }
    })
        .catch((err) => {
            console.log(err.message);
        });
      }

    const update_product = () => {
        setLoading(true)
        fetch(api.base_url + api.lookup_save, {
            method: 'POST',
            body: JSON.stringify({
                "value":imgName,
                "key":"mobile_promo_pdf"
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    setAlert(data['message'])
                    setTimeout(()=>setAlert(null), 3000)
                } else if (data['status'] === 'success') {
                    setSuccess(data['message'])
                    setTimeout(()=>setSuccess(null), 3000)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false)
            });

    }

    const api_call2 = (image, set_img_name) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("name", "products");    
        formData.append("folder", "products");
        formData.append(image.name, image);
      
        fetch(api.base_url + api.mobile_upload_file, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem('session_id')
            },
        })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
        //   api_call(false);
            if (data['status'] === 'error') {
                //     setAlertMessage(data['message']);
            } else if (data['status'] === 'success') {
                //    setAlertMessage('Signed in as ' + data['title']);
                console.log(data['file_name'])
                set_img_name(data['file_name'])
            }          
        })
        .catch((err) => {
            console.log(err.message);
            setLoading(false);
        });
    }

    const handleChangeUploadFiles = (file) => {
          for(let index = 0; index < file.length; index++){
            api_call2(file[index]);
          //   setSelectedImage(file[index])
          }
          if(file.length === undefined){
              api_call2(file, setImgName);
          }
    }

    const api_call_get_files = (file_name, setFileDict) => {
        console.log('going to fetch file')
      fetch((api.get_mobile_bucket_object_url2 +"products%2F" + "products" + "%2F" + file_name), {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            "Access-Control-Allow-Origin": "*"
          },
      })
      .then((response) => response.json())
      .then((data) => {
        var file_dict = {};
        var file_name = data['name'].split('/').pop();
        file_dict = {timeCreated:data['timeCreated'], 'file_name':file_name, title: file_name, caption: file_name};
  
        fetch(data['mediaLink'], {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + token
            },
        })
        .then(resp => resp.blob())
        .then((blob ) => {
          file_dict['url'] = URL.createObjectURL(blob)
          setFileDict(file_dict);
        })
      .catch((err) => {
          console.log(err.message);
      });
    })
    .catch((err) => {
        console.log(err.message);
      });
  }

    useEffect(() => {
        api_call_get_token();
        api_call_get_pdf_name();
    }, []);

    useEffect(() => {
        api_call_get_files( imgName, setImg);
    }, [imgName]);


    return (
        <Box className="mc-card mc-mb25">
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}
            {loading ?<Spinner animation="border" /> : <Save className={'mc-mt10 mc-mb10 mc-ml20 mb-4'} title='Save' style={{'cursor':'pointer'}} size={24} onClick={update_product}/>}
            <Row xs={1} md={2} xl={3}>
                <Col>
                    <FileUploader key={0} name="myImage" multiple={false} handleChange={handleChangeUploadFiles} types={fileTypes} />
                    <br />
                    <Text>{api.get_mobile_bucket_object_url2 +"products%2F" + "products" + "%2F" + imgName}</Text>
                    <br />
                    <img alt="No Preview Available!" src={imgName?api.get_mobile_bucket_object_url2 +"products%2F" + "products" + "%2F" + imgName:null} style={{maxWidth: "100%"}}/>
                </Col>
            </Row>
        </Box>
    )
}
