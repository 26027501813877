import React, { useState, useEffect } from "react";
import DotsMenu from "../DotsMenu";
import { Box, Icon, Text, Heading } from "../elements";
import { LabelField } from "../../components/fields";

export default function EcommerceCard({ variant, trend, number, title, icon, percent, compare, is_editable = false, style, dotsMenu, saveFunction }) {
    const [retVal, setRetVal] = useState(style===undefined?number:parseFloat(number).toLocaleString('en-US', style));
    useEffect(() => {
        if(saveFunction !== undefined && is_editable === null){
            saveFunction(retVal);
        }
        if(is_editable === false){
            setRetVal(style===undefined?number:parseFloat(number).toLocaleString('en-US', style))
        }
      }, [is_editable]);

      const handleChange = (event) =>{
        if(style !== undefined){
            setRetVal((isNaN(parseFloat(event.target.value.replace(/[^0-9.-]+/g,"")))?0:parseFloat(event.target.value.replace(/[^0-9.-]+/g,""))).toLocaleString('en-US', style));
        }else{
            setRetVal(event.target.value);
        }
    }
    return (
        <Box className={`mc-ecommerce-card ${ variant }`}>
            <Icon className="mc-ecommerce-card-trend material-icons">{ trend }</Icon>
            <Box className="mc-ecommerce-card-head">
                <Heading as="h4" className="mc-ecommerce-card-meta">
                    <Text as="span">{ title }</Text>
                    {
                        (is_editable===true)?
                            (<LabelField type="text" fieldSize="w-100 h-sm" labelDir="label-col mb-4" value={retVal} style={style} onChange={handleChange}  />):(retVal)
                    }
                </Heading>
                <Icon className="mc-ecommerce-card-icon material-icons">{ icon }</Icon>
            </Box>
            <Box className="mc-ecommerce-card-foot">
                <Box className="mc-ecommerce-card-compare">
                {percent?<Text as="mark">{ percent }</Text>:''}
                    <Text as="span">{ compare }</Text>
                </Box>
                <DotsMenu dots={ dotsMenu.dots } dropdown={ dotsMenu.dropdown } />
            </Box>
        </Box>
    );
}