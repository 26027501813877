import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/sample_request.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, fetchUser, setMessage, saveLead, setFieldValues, fetchCustomersLeads, saveLeadSample, fetchProducts} from '../../actions';
import { Alert } from "react-bootstrap";
import { IconField, IconTextarea, LabelField, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import Select from 'react-select';
import { ArrowRight, PatchPlus, PatchMinus, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Pen, Save } from 'react-bootstrap-icons';

const { v4: uuidv4 } = require('uuid');


export default function LeadList() {

    
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionProduct, setSelectedOptionProduct] = useState(null);
    const[productData, setProductData] = useState([{}])
    // console.log(selectedOption)
    const handleChangeSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
        handleChange("AccountId", {target:{value:selectedOption.AccountId}});
        handleChange("Lead_UUID", {target:{value:selectedOption.Lead_UUID}});
        handleChange("Contact_Name", {target:{value:selectedOption.contact_name}});
        handleChange("Contact", {target:{value:selectedOption.datus}});
        handleChange("Address1", {target:{value:selectedOption.Address1}});
        handleChange("Address2", {target:{value:selectedOption.Address2}});
        handleChange("City", {target:{value:selectedOption.City}});
        handleChange("State", {target:{value:selectedOption.State}});
        handleChange("Zip", {target:{value:selectedOption.Zip}});
        handleChange("Country", {target:{value:(selectedOption.Country?selectedOption.Country:'').toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase() }).replace('United States', 'United States of America (the)')}} );
    };
    const handleChangeSelectProducts = (selectedOption) => {
        setSelectedOptionProduct(selectedOption)
    };
    const now = new Date();
    let fieldValues = useSelector((state) => state.fieldValues);
    let products = useSelector((state) => state.products);
    const message = useSelector((state) => state.message);
    const customers_lead_list = useSelector((state) => state.customers_lead_list);
    // let product_data = []
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/lead-list");
    }

    useEffect(() => {
        dispatch(fetchCustomersLeads());
        dispatch(fetchProducts());
        dispatch(setFieldValues([]));
      }, []);

    const dispatch = useDispatch();

    const userList = useSelector((state) => state.userList);
    const titleList = userList.map((user) => user.title);

    const options = customers_lead_list.map(item => ({
        ...item,
        value: item.AccountId,
        label: item.customer_name,
        description: `Contact Name : ${item.contact_name}, Contact: ${item.datus}, Address1: ${item.Address1}, Address2: ${item.Address2}, City: ${item.City}, State: ${item.State}, Zip: ${item.Zip}, Country: ${item.Country}`
      }));
    
    const optionsProduct = products.map(item => ({
        ...item,
        value: item.num,
        label: item.num,
        description: `${item.description}`
      }));

    const handleChange = (column_name, event) => {
        // console.log('column_name')
        // console.log(column_name)
        // console.log(event.target.value)
        fieldValues = ({ ...fieldValues, [column_name]: event.target.value?event.target.value:'' });
        dispatch(setFieldValues(fieldValues));
        // console.log('its in handleChange')
        // console.log(fieldValues)
      };

      function signClicked(num, incrementBy) {
        // Find the index of the item in productData with the matching num
        const index = productData.findIndex(item => item.num === num);
        if (index !== -1) {
            // Update the qty property based on the incrementBy value
            productData[index].qty += incrementBy;
            // Make sure qty doesn't go below 0
            if (productData[index].qty < 0) {
                productData[index].qty = 0;
            }
            // Update the dataset
            setProductData([...productData.filter(item => item.num !== ""  && item.num !== undefined && item.qty !== 0 )]);
        }
    }

    function setSampleValue(num, value) {
        // Find the index of the item in productData with the matching num
        const index = productData.findIndex(item => item.num === num);
        if (index !== -1) {
            // Update the qty property based on the incrementBy value
            // Make sure qty doesn't go below 0
            
            productData[index].qty = Number(value);
            
            // Update the dataset
            setProductData([...productData]);
        }
    }

    const saveForm = () =>{
        console.log('its here!')
        if (!fieldValues.hasOwnProperty('Contact_Name') || !fieldValues.Contact_Name || !fieldValues.hasOwnProperty('Contact') || !fieldValues.Contact){
            dispatch(setMessage('Required fields are missing!'));
        }else{
            fieldValues = ({ ...fieldValues, Sample_UUID: uuidv4(), Created_At: now.toISOString().replace('T', ' ').replace('Z', ''), Updated_At: now.toISOString().replace('T', ' ').replace('Z', ''), productData: productData.filter(item => item.num !== ""  && item.num !== undefined && item.qty !== 0 ), Item_Count: productData.reduce((total, item) => {
                                                                                                                                                                                                                                            // Check if qty exists before adding
                                                                                                                                                                                                                                            if (typeof item.qty !== 'undefined' && item.qty !== 0) {
                                                                                                                                                                                                                                            return total + item.qty;
                                                                                                                                                                                                                                            } else {
                                                                                                                                                                                                                                            // Return the current total if qty is undefined (avoids NaN)
                                                                                                                                                                                                                                            return total;
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        }, 0) });
            if (!fieldValues.hasOwnProperty('Lead_Owner')){
                fieldValues = ({ ...fieldValues, Sample_USER_UUID: localStorage.getItem('user_uuid') });
            }else{
                fieldValues = ({ ...fieldValues, Sample_USER_UUID: userList.find(item => item.title === fieldValues.Lead_Owner).user_uuid });
                delete fieldValues.Lead_Owner
            }
            dispatch(saveLeadSample(fieldValues));
            setTimeout(back_clicked, 2000); // Delay in milliseconds
        }
    }

    const addProducts = () =>{
        if(selectedOptionProduct){
            let mySet = new Set()
            productData.forEach(item => {
                mySet.add(item.num);
            });
            if (!mySet.has(selectedOptionProduct.num)) {
                setProductData([...productData, {num: selectedOptionProduct.num, description: selectedOptionProduct.description, qty_sign_minus:<p onClick={signClicked(selectedOptionProduct.num, -1)}> - </p>, qty_sign_plus:<p onClick={signClicked(selectedOptionProduct.num, +1)}> + </p>, qty:1}])
            }                
        }else{
            setProductData([{num:1}])
        }
    }

    const col_list = [
            {
            accessorKey : 'num',
            header: 'Num',
            size:100
            },
            {
            accessorKey : 'description',
            header: 'Description',
            size:100
            },
            {
            accessorKey : 'qty',
            header: 'Quantity',
            size:100
            }
    ]

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb_createlead.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>

      <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{back_clicked()}}>Cancel</Button>
      <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{saveForm()}}>Submit</Button>
      {(message !== '')? 
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
                :null
      }
      <CardLayout className="p-lg-5 mb-4">
        <DivideTitle title={"Search Customers / Leads"} className="mb-4 mt-3" />
            <Select
                value={selectedOption}
                onChange={handleChangeSelect}
                options={options}
                getOptionLabel={(option) => `${option.label} - ${option.description}`}
                getOptionValue={(option) => option.value}
                isSearchable
                placeholder="Search..."
            />
        </CardLayout>
        {data?.create_sample.map((item, index) =>(
                <CardLayout className="p-lg-5 mb-4">
                    <DivideTitle title={Object.keys(item)[0]} className="mb-4 mt-3" />
                    <Row>
                        <Col xs={10} md={Object.keys(item)[0]==='Sample Notes'?12:5} xl={Object.keys(item)[0]==='Sample Notes'?12:4}>
                            {item[Object.keys(item)[0]].map((item2, index2) =>(
                                (index2+1)%3===1?
                                item2.component === 'LabelField'?
                                    <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} value={fieldValues[item2.column]} />
                                :item2.component === 'LabelTextarea'?
                                    <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} value={fieldValues[item2.column]} />
                                :null
                                :null
                            ))}
                        </Col>

                        <Col xs={10} md={5} xl={4}>
                            {item[Object.keys(item)[0]].map((item2, index2) =>(
                                ((index2+1)%3)===2?
                                    item2.component === 'LabelField'?
                                        <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} value={fieldValues[item2.column]} />
                                    :item2.component === 'LabelTextarea'?
                                        <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} value={fieldValues[item2.column]} />
                                    :null
                                :null
                            ))}
                        </Col>

                        <Col xs={10} md={5} xl={4}>
                            {item[Object.keys(item)[0]].map((item2, index2) =>(
                                ((index2+1)%3)===0?
                                    item2.component === 'LabelField'?
                                        <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}}  value={fieldValues[item2.column]}/>
                                    :item2.component === 'LabelTextarea'?
                                        <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}}  value={fieldValues[item2.column]}/>
                                    :null
                                :null
                            ))}
                        </Col>
                    </Row>
                </CardLayout>

        ))
        }
        <CardLayout className="p-lg-5 mb-4">
            <DivideTitle title={"Samples"} className="mb-4 mt-3" />
            <Row>
                <Col xs={10} md={8} xl={8}>
                    <Select
                        value={selectedOptionProduct}
                        onChange={handleChangeSelectProducts}
                        options={optionsProduct}
                        getOptionLabel={(option) => `${option.label} - ${option.description}`}
                        getOptionValue={(option) => option.value}
                        isSearchable
                        placeholder="Search..."
                    />
                </Col>
                <Col xs={2} md={2} xl={2}>
                    <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{addProducts()}}>Add</Button>
                </Col>
            </Row>
            <Row>
                        <MaterialReactTable
                                  columns = { col_list }
                                  data = {productData.filter(item => item.num !== ""  && item.num !== undefined && item.qty !== 0 ).map(item => {
                                                return {
                                                    ...item,
                                                    qty: <p style={{cursor:"pointer"}}> <PatchMinus style={{fontSize:"15px"}} onClick={()=>{signClicked(item.num, -1)}}> - </PatchMinus> <PatchPlus style={{fontSize:"15px"}} onClick={()=>{signClicked(item.num, +1)}}> + </PatchPlus> <input type="text" value={item.qty} onChange={(event)=>{ setSampleValue(item.num, event.target.value)}} /> </p>
                                                };
                                            })
                                            }
                                  initialState={{
                                      pagination: {
                                      pageSize: 100,
                                      },
                                      showColumnFilters:true,
                                      density: 'compact'
                                  }}
                                  //optionally override the default column widths
                                  defaultColumn={{
                                      maxSize: 400,
                                      minSize: 1,
                                      size: 100, //default size is usually 180
                                  }}
                                  enableColumnResizing
                                  columnResizeMode="onChange" //default
                        />
            </Row>
        </CardLayout>
        <CardLayout>
            <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{back_clicked()}}>Cancel</Button>
            <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{saveForm()}}>Submit</Button>
        </CardLayout>
    </PageLayout>
  );
}
;