import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Pen, Save } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { IconField, IconTextarea, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import { FileUploader } from "react-drag-drop-files";

export default function TasksCard({ product }) {

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(product?product.is_active:true)

    const [productUUID, setProductUUID] = useState(product?product.video_uuid:null)
    const [videoTitle, setVideoTitle] = useState(product?product.video_title:"")
    const [videoId, setVideoId] = useState(product?product.video_id:"")
    const handleCheckboxChange = (event) => {
        setIsActive(event.target.checked);
    }
    const navigate = useNavigate();

    const new_product = () => {
        setProductUUID(null)
        setVideoTitle("")
        setVideoId("")
        setIsActive(true)
    }

    const update_product = () => {
        
        if(videoTitle === null || videoTitle === '' || videoId === null || videoId === '' ){
            setAlert('Missing Video Information')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        setLoading(true)
        fetch(api.base_url + api.save_video, {
            method: 'POST',
            body: JSON.stringify({
                "video_UUID":productUUID,
                "video_title":videoTitle,
                "video_id":videoId,
                "isActive":isActive
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    setAlert(data['message'])
                    setTimeout(()=>setAlert(null), 3000)
                } else if (data['status'] === 'success') {
                    setSuccess(data['message'])
                    setTimeout(()=>setSuccess(null), 3000)
                    setProductUUID(data['video_uuid'])
                    // list_product()
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false)
            });

    }

    useEffect(() => {
        //api_call();
        setProductUUID(product?product.video_uuid:null)
        setVideoTitle(product?product.video_title:"")
        setVideoId(product?product.video_id:"")
        setIsActive(product?product.is_active:true)
        console.log('product is:')
        console.log(product)
    }, [product]);

    return (
        <Box className="mc-card mc-mb25">

            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}

            {loading ?<Spinner animation="border" /> : <Save className={'mc-mt10 mc-mb10 mc-ml20 mb-4'} title='Save' style={{'cursor':'pointer'}} size={24} onClick={update_product}/>}
            <PlusSquare className={'mc-mt10 mc-mb10 mc-ml20 mb-4'} title='New' style={{'cursor':'pointer'}} size={24} onClick={new_product}/>
            <input type="checkbox" checked={isActive} onChange={handleCheckboxChange} style={{'marginLeft':20, marginRight:5}} />
            <label>Active</label>

            <Row xs={1} md={2} xl={3}>
                <Col>
                    <LegendField type="text" fieldSize="w-100 h-sm" className="mb-4" title="Video Title" placeholder={"Video Title"} value={videoTitle} onChange={(e)=>{setVideoTitle(e.target.value); }}/>
                </Col>
                <Col>
                    <LegendField type="text" fieldSize="w-100 h-sm" className="mb-4" title="Youtube Video Id" placeholder={"Youtube Video Id"} value={videoId} onChange={(e)=>{setVideoId(e.target.value); }}/>
                </Col>
            </Row>
        </Box>
    )
}
