import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Analytics1Table } from "../../components/tables";
import CardHeader from "../../components/cards/CardHeader";
import { Box, Label, Icon, Text } from "../../components/elements";
import { LabelField } from "../../components/fields";
import Select from 'react-select';
// import { ExportExcel1 } from "../excel";
import {FuncexportToExcel3} from "../excel/FuncExportExcel3.js"



export default function CRM() {
    const [loading1, setLoading1] = useState(false);
    const [tableS, setTableS] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [listCols, setListCols] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState([]);
    const [options, setOptions] = useState([])
    const [completeWord, setCompleteWord] = useState(false)
    const [isYtd, setIsYtd] = useState(false);

    useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                api_call()
            }, 2000)
            return () => clearTimeout(delayDebounceFn)
    }, [listCols, isYtd])

    useEffect(() => {
            // fn_filter_data();
            if(tableS !== null && tableS !== undefined && tableS.length > 0){
                console.log(Object.keys(tableS[0]).map(func_map_header))
                FuncexportToExcel3(tableS, Object.keys(tableS[0]).map(func_map_header), Object.keys(tableS[0]), "All - Sales Report")
            }
    }, [tableS])

  const navigate = useNavigate();

  const api_call = () => {
    if  (localStorage.getItem("session_id")=== null)
      return
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth() + 1; // Months are zero-based
    const day = new Date().getUTCDate();
    const hour = new Date().getUTCHours();
    const minute = new Date().getUTCMinutes();
    const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);    
    const hasForwardParam = window.location.href.includes('forward=');
    if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))

    setLoading1(true);
    fetch(api.base_url + api.ehanced_analytics_sales_customer_type_all, {
      method: "POST",
      body: JSON.stringify({
        "group_by_cols" : ["Account_Type", "Rep", "Customer_Name"],
        "Account_Type" : "DOM PRO",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
            // console.log(data["result_json"]);
            setTableS(data["result_json"]);
        }
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

 
    function func_map_header(item, index){
        return item.replace(/percent/g, '%').replace(/_/g, ' ').replace(/dividedby/g, '/'); 
    }


    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.breadcrumbanalytics5.title }>
                        {data?.breadcrumbanalytics4.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                <Col xs={12} sm={12} xl={12}>
                                <Box className="mc-card">
                                   <Col>
                                    
                                    {loading1? 
                                        <div className="d-flex justify-content-center">
                                        <Text>Downloading!</Text> <Spinner animation="border" />
                                        </div>:
                                        <div className="d-flex justify-content-center">
                                          <Text>Report is downloaded</Text>
                                        </div> 
                                    }
                                    </Col>
                                </Box>
                    </Col>
            </Row>
        </PageLayout>
    )
}