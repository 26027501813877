import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Analytics1Table } from "../../components/tables";
import CardHeader from "../../components/cards/CardHeader";
import { Box, Label, Icon } from "../../components/elements";
import { LabelField } from "../../components/fields";
import Select from 'react-select';
import { ExportExcel1 } from "../excel";


export default function CRM() {
    const [loading1, setLoading1] = useState(false);
    const [tableS, setTableS] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [listCols, setListCols] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState([]);
    const [options, setOptions] = useState([])
    const [completeWord, setCompleteWord] = useState(false)
    const [isYtd, setIsYtd] = useState(false);
    const [includeItemDetail, setIncludeItemDetail] = useState(false);

    const handleChangeClass = (selected) => {
        // console.log('selectedOption is called!')
        setSelectedOptions(selected);
        let vOptions = selected.map((item, index)=>(item.value));
        setSelectedOptionsList(vOptions)
    }

    useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                api_call()
            }, 2000)
            return () => clearTimeout(delayDebounceFn)
    }, [listCols, isYtd, includeItemDetail])


    useEffect(() => {
        if(selectedOptionsList.length > 0){
            //const delayDebounceFn = setTimeout(() => {
                fn_filter_data()
            //}, 3000)
            //return () => clearTimeout(delayDebounceFn)
        }
    }, [selectedOptionsList])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fn_filter_data()
        }, 3000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, completeWord])

    useEffect(() => {
            fn_filter_data();
    }, [tableS])

  const navigate = useNavigate();

  const api_call = () => {
    if  (localStorage.getItem("session_id")=== null)
      return
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth() + 1; // Months are zero-based
    const day = new Date().getUTCDate();
    const hour = new Date().getUTCHours();
    const minute = new Date().getUTCMinutes();
    const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);    
    const hasForwardParam = window.location.href.includes('forward=');
    if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))

    setLoading1(true);
    fetch(api.base_url + (includeItemDetail?api.ehanced_analytics_item_rep1 : api.analytics1), {
      method: "POST",
      body: JSON.stringify({
        group_by_cols : listCols,
        is_ytd  :   isYtd
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
            // console.log(data["result_json"]);
            setTableS(data["result_json"]);
            if('Account_Type' in data["result_json"][0]){
                let list_Account_Type = [...new Set(data["result_json"].map(item => item.Account_Type))].sort();
                setSelectedOptionsList(list_Account_Type)
                let vOptions = list_Account_Type.map((item, index)=>({ value: item, label: item }));
                setOptions(vOptions);
                setSelectedOptions(vOptions);
            }
        }
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fn_filter_data = () => {
    if (tableS === null || tableS.length === 0){
        return
    }

    let terms = (searchTerm===null?"":searchTerm).split(" ");
    let vfilteredData = tableS.filter(obj => {
          if ( 
                ((!('Account_Type' in obj) || selectedOptionsList.includes(obj.Account_Type)) )
           && (
                searchTerm === null || searchTerm.trim()==='' ||
                ('Rep' in obj && ((!completeWord && terms.every(word => obj.Rep.toLowerCase().includes(word.toLowerCase()))) || (completeWord && obj.Rep === searchTerm) ) )  ||
('Customer_Name' in obj && ((!completeWord && terms.every(word => obj.Customer_Name.toLowerCase().includes(word.toLowerCase()))) || (completeWord && obj.Customer_Name === searchTerm) ) ) ||
('item_code' in obj && ((!completeWord && terms.every(word => obj.item_code.toLowerCase().includes(word.toLowerCase()))) || (completeWord && obj.item_code === searchTerm) ) ) ||
('item_description' in obj && ((!completeWord && terms.every(word => obj.item_description.toLowerCase().includes(word.toLowerCase()))) || (completeWord && obj.item_description === searchTerm) ) )
            )
          )
            return true;
        else
            return false;
      });
      if(vfilteredData.length === 0){
        vfilteredData = [tableS[tableS.length - 1]];
      }
      if(isYtd){
        vfilteredData = vfilteredData.map(obj => {
            let newObj = { ...obj }; // Create a copy of the object
            delete newObj.amount_ytd_ly; // Remove the key
            return newObj; // Return the updated object
          });
      }
    
    let past_row = []
    vfilteredData = vfilteredData.map(dataset => {
        if (!dataset.hasOwnProperty('icon') && !fn_is_child_row(dataset, past_row)){
            dataset.icon = 'plus'; 
        }
        if(vfilteredData.length === 1){
            dataset.icon = 'plus';
        }
        past_row = dataset;
        return dataset;
    });
    setFilteredData(vfilteredData);
}
 
    function func_map_header(item, index){
        let currentYear = new Date().getFullYear();
        let lastYear = currentYear - 1   
        return item.replace('item_description', 'Item Description').replace('item_code', 'Num').replace('Ytd_Amount_Open_Sales_Order', 'YTD + Open Sales Order' ).replace('predicted_amount', 'FY ' + currentYear + ' Forecast' ).replace('percent_Change_YTD', 'percent_Change_YTD ' +lastYear+' '+currentYear ).replace('amount_ytd_ly', 'YTD '+lastYear).replace('_'+currentYear, 'YTD '+currentYear).replace('_20', isYtd?'YTD 20':'FY 20').replace(/percent/g, '%').replace(/_/g, ' '); 
    }

    const handleChange = (event) => {
        if (event.target.checked){
          setListCols([...listCols, event.target.value])
        } else{
          setListCols(listCols.filter(function (item) {
            return item !== event.target.value;
          }));
        }
      }

      const handleChangeCompleteWord = (event) => {
        if (event.target.checked)
            setCompleteWord(true)
        else
        setCompleteWord(false)
      }

      const fn_is_child_row = (new_row, master_row) => {
        let is_child = true;
        for (let i = 0; i < listCols.length; i++){
            if(new_row[listCols[i]] !== master_row[listCols[i]]){
                is_child = false;
            }
        }
        return is_child;
      }

      const fn_merge_dataset = (p_filteredData, child_dataset, v_master_row) => {
        let new_dataset = []
        for (let i = 0; i <p_filteredData.length; i++ ){
            let new_row_with_new_cols = fn_fomat_dataset_row(p_filteredData[i]);
            new_dataset = new_dataset.concat(new_row_with_new_cols);
            if (fn_is_child_row(new_row_with_new_cols, v_master_row)) // filtered data row matched with child dataset row
            {
                new_dataset[new_dataset.length-1].icon = 'minus';
                new_dataset = new_dataset.concat(child_dataset)
            }
        }
        return new_dataset;
      }
    
      const fn_fomat_dataset_row = (dataset) =>{
        // console.log(dataset)
        let new_row = {}
        let new_cols_added = false
        for (let key in dataset) {
            // console.log(key)
            if (listCols.includes(key) || new_cols_added) {
                //console.log(key)
                new_row[key] = dataset[key];
            } else {
                new_row.item_code = '';
                new_row.item_description = '';
                new_cols_added = true;
                new_row[key] = dataset[key];
            }
        }
          //console.log(new_row)
        return new_row;
      }

      function isEquivalent(a, b) {
        // get the keys of each object
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);
    
        // if their property lengths are different, they're different objects
        if (aProps.length != bProps.length) {
            return false;
        }
    
        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
    
            // if the values of the same property aren't equal,
            // the objects are different
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
    
        // if we made it this far, the objects are considered equivalent
        return true;
    }

      const fn_remove_child = (master_row, index) => {

        let tableS2 = (tableS.filter(item => !fn_is_child_row(item, master_row) || isEquivalent(item, master_row) ));
        
        tableS2.forEach(item => {
            if(fn_is_child_row(item, master_row)) {
              item.icon = 'plus'
            }
          });

        // C = tableS2[index].icon='minus'
        setTableS(tableS2);
        

      }

      const fetch_detail_call_back = (v_row) => {
        if  (localStorage.getItem("session_id")=== null)
          return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);        
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
      
        let body_req = {
            group_by_cols : listCols,
            is_ytd  :   isYtd
          }

          for (let i = 0; i < listCols.length; i++ ){
            body_req[listCols[i]] = v_row[listCols[i]]
          }

        // setLoading1(true);
        fetch(api.base_url + api.ehanced_analytics_item_rep1, {
          method: "POST",
          body: JSON.stringify(
            body_req
          ),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + localStorage.getItem("session_id"),
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data["status"] === "error") {
              navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
            } else if (data["status"] === "success") {
                let v_result = fn_merge_dataset(tableS, data["result_json"], v_row);
                setTableS(v_result);
            }
            setLoading1(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.breadcrumbanalytics1.title }>
                        {data?.breadcrumbanalytics1.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                <Col xs={12} sm={12} xl={12}>
                                <Box className="mc-card">
                                    <Row xs={12} sm={12} xl={12} className="mb-4">
                                        <Col>
                                        <input key={'a1'} type="checkbox" id="idAccount_Type" name="idAccount_Type" value={'Account_Type'} className={'mc-float-left mc-ml5 mc-mt15'} onChange={handleChange} />
                                        <label style={{marginLeft:"5px"}} htmlFor="idAccount_Type" className={'mc-float-left mc-mt10'} >Account Type</label>

                                        <input key={'a2'} type="checkbox" id="idRep" name="idRep" value={'Rep'} className={'mc-float-left mc-ml10 mc-mt15'} onChange={handleChange} />
                                        <label style={{marginLeft:"5px"}} htmlFor="idRep" className={'mc-float-left mc-mt10'} >Rep</label>

                                        <input key={'a3'} type="checkbox" id="idCustomer_Name" name="idCustomer_Name" value={'Customer_Name'} className={'mc-float-left mc-ml10 mc-mt15'} onChange={handleChange} />
                                        <label style={{marginLeft:"5px"}} htmlFor="idCustomer_Name" className={'mc-float-left mc-mt10'} >Customer Name</label>

                                        <input key={'a4'} type="checkbox" id="idIncludeItemDetail" name="idIncludeItemDetail" value={'IncludeItemDetail'} className={'mc-float-left mc-ml10 mc-mt15'} onChange={(event)=>{setIncludeItemDetail(event.target.checked)}} />
                                        <label style={{marginLeft:"5px"}} htmlFor="idCustomer_Name" className={'mc-float-left mc-mt10'} >Include Item Detail</label>

                                        </Col>
                                        {
                                         listCols.includes('Account_Type')?
                                        <Col xs={5} sm={5} xl={4} style={{marginTop:'27px'}}>
                                            <Select isMulti options={options} value = {selectedOptions} onChange={handleChangeClass}  />
                                        </Col>
                                        :null}
                                        <Col  xs={3} sm={3} xl={2}>
                                        <LabelField 
                                            type = { 'search' }                                   
                                            placeholder = { 'Search' }
                                            labelDir = "label-col"
                                            fieldSize="w-100 h-md"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        </Col>
                                        <Col>
                                            <input key={'a1'} type="checkbox" id="idCompleteWord" name="idCompleteWord" value={'CompleteWord'} className={'mc-float-left mc-ml5 mc-mt15'} onChange={handleChangeCompleteWord} />
                                            <label style={{marginLeft:"5px"}} htmlFor="idCompleteWord" className={'mc-float-left mc-mt10'} >Complete Phrase</label>

                                            <input key={'a4'} type="checkbox" id="idYTD" name="idYTD" value={'Customer_Name'} className={'mc-float-left mc-ml10 mc-mt15'} onChange={(event) => {setIsYtd(event.target.checked)}} />
                                            <label style={{marginLeft:"5px"}} htmlFor="idYTD" className={'mc-float-left mc-mt10'} >YTD</label>

                                            <ExportExcel1 
                                                institutionRateDataSet = {filteredData}
                                                headers = {filteredData?(Object.keys(filteredData[0]).map(func_map_header)):null}
                                                tcol={filteredData?Object.keys(filteredData[0]):null}
                                            />
                                        </Col>
                                        
                                    </Row>
                                   <Col>
                                    
                                    {loading1? 
                                        <div className="d-flex justify-content-center">
                                            <Spinner animation="border" />
                                        </div>:
                                        filteredData?
                                    <Analytics1Table
                                            thead={Object.keys(filteredData[0]).map(func_map_header).filter(item => item !== 'icon')}
                                            tbody={filteredData}
                                            tcol={ (Object.keys(filteredData[0])).filter(item => item !== 'icon')}
                                            fetch_detail_call_back = {fetch_detail_call_back}
                                            fn_remove_child = {fn_remove_child}
                                            dontShowPlus = {includeItemDetail}
                                        />:"" 
                                    }
                                    </Col>
                                </Box>
                    </Col>
            </Row>
        </PageLayout>
    )
}