import React from "react";
import { List, Item, Anchor, Button } from "../elements";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';

export default function MenuItem({ item }) {
    const navigate = useNavigate();
    const [active, setActive] = React.useState(false);
    
    function reportClicked(hrefOpen){
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        if(hrefOpen !== undefined){
            fetch(api.base_url + api.lookup_fetch, {
                method: 'POST',
                body: JSON.stringify({
                    key: hrefOpen
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                    "Access-Control-Allow-Origin": "*"
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data['status'] === 'error') {
                        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                    } else if (data['status'] === 'success') {
 
                            const link = document.createElement('a');
                            link.href = data['value'];
                            link.target = '_blank';
                            link.rel = 'noreferrer';
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();

                            // Clean up and remove the link
                            link.parentNode.removeChild(link);

                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }

    return (
        <Item className={`mc-sidebar-menu-item ${active ? "active" : ""}`} onClick = {()=> setActive(!active)}>
            {item.submenu ?
                <>
                    <Button 
                        icon = { item.icon } 
                        text = { item.text } 
                        badge = { item.badge }
                        arrow = "expand_more"
                        className = "mc-sidebar-menu-btn" 
                    />
                    <List className="mc-sidebar-dropdown-list">
                        {item.submenu.map((item, index) => (
                            <Item key={ index } className="mc-sidebar-dropdown-item" onClick = {()=> setActive(!active)}>
                                {item.target?
                                    <a href={ item.href } className="mc-sidebar-dropdown-link" target="_blank" rel="noopener noreferrer">
                                        { item.text }
                                    </a>:
                                <Anchor href={ item.href } className="mc-sidebar-dropdown-link" onClick={()=>{reportClicked(item.hrefOpen)}}>  
                                    { item.text } 
                                </Anchor>
                                }
                            </Item>
                        ))}
                    </List>
                </>
            :
                <Anchor 
                    href={ item.href }
                    icon = { item.icon } 
                    text = { item.text } 
                    badge = { item.badge }
                    className = "mc-sidebar-menu-btn" 
                    target={ item.target }
                />
            }
        </Item>
    )
}