import React, { useState, useEffect } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { LegendField, LegendTextarea, IconField } from "../../components/fields";
import { Item, Anchor, Box, Button, Image } from "../../components/elements";
import { CardLayout, TabCard, AddProductCard, ListProductsCard, PromoPDFCard, EduPDFCard } from "../../components/cards";
import { Breadcrumb, FileUpload } from "../../components"; 
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/bcl_app/bcl_app.json";
import Spinner from 'react-bootstrap/Spinner'
import { Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
export default function MyAccount() {
    
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(localStorage.getItem("title"));

    const [oldPassword, setOldPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [productDataset, setProductDataset] = useState(null)
    const [activeTab, setActiveTab] = useState('promo');
    const [product, setProduct] = useState(null);

    useEffect(() => {
        list_product();
    }, []);

    useEffect(() => {
        if(activeTab === 'password')
            list_product();
      }, [activeTab]);

    const handleSubmit1 = (e) => {
        e.preventDefault();
        doProfileUpdate();
    }; 

    const handleSubmit2 = (e) => {
        e.preventDefault();
        doPasswordUpdate();
    }; 

    const doProfileUpdate = async () => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.update_user_profile, {
            method: 'POST',
            body: JSON.stringify({
                title: title            
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    setAlertMessage( data['message'] );
                    if ( data['status'] === 'success' ){
                        localStorage.setItem("title", title)
                    }
            })
            .catch((err) => {
                console.log(err.message);
            });
        setLoading(false)
    };

    const doPasswordUpdate = async () => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.update_user_password, {
            method: 'POST',
            body: JSON.stringify({
                old_password: oldPassword,
                password_1: password1,
                password_2: password2
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    setAlertMessage( data['message'] );
                    setOldPassword('');
                    setPassword1('');
                    setPassword2('');
                    setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false)
            });
        setLoading(false)
    };

    const list_product = () => {
        setLoading(true)
        fetch(api.base_url + api.product_list, {
            method: 'POST',
            body: JSON.stringify({
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    // setAlert(data['message'])
                    // setTimeout(()=>setAlert(null), 3000)
                } else if (data['status'] === 'success') {
                    setProductDataset(data['result_json'])
                    console.log(data['result_json'])
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false)
            });
    }

    const tabChangeAddProduct = (item) => {
        // Change to a specific tab when the button is clicked
        console.log('its to be set:')
        console.log(item)
        setProduct(item);
        setActiveTab('profile');
        
        //  console.log('its here')
      };

    let valert;
    if (alertMessage !== '' ) {
        valert = <Alert key="abc123" variant="warning" onClose={() => setAlertMessage('')} dismissible>{alertMessage}</Alert>
    } else {
        valert = ""
    }

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.pageTitle2 }>
                        {data?.breadcrumb2.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Tabs id="mc" className="mc-tabs" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey="promo" title="Promo" className="mc-tabpane promo">
                                <TabCard title="Promo">
                                <PromoPDFCard
                                        data = {productDataset}
                                        list_product = {list_product}
                                        tabChangeAddProduct= {tabChangeAddProduct}
                                        loading = {loading}
                                    />
                                </TabCard>
                            </Tab>
                            <Tab eventKey="edu" title="Edu" className="mc-tabpane promo">
                                <TabCard title="Edu">
                                <EduPDFCard
                                        data = {productDataset}
                                        list_product = {list_product}
                                        tabChangeAddProduct= {tabChangeAddProduct}
                                        loading = {loading}
                                    />
                                </TabCard>
                            </Tab>

                        </Tabs>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}