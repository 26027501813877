import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/master/customerView.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import api from "../../data/master/api.json";
import ReactHtmlParser from 'html-react-parser'; 
import Spinner from 'react-bootstrap/Spinner'
import { alignPropType } from "react-bootstrap/esm/types";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';
import { Archive, Download, Files, Upload } from 'react-bootstrap-icons';
import {stateToHTML} from 'draft-js-export-html';

export default function CustomerView() {
    
    const search = useLocation().search;
    const memo_type = new URLSearchParams(search).get('type')
    // const memo_uuid = new URLSearchParams(search).get('uuid')
    const customer_name = new URLSearchParams(search).get('name')
    const [editorMarkup, setEditorMarkup] = useState('');
    const [tableS, setTableS] = useState(null);
    const [memo_uuid, setMemo_uuid] = useState(null);
    const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(editorMarkup).contentBlocks,
        convertFromHTML(editorMarkup).entityMap,
      ))
    );
    const [token, setToken] = useState(null);
    const [viewEditor, setViewEditor] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imgBusy, setImgBusy] = useState(null);
    const folder = 'documents';
    const fnsetViewEditor = () => {
      if (!viewEditor){
        setViewEditor(true);
        try {
          setEditorMarkup(document.querySelectorAll('[data-contents="true"]')[0].innerHTML);
        }catch(err) {
        }
      }
    }
    const [archiveImageList, setArchiveImageList] = useState([]);
    const itemsRef = useRef([]);
    useEffect(() => {
      if(tableS !== null && tableS.length > 0){
        itemsRef.current = itemsRef.current.slice(0, tableS.length);
      }
   }, [tableS]);

   const handleChange = (event, memo_uuid) => {
    console.log(memo_uuid)
    if (event.target.checked){
      setImgBusy(memo_uuid);
      setArchiveImageList([...archiveImageList, event.target.value])
    } else{
      setArchiveImageList(archiveImageList.filter(function (item) {
        return item !== event.target.value;
      }));
      if(archiveImageList.filter(function (item) {
        return item !== event.target.value;
      }).length === 0){
        setImgBusy(null);
      }
    }
  }

    function api_call_get_token() {
      if  (localStorage.getItem("session_id")=== null)
        return
      const year = new Date().getUTCFullYear();
      const month = new Date().getUTCMonth() + 1; // Months are zero-based
      const day = new Date().getUTCDate();
      const hour = new Date().getUTCHours();
      const minute = new Date().getUTCMinutes();
      const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);      
      const hasForwardParam = window.location.href.includes('forward=');
      if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
      fetch(api.base_url + api.get_token, {
          method: 'POST',
          headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'Authorization': 'bearer ' + localStorage.getItem('session_id'),
              "Access-Control-Allow-Origin": "*"
          },
      }).then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
          setToken(data.token);
        }    
  })
      .catch((err) => {
          console.log(err.message);
      });
    }

    useEffect(() => {
      api_call_get_token();
    }, []);
  
    const api_call_get_file = (file) => {
      
      api_call_get_token();

        fetch(api.get_bucket_object_url +'documents%2F' + customer_name + '%2F' + file, {
          method: 'GET',
          headers: {
              'Content-type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
        })
        .then((response) => response.json())
        .then((data) => {
    
          fetch(data['mediaLink'], {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
              },
          })
          .then(resp => resp.blob())
          .then((blob ) => {
            let url = URL.createObjectURL(blob)
            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              file,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            setImgBusy(null);
        })
        .catch((err) => {
            console.log(err.message);
        });
      })
      .catch((err) => {
          console.log(err.message);
        });

    }

    const fnsetViewEditorfalse = () => {
      if (viewEditor){
        setViewEditor(false);

      }
      setMemo_uuid(null);
    }

    useEffect(() => {
      //console.log(document.querySelectorAll('[data-contents="true"]')[0].innerHTML)
      //setEditorMarkup(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      try {
        setEditorMarkup(document.querySelectorAll('[data-contents="true"]')[0].innerHTML);
      }catch(err) {
      }
    }, [editorState]);


    useEffect(() => {
      if (memo_uuid === null){
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
          convertFromHTML('').contentBlocks,
          convertFromHTML('').entityMap,
        )))
      } else {
        (tableS?.map((item, index) => (
          (item.memo_uuid === memo_uuid)? setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
            convertFromHTML(item.memo).contentBlocks,
            convertFromHTML(item.memo).entityMap,
          ))) : ''
          ))
        )
      }
    }, [memo_uuid]);

    useEffect(() => {
      api_call()
        }, []);

        const api_call = () => {
          if  (localStorage.getItem("session_id")=== null)
            return
          const year = new Date().getUTCFullYear();
          const month = new Date().getUTCMonth() + 1; // Months are zero-based
          const day = new Date().getUTCDate();
          const hour = new Date().getUTCHours();
          const minute = new Date().getUTCMinutes();
          const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);          
          const hasForwardParam = window.location.href.includes('forward=');
          if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
              navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
      
          fetch(api.base_url + api.memo_list, {
              method: 'POST',
              body: JSON.stringify({
                  name: customer_name
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          })
              .then((response) => response.json())
              .then((data) => {
                  if (data['status'] === 'error') {
                    navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                  } else if (data['status'] === 'success') {
                      //    setAlertMessage('Signed in as ' + data['title']);
                      setTableS(data['result_json'])
                      setLoading(false);
                      setImgBusy(null);
                      setViewEditor(false);
                      setMemo_uuid(null);
                  }

              })
              .catch((err) => {
                  console.log(err.message);
              });
      }


  function api_call_delete_memo(memo_uuid){
    setLoading(true);
    fetch(api.base_url + api.memo_delete, {
        method: 'POST',
        body: JSON.stringify({
            name: customer_name,
            memo_uuid: memo_uuid
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data['status'] === 'error') {
                //     setAlertMessage(data['message']);
            } else if (data['status'] === 'success') {
                //    setAlertMessage('Signed in as ' + data['title']);
                // newArray.push(auction);
                var newArray = [];
                tableS?.map((item, index) => (
                  (item.memo_uuid !== memo_uuid)?
                    newArray.push(item) : 
                    ''
                  ))
                setTableS(newArray);
                setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
}

  const api_call_save_memo = () => {
    if(loading){
      return;
    }
    setLoading(true);
    fetch(api.base_url + api.memo_update, {
        method: 'POST',
        body: JSON.stringify({
          memo_uuid: memo_uuid,
          memo: document.querySelectorAll('[data-contents="true"]')[0].innerHTML,
          name: customer_name
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data['status'] === 'error') {
                //     setAlertMessage(data['message']);
            } else if (data['status'] === 'success') {

                setEditorMarkup('');
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
                  convertFromHTML('').contentBlocks,
                  convertFromHTML('').entityMap,
                )));
                //setViewEditor(false);
                //setMemo_uuid(null);
                api_call();
                //    setAlertMessage('Signed in as ' + data['title']);
                //setTableS(data['result_json'])
                //setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
}


 const submit = (memo_uuid) => {
  confirmAlert({
    title: 'Confirmation!',
    message: 'Are you sure to do delete this memo?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => api_call_delete_memo(memo_uuid)
      },
      {
        label: 'No'
      }
    ]
  });
}


const api_call_document_memo = (doc, memo_uuid) => {
  setImgBusy(memo_uuid);
  let formData = new FormData();
  formData.append("name", customer_name);    
  formData.append("folder", folder);
  formData.append("memo_uuid", memo_uuid);
  formData.append(doc.name, doc);

  fetch(api.base_url + api.upload_file_memo, {
      method: 'POST',
      body: formData,
      headers: {
          'Authorization': 'bearer ' + localStorage.getItem('session_id')
      },
  })
  .then((response) => response.json())
  .then((data) => {
      if (data['status'] === 'error') {
          //     setAlertMessage(data['message']);
      } else if (data['status'] === 'success') {
        setTimeout(() => { api_call(); }, 1000);
      }

  })
  .catch((err) => {
      console.log(err.message);
  });
}
const navigate = useNavigate();
const back_clicked = () => {
  navigate("/customer-view".concat("?name=").concat(encodeURIComponent(customer_name)));
}

const archiveImagesPre = () =>{
  confirmAlert({
    title: 'Confirmation!',
    message: 'Are you sure to Archive selected Documents?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => archiveImages()
      },
      {
        label: 'No',
        onClick:() => {setArchiveImageList([]); setImgBusy(null);}
      }
    ]
  });
}

const api_call_update_file = (file_names, is_deleted, deleteImageNameList) => {
  if (token === null){
    return;
  }

  fetch(api.base_url + api.update_file, {
      method: 'POST',
      body: JSON.stringify({
          name: customer_name,
          folder:folder,
          is_deleted:is_deleted,
          file_names:file_names
      }),
      headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'bearer ' + localStorage.getItem('session_id'),
          "Access-Control-Allow-Origin": "*"
      },
  })
  .then((response) => response.json())
  .then((data) => {
      if (data['status'] === 'error') {
      } else if (data['status'] === 'success') {
        api_call();
      }
  })
  .catch((err) => {
      console.log(err.message);
  });
}

const archiveImages = () =>{
  let deleteImageNameList = archiveImageList.map((item, index) => {return item;})
  let deleteImageNameStr = "'''" + deleteImageNameList.join("''', '''") + "'''";
  api_call_update_file(deleteImageNameStr, true, deleteImageNameList);
}


  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={customer_name}>
          {data?.breadcrumbmemo.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                item.path==="/customer-view"?
                <Anchor className="mc-breadcrumb-link" href={item.path+"?name="+encodeURIComponent(customer_name)}>
                  {item.text}
                </Anchor>
                :
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-lg-5">
      <Row>
          <Col xl={9}>
        <div className="mc-mb25">
        {!viewEditor?
        <div >
          <Button key={ 'a2' } className={ 'btn btn-info btn-sm m-1' } onClick={()=>{setMemo_uuid(null); fnsetViewEditor();}}>{'New Memo' }</Button>
          {loading?<Spinner animation="border" />:''}
        </div>
        :
        <div className="mc-mb5">
          <Button key={ 'a1' } className={ 'btn btn-info btn-sm m-1' } onClick={api_call_save_memo}>{'Save' }</Button>
          <Button key={ 'a3' } className={ 'btn btn-info btn-sm m-1' } onClick={fnsetViewEditorfalse}>{'Cancel' }</Button>
          {loading?<Spinner animation="border" />:''}
        </div>
        }
        {viewEditor?
          <div key={'a4'} style={{ border: "1px solid ", padding: '2px', minHeight: '200px' }}>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </div>
          : ''}
      </div>

            {tableS?.map((item, index) => (
              (item.memo_uuid === memo_uuid)?

          <div className="mc-mb25">
          <div  className="mc-mb5">
          <Button key={ 100000+index } className={ 'btn btn-info btn-sm m-1' } onClick={api_call_save_memo}>{'Save' }</Button>
          <Button key={ index } className={ 'btn btn-info btn-sm m-1' } onClick={fnsetViewEditorfalse}>{'Cancel' }</Button>
          </div>
            <div style={{ border: "1px solid ", padding: '2px', minHeight: '200px' }}>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>
          </div>
          :
          <Box key={1000000000+index} className="mc-card mc-mb25">

              <Box key={index} className="mc-card">
                { ReactHtmlParser (item.memo) }
              </Box>
              <div className="mc-footer mc-header-left">
                <Button key={ -1 } className={ 'btn btn-info btn-sm m-1' } onClick={()=>{fnsetViewEditorfalse(); setMemo_uuid(item.memo_uuid)}}>{'Edit' }</Button>
                <Button key={ -2 } className={ 'btn btn-info btn-sm m-1' } onClick={()=>{submit(item.memo_uuid);}}>{'Delete' }</Button>
                <span key={ -3 } style={{textAlign: 'left', float:"left", display:"block"}} >{item.title} {item.created_at.substring(0,16)}</span>
                

                {(item.memo_uuid === imgBusy)?
                  (archiveImageList.length > 0)?
                    <span><Archive size={24} className={'mc-mr5 mc-ml10 pointer'} title={'Archive'} onClick={archiveImagesPre}></Archive>
                    <Text>Archive</Text>
                    </span>
                    :
                    <Spinner animation="border" />
                :
                
                  <span>
                    <input
                      key={0}
                      type="file"
                      name="myImage"
                      hidden={true}
                      onChange={(event) => { 
                        api_call_document_memo(event.target.files[0], item.memo_uuid);
                      }}
                      ref={el => itemsRef.current[index] = el}
                    />
                    <Upload size={24} className={'mc-mr5 mc-ml10 pointer'} onClick={()=>{itemsRef.current[index].click();}} title={'Upload Document'} />
                    <Text>Upload</Text>
                  </span>
                } 
                  
                <div>
                {item.file_names?.map((item2, index) => (
                  <span key={index} className="mc-float-left">
                  <span key={index} className="mc-float-left"> 
                    <Download  size={24} className={'mc-mr5 mc-ml10 mc-float-left pointer'}  title={'Download Document'}  onClick={()=>{api_call_get_file(item2.file_name) }}/>
                    <Text className={'mc-float-left'}>{item2.file_name.split(" - ").slice(-1)[0]}</Text>
                  </span>
                    <input key={'a10'} type="checkbox" id="delPhoto" name="delPhoto" value={item2.file_name} className={'mc-float-left mc-ml5 mc-mt8'} onChange={(e)=>{handleChange(e, item.memo_uuid);}} checked={ archiveImageList.includes(item2.file_name) }></input>
                  </span>
                ))
                }
                </div>

              </div>
          </Box>

            ))}
            </Col>
              <Col xl={3}>
              <TasksCard title={"Actionable Items"} name={customer_name} height={null} />
            </Col>
          </Row>  
      </CardLayout>      
    </PageLayout>
  );
}
