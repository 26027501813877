import React, { useState, useEffect } from "react";
import { Box, Button, Heading, Text, Anchor, Icon, Input } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from "react-bootstrap/Spinner";
import {
  CRMCard,
  EcommerceCard,
  SalesCard,
  AnalyticsCard,
  RevenueCard,
  OrdersCard,
  DevicesCard,
  RepSalesCard,
  ImageCard
} from "../../components/cards";
import data from "../../data/blocks/charts.json";
import { Link } from "react-router-dom";
import { EnvelopeFill } from 'react-bootstrap-icons';
import { TelephoneFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { CheckLg, FileEarmarkX } from 'react-bootstrap-icons';

export default function CustomerInfoCard({ title, dotsMenu, table, name }) {
  const [customerInfoData, setCustomerInfoData] = useState("");
  const [loading, setLoading] = useState(true);
  const [budgetEdit, setBudgetEdit] = useState(false);
  const [birthday, setBirthday] = useState(null)
  const [birthdayEdit, setBirthdayEdit] = useState(false)

  const [report_checked, check_report_checked] = useState(true);

  const navigate = useNavigate();

  const api_call = () => {
    if (name === "" || localStorage.getItem("session_id")===null) {
      return;
    }
    setLoading(true);
    fetch(api.base_url + api.customer_stats, {
      method: "POST",
      body: JSON.stringify({
        name: name,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
          //    setAlertMessage('Signed in as ' + data['title']);
          if(data.hasOwnProperty("result_json")){
            setCustomerInfoData(data["result_json"][0]);
            setBirthday(data["result_json"][0].birthday);
          }
        }
        setLoading(false);

        //React.forwardRef()
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const api_call_birthday = () => {
    if (name === "" || localStorage.getItem("session_id")) {
      return;
    }
    setLoading(true);
    fetch(api.base_url + api.set_birthday, {
      method: "POST",
      body: JSON.stringify({
        customer_name: name,
        birthday: birthday
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  

  useEffect(() => {
    api_call();
  }, []);

  useEffect(() => {
    setBudgetEdit(false);
    check_report_checked(customerInfoData.report_checked);
  }, [customerInfoData]);

  const handleCheckbox = (event) => {

    setLoading(true);
    fetch(api.base_url + api.exclude_customer, {
      method: "POST",
      body: JSON.stringify({
        customer_name: name,
        include: event.target.checked
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === "error") {
          navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        } else if (data["status"] === "success") {
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });

    check_report_checked(event.target.checked);
}

  let vdotsMenu = {
    dots: "more_vert",
    dropdown: [
      { icon: "refresh", text: "Refresh", onClick:()=>{api_call(); } }
    ],
  };

  let vdotsMenuBudget = {
    dots: "more_vert",
    dropdown: [
      { icon: budgetEdit?"save":"edit", text: budgetEdit?"Save":"Edit", onClick:()=>{budgetEdit?setBudgetEdit(null):setBudgetEdit(true)} }
      ,{ icon: "refresh", text: "Refresh", onClick:()=>{api_call(); } }
    ],
  };

  const budgetSaveFunction = (retVal) =>{
    if (name === "") {
      return;
    }
    setLoading(true);
    fetch(api.base_url + api.budget_insert, {
      method: "POST",
      body: JSON.stringify({
        name: name,
        budget_amount: parseInt(retVal.replace(/[^0-9.-]+/g,""))
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data["status"] === "error") {
        } else if (data["status"] === "success") {
          api_call();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  console.log(
    'Pricing Tier :', customerInfoData.PricingTier,
    'Payment Term: ', customerInfoData.PaymentTerm,
    'Rep: ', customerInfoData.Rep,
    'Sales LYTD: ', customerInfoData.LYTDAmount,
    'Sales YTD: ', customerInfoData.Amount,
    'Budget: ', customerInfoData.Budgetpercent,
    'Last Ordered: ', customerInfoData.last_order_date,
    'Open Sales Total:', customerInfoData.OpenSalesTotal
    )
  return (
    <Box className="mc-card" key={122}>


      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        ""
      )}

      {customerInfoData ? (
        <Row key={1} className={"mc-mb15"}>
          <Col xs={6} md={6} xl={4} key={1}>
            {customerInfoData
              ? customerInfoData.contact && customerInfoData.contact !== null
                ? customerInfoData.contact.split(",").map((item, index) => (
                    <Row key={index}>
                      <Col>
                     
                      {item.includes("@")?
                      <EnvelopeFill size={24} className={'mc-float-left mc-mr5'} />
                      :<TelephoneFill size={24} className={'mc-float-left mc-mr5'} />
                      }<Text>{item}</Text>
                     
                      </Col>
                    </Row>
                  ))
                : ""
              : ""}
          </Col>
          <Col xs={6} md={6} xl={4} key={2}>
            <Row key={31}>
              <Col>
                <Text className="">
                  <b>Pricing Tier : </b>{customerInfoData ? customerInfoData.PricingTier : ""}
                </Text>
              </Col>
            </Row>
            <Row key={32}>
              <Col>
                <Text className="">
                <b>Terms : </b> {customerInfoData ? customerInfoData.PaymentTerm : ""}
                </Text>
              </Col>
            </Row>
            <Row key={33}>
              <Col>
                <Text className="">
                  <b>Sale Rep : </b>{customerInfoData ? customerInfoData.Rep : ""}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xs={6} md={6} xl={4} key={3}>
            <Row key={31}>
              <Col>
                <div title='Set Birthday' style={{'cursor':'pointer'}} onClick={(event)=>{if (event.detail===2) {setBirthdayEdit(true); } }}>
                  <b>Birthday : </b>{ !birthdayEdit?birthday : 
                    <div className="mc-float-left">
                        <div className="mc-float-left">
                            <LabelField type = 'date' fieldSize = "h-md" defaultValue={birthday} onChange={(e)=>{setBirthday(e.target.value); }} />
                        </div>
                        <CheckLg className="mc-mt10 mc-ml10" size={24} title='Ok' style={{'cursor':'pointer'}} onClick={()=>{ api_call_birthday(); setBirthdayEdit(false); }} />
                        <FileEarmarkX className="mc-mt10 mc-ml10" size={24} title='Cancel' style={{'cursor':'pointer'}} onClick={()=>{setBirthdayEdit(false);}} />
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Row key={32}>
              <Col>
                  <b>Show on Analytics Report : </b> 
                    <Input
                          type="checkbox"
                          name="allCheck"
                          checked={ report_checked }
                          onChange={ handleCheckbox }
                      />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row key={4} className={"mc-mb25"}>
        <Col xs={12} md={6} xl={6} key={1}>
          {customerInfoData ? (
            <EcommerceCard
              key={11}
              icon={"shopping_cart"}
              trend={
                customerInfoData.Percent < 100 ? "trending_down" : "trending_up"
              }
              title={"Sales YTD"}
              number={customerInfoData.Amount?parseFloat(customerInfoData.Amount).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):'N/A'}
              variant={customerInfoData.Percent < 100 ? "purple" : "green"}
              percent={customerInfoData.LYTDAmount?parseFloat(customerInfoData.LYTDAmount).toLocaleString('en-US', {style: 'currency',currency: 'USD',})  + (customerInfoData.Percent?' | ' + customerInfoData.Percent +' %' : ''):'N/A'}
              compare={"Sales LYTD"}
              dotsMenu={vdotsMenu}
            />
          ) : (
            ""
          )}
        </Col>
        <Col xs={12} md={6} xl={6} key={2}>
          {customerInfoData ? (
            <EcommerceCard
              key={12}
              icon={"shopping_cart"}
              trend={
                customerInfoData.Budgetpercent < 100
                  ? "trending_down"
                  : "trending_up"
              }
              title={new Date().getFullYear() + " Budget"}
              number={customerInfoData.BudgetAmount?customerInfoData.BudgetAmount:0}
              variant={
                customerInfoData.Budgetpercent < 100 ? "purple" : "green"
              }
              percent={customerInfoData.Budgetpercent?String(customerInfoData.Budgetpercent).concat(" %"):'N/A'}
              compare={"Sales YTD"}
              dotsMenu={vdotsMenuBudget}
              is_editable={budgetEdit}
              style = {{style: 'currency',currency: 'USD', minimumFractionDigits:0}}
              saveFunction = {budgetSaveFunction}
            />
          ) : (
            ""
          )}
        </Col>

        <Col xs={12} md={6} xl={6} key={3}>
          {customerInfoData ? (
            <EcommerceCard
              key={13}
              icon={"shopping_cart"}
              trend={""}
              title={"Last Shipped"}
              number={customerInfoData.last_order_date ? customerInfoData.last_order_date : ""}
              variant={"blue"}
              percent={null}
              compare={null}
              dotsMenu={vdotsMenu}
            />
          ) : (
            ""
          )}
        </Col>

        <Col xs={12} md={6} xl={6} key={33}  className={"pointer"} onClick={()=>{navigate("/images?".concat("name=").concat(encodeURIComponent(name)));
}}>
            <ImageCard
              key={14}
              variant={"blue"}
              dotsMenu={vdotsMenu}
            />
        </Col>

        <Col xs={12} md={6} xl={6} key={1}>
          {customerInfoData ? (
            <EcommerceCard
              key={11}
              icon={"shopping_cart"}
              trend={
                customerInfoData.OpenSalesTotal >0 ? "trending_up":"trending_down"
              }
              title={"Open Sales Total"}
              number={customerInfoData.OpenSalesTotal?parseFloat(customerInfoData.OpenSalesTotal).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):'N/A'}
              variant={customerInfoData.OpenSalesTotal > 0 ? "green": "purple"}
              percent={customerInfoData.OpenSalesMTD?parseFloat(customerInfoData.OpenSalesMTD).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):'N/A'}
              compare={"Open Sales MTD"}
              dotsMenu={vdotsMenu}
            />
          ) : (
            ""
          )}
        </Col>

      </Row>
          <RepSalesCard name={name} />
    </Box>
  );
}
