import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Overview, Documentation, ChangeLog, Error } from "./pages/supports";
import { Avatars, Alerts, Buttons, Charts, Tables, Fields, Headings, Colors } from "./pages/blocks";
import {
    Ecommerce, Analytics, CRM, CustomerMapping, Analytics1, Analytics2, CustomerPriceList, ForgotPassword, Register, Login, Logout, UserList, UserProfile, MyAccount, 
    ProductList, ProductView, CustomerView, ProductUpload, InvoiceList, InvoiceDetails, OrderList, Message, 
    Notification, BlankPage, Settings, Memo, Emails, Photo, Search, Authorization, AddCustomer, DOMPROUdellRepsReports, DOMPRODawsonRepsReport, DOMPROSalesReport, AllSalesReport, UserListing, ReportOverview, BalanceSheet, ProfitAndLoss } from "./pages/master";
import {BclAppProducts, BclAppPromoEdu, BclAppVideos, MobileAppPhoto} from "./pages/bcl_app";
import {LeadList, CreateLead, ViewLead, SampleRequest, SampleRequestReport} from "./pages/lead";
import {OutlookEmail} from "./pages/email";
export default function App() {
    return (
        <ThemeProvider>
            <LoaderProvider>
                <BrowserRouter>
                    <Routes>
                        {/* master Pages */}
                        <Route path="/ecommerce" element={<Ecommerce /> } />
                        <Route path="/analytics" element={<Analytics /> } />
                        <Route path="/crm" element={<CRM /> } />
                        <Route path="/customer-mapping" element={<CustomerMapping /> } />
                        <Route path="/user-listing" element={<UserListing />} />
                        <Route path="/add-customer" element={<AddCustomer /> } />
                        <Route path="/analytics-1" element={<Analytics1 />} />
                        <Route path="/analytics-2" element={<Analytics2 />} />
                        <Route path="/DOMPROUdellRepsReports" element={<DOMPROUdellRepsReports /> } />
                        <Route path="/DOMPRODawsonRepsReport" element={<DOMPRODawsonRepsReport /> } />
                        <Route path="/DOMPROSalesReport" element={<DOMPROSalesReport /> } />
                        <Route path="/AllSalesReport" element={<AllSalesReport /> } />
                        <Route path="/customer-price-list" element={<CustomerPriceList /> } />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/authorization" element={<Authorization />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/product-list" element={<ProductList />} />
                        <Route path="/product-view" element={<ProductView />} />
                        <Route path="/customer-view" element={<CustomerView />} />
                        <Route path="/memo" element={<Memo />} />
                        <Route path="/emails" element={<Emails />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/images" element={<Photo />} />
                        <Route path="/bcal-app-images" element={<MobileAppPhoto />} />
                        <Route path="/product-upload" element={<ProductUpload />} />
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/order-list" element={<OrderList />} />
                        <Route path="/message" element={<Message />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/blank-page" element={<BlankPage />} />
                        <Route path="/sample-request" element={<SampleRequest />} />
                        <Route path="/report-overview" element={<ReportOverview /> } />
                        <Route path="/balance-sheet" element={<BalanceSheet /> } />
                        <Route path="/profit-and-loss" element={<ProfitAndLoss /> } />

                        {/* BCL Customer Mobile App */}
                        <Route path="/bcl-app-products" element={<BclAppProducts />} />
                        <Route path="/bcl-app-promo-edu" element={<BclAppPromoEdu />} />                        
                        <Route path="/bcl-app-videos" element={<BclAppVideos />} />

                        {/* Blocks Pages */} 
                        <Route path="/headings" element={<Headings />} />
                        <Route path="/buttons" element={<Buttons />} />
                        <Route path="/avatars" element={<Avatars />} />
                        <Route path="/colors" element={<Colors />} />
                        <Route path="/charts" element={<Charts />} />
                        <Route path="/tables" element={<Tables />} />
                        <Route path="/fields" element={<Fields />} />
                        <Route path="/alerts" element={<Alerts />} />

                        {/* Supports Pages */}
                        <Route path="*" element={<Error />} />
                        <Route path="/" element={<CRM />} />
                        <Route path="/documentation" element={<Documentation />} />
                        <Route path="/changelog" element={<ChangeLog />} />

                        {/* Lead Pages */}
                        <Route path="/lead-list" element={<LeadList />} />
                        <Route path="/all-sample-request" element={<SampleRequestReport />} /> 
                        <Route path="/create-lead" element={<CreateLead />} />
                        <Route path="/lead-view" element={<ViewLead />} />

                        {/* Outlook Email */}
                        <Route path="/outlook-email" element={<OutlookEmail />} />
                    </Routes>
                </BrowserRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}

