import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Save } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { MaterialReactTable } from 'material-react-table';

export default function TasksCard({ title,  data, list_product, tabChangeAddProduct, loading }) {

    const [dataSet, setDataSet] = useState(null)
    const [titleS, setTitleS] = useState(title)
    const [editIndex, setEditIndex] = useState(null)
    const [newTask, setNewTask] = useState(false)
    const [userUUID, setUserUUID] = useState(null)
    const [role, setRole] = useState(null)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [activeUpdateLIst, setActiveUpdateLIst] = useState([])
    const [permissions, setPermissions] = useState([])
    const [productDataset, setProductDataset] = useState(null)

    const columns = data?Object.keys(data[0]).map((item) => 
        ({accessorKey:item,
        header: item.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase())})
      ):[];

      const data2 = data.map((item) => {
        let returnItem = item
        if('video_uuid' in item){
            returnItem = { ...returnItem, video_uuid: <div style={{cursor:'pointer'}} title={item.video_uuid} onClick={()=>{tabChangeAddProduct(item)}}>{item.video_uuid}</div>};
        }
        if('is_active' in item){
            returnItem = { ...returnItem, is_active: item.is_active.toString()};
        }
        

        return returnItem;
      });



    return (
        <Box className="mc-card mc-mb25">
            
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}

            <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={list_product} />

            <Box className="thin-scrolling" style={{ height: "500px" }}>
            {loading ? 
            <div>
                <Spinner animation="border" />
            </div>
            :
                            <MaterialReactTable
                                columns={columns}
                                data={data2}
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                            />

            }
            </Box>
        </Box>
    )
}