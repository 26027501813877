import React, {useEffect, useState} from "react";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
  Input
} from "../../components/elements";
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';
import api from "../../data/master/api.json";
import ReactSimplyCarousel from 'react-simply-carousel';
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import {CardLayout, TabCard} from "../../components/cards";
import data from "../../data/master/imagesView.json";
import { useNavigate } from 'react-router-dom';
import DotsMenu from "../../components/DotsMenu";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Archive, Recycle, Upload } from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FileUploader } from "react-drag-drop-files";

const UploadAndDisplayImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [busy, setBusy] = useState(true);
  const [token, setToken] = useState(null);
  const [images, setImages] = useState(null);
  const [imageCount, setImageCount] = useState(0);
  
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [archiveImageList, setArchiveImageList] = useState([]);

  const search = useLocation().search;
  const customer_name = new URLSearchParams(search).get('name')
  const folder = 'images';
  const fileTypes = ["JPG", "PNG", "GIF", "HEIC", "JPEG"];

  const handleChangeUploadFiles = (file) => {
    for(let index = 0; index < file.length; index++){
      api_call2(file[index]);
      setSelectedImage(file[index])
    }
  };

   function api_call_get_token() {
    if  (localStorage.getItem("session_id")=== null)
      return
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth() + 1; // Months are zero-based
    const day = new Date().getUTCDate();
    const hour = new Date().getUTCHours();
    const minute = new Date().getUTCMinutes();
    const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);    
    const hasForwardParam = window.location.href.includes('forward=');
    if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))

    fetch(api.base_url + api.get_token, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    }).then((response) => response.json())
    .then((data) => {
      if (data["status"] === "error") {
        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
      } else if (data["status"] === "success") {
        setToken(data.token);
      }    
})
    .catch((err) => {
        console.log(err.message);
    });
  }



  const api_call = (is_deleted) => {
    if (token === null){
      return;
    }
    setBusy(true);
    fetch(api.base_url + api.file_list, {
        method: 'POST',
        body: JSON.stringify({
            name: customer_name.replace(/\//g, ""),
            folder:folder,
            is_deleted:is_deleted
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'bearer ' + localStorage.getItem('session_id'),
            "Access-Control-Allow-Origin": "*"
        },
    })
    .then((response) => response.json())
    .then((data) => {
        if (data['status'] === 'error') {
        } else if (data['status'] === 'success') {
          api_call_get_files(data['result_json']);
          if(data['result_json'].length === 0)
            setBusy(false);
        }

    })
    .catch((err) => {
        console.log(err.message);
    });
}

const api_call_get_files = (files) => {
  let l_images = [];
  let count_files = files.length
  files.forEach((currentValue, index)=>{
    fetch(api.get_bucket_object_url +'images%2F' + customer_name.replace(/\//g, "") + '%2F' + currentValue['file_name'], {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
    })
    .then((response) => response.json())
    .then((data) => {
      var file_dict = {};
      var file_name = data['name'].split('/').pop();
      file_dict = {timeCreated:data['timeCreated'], 'file_name':file_name, title: file_name, caption: file_name};

      fetch(data['mediaLink'], {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          },
      })
      .then(resp => resp.blob())
      .then((blob ) => {
        file_dict['url'] = URL.createObjectURL(blob)
      l_images.push(file_dict);

      if(l_images.length === count_files){
        var conf = { prop: 'timeCreated', desc:"desc" };
        l_images = sortBy(l_images, conf)
        setImages(l_images);
        setImageCount(l_images.length);
        setBusy(false);
      }
    })
    .catch((err) => {
        console.log(err.message);
        setBusy(false);
    });
  })
  .catch((err) => {
      console.log(err.message);
      setBusy(false);
    });
  });
}

useEffect(() => {
  api_call_get_token();
}, []);

useEffect(() => {
  api_call(false);
}, [token]);


const api_call2 = (image) => {
  setBusy(true);
  let formData = new FormData();
  formData.append("name", customer_name.replace(/\//g, ""));    
  formData.append("folder", folder);
  formData.append(image.name, image);

  fetch(api.base_url + api.upload_file, {
      method: 'POST',
      body: formData,
      headers: {
          'Authorization': 'bearer ' + localStorage.getItem('session_id')
      },
  })
  .then((response) => response.json())
  .then((data) => {
    setBusy(false);
    api_call(false);
      if (data['status'] === 'error') {
          //     setAlertMessage(data['message']);
      } else if (data['status'] === 'success') {
          //    setAlertMessage('Signed in as ' + data['title']);
      }

  })
  .catch((err) => {
      console.log(err.message);
  });
}

const navigate = useNavigate();
const back_clicked = () => {
  navigate("/customer-view".concat("?name=").concat(encodeURIComponent(customer_name)));
}

let dotsMenu = {
  dots: "more_vert",
  dropdown: [
    { icon: "history", text: "N/A" }
  ],
};

const sortBy = (function () {
  var toString = Object.prototype.toString,
      // default parser function
      parse = function (x) { return x; },
      // gets the item to be sorted
      getItem = function (x) {
        var isObject = x != null && typeof x === "object";
        var isProp = isObject && this.prop in x;
        return this.parser(isProp ? x[this.prop] : x);
      };
      
  /**
   * Sorts an array of elements.
   *
   * @param  {Array} array: the collection to sort
   * @param  {Object} cfg: the configuration options
   * @property {String}   cfg.prop: property name (if it is an Array of objects)
   * @property {Boolean}  cfg.desc: determines whether the sort is descending
   * @property {Function} cfg.parser: function to parse the items to expected type
   * @return {Array}
   */
  return function sortby (array, cfg) {
    if (!(array instanceof Array && array.length)) return [];
    if (toString.call(cfg) !== "[object Object]") cfg = {};
    if (typeof cfg.parser !== "function") cfg.parser = parse;
    cfg.desc = !!cfg.desc ? -1 : 1;
    return array.sort(function (a, b) {
      a = getItem.call(cfg, a);
      b = getItem.call(cfg, b);
      return cfg.desc * (a < b ? -1 : +(a > b));
    });
  };  
}());

const handleChange = (event) => {
  if (event.target.checked){
    setArchiveImageList([...archiveImageList, event.target.value])
  } else{
    setArchiveImageList(archiveImageList.filter(function (item) {
      return item !== event.target.value;
    }));
  }
}

const api_call_update_file = (file_names, is_deleted, deleteImageNameList) => {
  if (token === null){
    return;
  }
  setBusy(true);
  fetch(api.base_url + api.update_file, {
      method: 'POST',
      body: JSON.stringify({
          name: customer_name.replace(/\//g, ""),
          folder:folder,
          is_deleted:is_deleted,
          file_names:file_names
      }),
      headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'bearer ' + localStorage.getItem('session_id'),
          "Access-Control-Allow-Origin": "*"
      },
  })
  .then((response) => response.json())
  .then((data) => {
      if (data['status'] === 'error') {
      } else if (data['status'] === 'success') {

        var conf = { desc:"desc" };
        let vlist = sortBy(archiveImageList, conf)

        let vImages = images;
        vlist.map((item, index) => { 
          return vImages.splice(item, 1);
        });
        setImages(vImages);
        setArchiveImageList([]);
      }
      setBusy(false);
  })
  .catch((err) => {
      console.log(err.message);
  });
}

const archiveImagesPre = () =>{
  confirmAlert({
    title: 'Confirmation!',
    message: 'Are you sure to Archive selected Images?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => archiveImages()
      },
      {
        label: 'No'
      }
    ]
  });
}

const restoreImagesPre = () =>{
  confirmAlert({
    title: 'Confirmation!',
    message: 'Are you sure to Restore selected Images?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => restoreImages()
      },
      {
        label: 'No'
      }
    ]
  });
}

const archiveImages = () =>{
  let deleteImageNameList = archiveImageList.map((item, index) => {return images[item].file_name;})
  let deleteImageNameStr = "'''" + deleteImageNameList.join("''', '''") + "'''";
  api_call_update_file(deleteImageNameStr, true, deleteImageNameList);
}
const restoreImages = () =>{
  let deleteImageNameList = archiveImageList.map((item, index) => {return images[item].file_name;})
  let deleteImageNameStr = "'''" + deleteImageNameList.join("''', '''") + "'''";
  api_call_update_file(deleteImageNameStr, false, deleteImageNameList);
}
function handleTabSelect(key) {
  if (key === "profile")
  {
    setImages(null);
    api_call(false);
  }
  else
  {
    setImages(null);
    api_call(true);
  }
}
const inputFileRef = React.useRef();

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={customer_name}>
          {data?.breadcrumbmemo.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                item.path==="/customer-view"?
                <Anchor className="mc-breadcrumb-link" href={item.path+"?name="+encodeURIComponent(customer_name)}>
                  {item.text}
                </Anchor>
                :
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout>
      <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs" onSelect={handleTabSelect} >
        <Tab eventKey="profile" title="Live" className="mc-tabpane profile" disabled={busy}>
          <Box className="mc-card mc-mb25">
          
              { (busy!==true)?
              
              <Row>
                <Col xs={5} sm={5} xl={5}>
                  <FileUploader key={0} handleChange={handleChangeUploadFiles} name="myImage" types={fileTypes} multiple={true} />
                </Col>
                {archiveImageList.length > 0 ?
                  <Col xs={7} sm={7} xl={7}>
                    <span className={'mc-mt10 pointer'} onClick={archiveImagesPre}><Archive size={24} className={'pointer mc-mt10'} title={'Archive'}></Archive> Archive</span>
                  </Col>
                : ''}
                </Row>
              :<Spinner animation="border" className={'mc-ml10'}/>
              }
          </Box>
     
          {
          images?
          <div>
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
            <Row key={1} className={"mc-mb15"}>
            {images.map((item, index) => (
              <Col xl={2} xs={6} sm={4} md={4}>
              <Box className="pointer" key={122} >
                  <div>
                    <input type="checkbox" id="delPhoto" name="delPhoto" value={index} onChange={handleChange} checked={ archiveImageList.includes(index.toString()) }></input>
                  </div>
                  <img alt="not fount" src={item.url} width={"150px"} onClick={()=>{setIsOpen(true); setImgIndex(index);}} />
                  
              </Box>
              </Col>
            ))}
          </Row>
          </div>:""
        }

  </Tab>

  <Tab eventKey="password" title="Archived" className="mc-tabpane password"  disabled={busy}>
    <Box className="mc-card mc-mb25">
          
              { (busy!==true)?
              <div>
                {archiveImageList.length > 0 ?
                <span className={'mc-mr5 pointer'} onClick={restoreImagesPre}><Recycle size={24} className={'mc-ml10 pointer'} title={'Restore'}></Recycle> Restore</span>
              : ''}
              </div>
              :<Spinner animation="border" className={'mc-ml10'}/>
              }
          </Box>
     
          {
          images?
          <div>
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
            <Row key={1} className={"mc-mb15"}>
            {images.map((item, index) => (
              <Col xl={2} xs={6} sm={4} md={4}>
              <Box className="pointer" key={122} >
                  <div>
                    <input type="checkbox" id="delPhoto" name="delPhoto" value={index} onChange={handleChange} checked={archiveImageList.includes(index.toString())}></input>
                  </div>
                  <img alt="not fount" src={item.url} width={"150px"} onClick={()=>{setIsOpen(true); setImgIndex(index);}} />
                  
              </Box>
              </Col>
            ))}
          </Row>
          </div>:""
        }


    </Tab>

</Tabs>
      </CardLayout>
      {isOpen && <Lightbox
        imageTitle={images[imgIndex].title}
        imageCaption={images[imgIndex].caption}
        mainSrc={images[imgIndex].url}
        nextSrc={images[(imgIndex + 1) % images.length].url}
        prevSrc={images[(imgIndex + images.length - 1) % images.length].url}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setImgIndex((imgIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)}
      />}
    </PageLayout>  
  );
};

export default UploadAndDisplayImage;