import React, {useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/create_lead.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, fetchUser, setMessage, saveLead, setFieldValues} from '../../actions';
import { Alert } from "react-bootstrap";
import { IconField, IconTextarea, LabelField, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";

const { v4: uuidv4 } = require('uuid');


export default function LeadList() {

    let fieldValues = useSelector((state) => state.fieldValues);
    const message = useSelector((state) => state.message);
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/lead-list");
    }

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(setFieldValues([]));
      }, []);

    const dispatch = useDispatch();

    const userList = useSelector((state) => state.userList);
    const titleList = userList.map((user) => user.title);

    const handleChange = (column_name, event) => {
        fieldValues = ({ ...fieldValues, [column_name]: event.target.value });
        dispatch(setFieldValues(fieldValues));
        console.log(fieldValues)

      };

    const saveForm = () =>{
        console.log('its here!')
        if (!fieldValues.hasOwnProperty('Company') || !fieldValues.Company || !fieldValues.hasOwnProperty('Last_Name') || !fieldValues.Last_Name){
            dispatch(setMessage('Required fields are missing!'));
        }else{
            fieldValues = ({ ...fieldValues, Lead_UUID: uuidv4() });
            if (!fieldValues.hasOwnProperty('Lead_Owner')){
                fieldValues = ({ ...fieldValues, Lead_Owner_UUID: localStorage.getItem('user_uuid') });
            }else{
                fieldValues = ({ ...fieldValues, Lead_Owner_UUID: userList.find(item => item.title === fieldValues.Lead_Owner).user_uuid });
                delete fieldValues.Lead_Owner
            }
            dispatch(saveLead(fieldValues));
            setTimeout(back_clicked, 2000); // Delay in milliseconds
        }
    }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={'Add Lead'}>
          {data?.breadcrumb_createlead.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{back_clicked()}}>Cancel</Button>
      <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{saveForm()}}>Save</Button>
      {(message !== '')? 
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
                :null
      }
      

        {data?.create_lead.map((item, index) =>(
  
                <CardLayout className="p-lg-5 mb-4">
                    {/* <CardHeader title={Object.keys(item)[0]} /> */}
                    <DivideTitle title={Object.keys(item)[0]} className="mb-4 mt-3" />
                    <Row>
                        <Col xs={10} md={Object.keys(item)[0]==='Lead Notes'?12:5} xl={Object.keys(item)[0]==='Lead Notes'?12:4}>
                        {item[Object.keys(item)[0]].map((item2, index2) =>(
                            (index2+1)%3===1?
                            item2.component === 'LabelField'?
                                <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                            :item2.component === 'LabelTextarea'?
                                <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                            :null
                            :null
                        ))}

                        </Col>
                        {/* <Col xs={2} md={1} xl={1}>
                        </Col> */}
                        <Col xs={10} md={5} xl={4}>
                        {item[Object.keys(item)[0]].map((item2, index2) =>(
                            ((index2+1)%3)===2?
                                item2.component === 'LabelField'?
                                    <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :item2.component === 'LabelTextarea'?
                                    <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :null
                            :null
                        ))}
                        </Col>

                        <Col xs={10} md={5} xl={4}>
                        {item[Object.keys(item)[0]].map((item2, index2) =>(
                            ((index2+1)%3)===0?
                                item2.component === 'LabelField'?
                                    <LabelField label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.column==='Lead_Owner'?titleList:item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :item2.component === 'LabelTextarea'?
                                    <LabelTextarea label={item2.label} type={item2.type} fieldSize={item2.fieldSize} labelDir={"label-col mb-4 " + item2.labelClass} option={item2.options} placeholder={item2.label} onChange={(event)=>{handleChange(item2.column, event)}} />
                                :null
                            :null
                        ))}
                        </Col>
                    </Row>
                </CardLayout>

        ))
        }
      {/* </CardLayout> */}
    </PageLayout>
  );
}
;