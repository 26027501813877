import React, {useState, } from "react";
import { Image, List, Box, Icon, Text, Item, Anchor, Button } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/notification.json";

export default function Notification() {
    const [notifyData, setNotifyData] = useState(localStorage.getItem('notifyData')?JSON.parse(localStorage.getItem('notifyData')):null);

    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title={ data?.pageTitle }>
                    {data?.breadcrumb.map((item, index) => (
                        <Item key={ index } className="mc-breadcrumb-item">
                            {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                        </Item>
                    ))}
                </Breadcrumb>
            </CardLayout>
            <CardLayout>
                <CardHeader title="all notification" /* dotsMenu={ data?.dotsMenu } */ />
                <List className="mc-notify-list mc-header-dropdown-notify-media">
                    {notifyData?.dropdown.items?.map((item, index) => (
                        <Item className="mc-notify-item" key={ index }>
                            <Anchor className="mc-notify-content" href={ item.path }>
                                <Box className="mc-notify-media">
                                    <Image src={ item.notify.image } alt={ 'Task' } />
                                    <Icon className={`material-icons ${'red'}`}>{'task'}</Icon>
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span"> { item.note } </Text>
                                    <Text as="small">{ item.longMoment }</Text>
                                </Box>
                            </Anchor>
                        {/*    <Button className="mc-notify-close"><Icon>{ item.button }</Icon></Button> */}
                        </Item>
                    ))}
                </List>
            </CardLayout>
        </PageLayout>
    )
}