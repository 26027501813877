import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto';
import { Pie, Line, Doughnut, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AlignCenter } from "react-bootstrap-icons";

ChartJS.register(
  Tooltip,
  Legend,
  ChartDataLabels // Register the plugin
);

export default function CRM() {
    const [infoData, setInfoData] = useState(localStorage.getItem('infoData')?JSON.parse(localStorage.getItem('infoData')):null);
    const [infoDataSales, setInfoDataSales] = useState(localStorage.getItem('infoDataSales')?JSON.parse(localStorage.getItem('infoDataSales')):null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [budgetEdit, setBudgetEdit] = useState(false);

    const [classS, setClassS] = useState(localStorage.getItem('classS')?localStorage.getItem('classS'):'All')
    const [nameAddressS, setNameAddress] = useState(localStorage.getItem('nameAddressS')?localStorage.getItem('nameAddressS'):'')
    const [changeStats, setChangeStats] = useState(false)
    const [chartData, setChartData] = useState({
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
            datasets: [
                {
                label :'Dataset',
                data: [30, 15, 40, 10, 5],
                backgroundColor: ['#D3D3D3'],
                hoverBackgroundColor: ['#FF000080', '#FFFF0080', '#00800080', '#0000FF80', '#80008080'],
                borderColor: '#808080', // Line color
                fill: true
                }
            ]
        })
    const [result_revenue_benchmark, set_result_revenue_benchmark] = useState('')

    const [result_cogs_benchmark, set_result_cogs_benchmark] = useState('')
    const [chartDataCogs, setChartDataCogs] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
        datasets: [
            {
            label :'Dataset',
            data: [30, 15, 40, 10, 5],
            backgroundColor: ['#D3D3D3'],
            hoverBackgroundColor: ['#FF000080', '#FFFF0080', '#00800080', '#0000FF80', '#80008080'],
            borderColor: '#808080', // Line color
            fill: true
            }
        ]
    })

const [result_gross_profit, set_result_gross_profit] = useState('')
const [chartDataGrossProfit, setChartDataGrossProfit] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
        {
        label :'Dataset',
        data: [30, 15, 40, 10, 5],
        backgroundColor: ['#D3D3D3'],
        hoverBackgroundColor: ['#FF000080', '#FFFF0080', '#00800080', '#0000FF80', '#80008080'],
        borderColor: '#808080', // Line color
        fill: true
        }
    ]
})

const [result_net_profit, set_result_net_profit] = useState('')
const [chartDataNetProfit, setChartDataNetProfit] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
        {
        label :'Dataset',
        data: [30, 15, 40, 10, 5],
        backgroundColor: ['#D3D3D3'],
        hoverBackgroundColor: ['#FF000080', '#FFFF0080', '#00800080', '#0000FF80', '#80008080'],
        borderColor: '#808080', // Line color
        fill: true
        }
    ]
})

    const [chartDataGrossProfitMargin, setChartDataGrossProfitMargin] = useState({
            labels: ['Gross Profit Margin'],
            datasets: [{
                data: [40, 60], // 40% value, 60% empty
                backgroundColor: ['#2D738F', '#e9ecef'], // Blue for data, light gray for empty
                hoverBackgroundColor: ['#2D738F', '#e9ecef80'], // Adjust hover opacity as desired
                borderColor: '#fff', // White border for better visibility
                borderWidth: 1, // Adjust border width as desired
            }]
        });
    
        const [chartDataOperatingExpenseRatio, setChartDataOperatingExpenseRatio] = useState({
            labels: ['Operating Expense Ratio'],
            datasets: [{
                data: [40, 60], // 40% value, 60% empty
                backgroundColor: ['#2D738F', '#e9ecef'], // Blue for data, light gray for empty
                hoverBackgroundColor: ['#2D738F', '#e9ecef80'], // Adjust hover opacity as desired
                borderColor: '#fff', // White border for better visibility
                borderWidth: 1, // Adjust border width as desired
            }]
        });

        const [chartDataNetProfitMargin, setChartDataNetProfitMargin] = useState({
            labels: ['Net Profit Margin'],
            datasets: [{
                data: [40, 60], // 40% value, 60% empty
                backgroundColor: ['#2D738F', '#e9ecef'], // Blue for data, light gray for empty
                hoverBackgroundColor: ['#2D738F', '#e9ecef80'], // Adjust hover opacity as desired
                borderColor: '#fff', // White border for better visibility
                borderWidth: 1, // Adjust border width as desired
            }]
        });


    const [chartData5, setChartData5 ] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            { // Benchmark line dataset
                label: 'Gross Profit Margin %', // Optional label for the line
                type:'line',
                data: [35, 35, 35, 35, 35, 35], // Constant value for the line
                backgroundColor: 'rgba(255, 99, 132, 0.2)', // Semi-transparent red
                borderColor: 'red',
                borderWidth: 2, // Thicker line for emphasis
                pointRadius: 0, // Hide data points on the line
                yAxisID: 'y-percentage',
            },
            
            { // Your original data
                label: 'Revenue',
                data: [1000, 2000, 3000, 5000, 2500, 4000],
                backgroundColor: ['#297E96'], // Your colors
                borderColor: '#000',
                borderWidth: 1,
                yAxisID: 'y-large-numbers',
            },
            { // Your original data
                label: 'COGS',
                data: [1000, 2000, 3000, 5000, 2500, 4000],
                backgroundColor: ['#007bff80'], // Your colors
                borderColor: '#000',
                borderWidth: 1,
                yAxisID: 'y-large-numbers',
            },
            
        ]
    });

    const [chartData6, setChartData6 ] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            { // Benchmark line dataset
                label: 'Net Profit Margin %', // Optional label for the line
                type: 'line',
                data: [35, 35, 35, 35, 35, 35], // Constant value for the line
                backgroundColor: 'rgba(255, 99, 132, 0.2)', // Semi-transparent red
                borderColor: 'red',
                borderWidth: 2, // Thicker line for emphasis
                pointRadius: 0, // Hide data points on the line
                yAxisID: 'y-percentage',
            },
            { // Your original data
                label: 'Gross Profit',
                data: [10, 20, 30, 50, 25, 40],
                backgroundColor: ['#297E96'], // Your colors
                borderColor: '#000',
                borderWidth: 1,
                yAxisID: 'y-large-numbers',
            },
            { // Your original data
                label: 'OPEX',
                data: [8, 16, 25, 40, 15, 30],
                backgroundColor: ['#007bff80'], // Your colors
                borderColor: '#000',
                borderWidth: 1,
                yAxisID: 'y-large-numbers',
            }
        ]
    });

    const [reportPeriod, setReportPeriod ] = useState('');
    const [opex_current_year, set_opex_current_year ] = useState(0); 
    const [opex_last_year, set_opex_last_year ] = useState(0);

    // const [result_values_Revenue, set_result_values_Revenue] = useState([])
    // const [result_values_COGS, set_result_values_COGS] = useState([])
    // const [result_values_Gross_Profit, set_result_values_Gross_Profit] = useState([])
    // const [result_values_OPEX, set_result_values_OPEX] = useState([])

    const options5 = {
        plugins: {
            tooltip: {
                // callbacks: {
                //         label: function(tooltipItem) {
                //             const datasetLabel = tooltipItem.dataset.label;
                //             const dataIndex = tooltipItem.dataIndex;
                //             // Customize tooltip for each dataset
                //             if (datasetLabel === 'Revenue' && result_values_Revenue.length >= dataIndex) {
                //                 const actualValue = Number(result_values_Revenue[dataIndex]).toLocaleString('en-US');
                //                 return `Revenue: ${actualValue}`;
                //             } else if (datasetLabel === 'COGS' && result_values_COGS.length >= dataIndex) {
                //                 const actualValue = Number(result_values_COGS[dataIndex]).toLocaleString('en-US');
                //                 return `COGS: ${actualValue}`;
                //             } else if (datasetLabel === 'Gross Profit Margin %') {
                //                 // Assuming you want to show the percentage value as it is
                //                 const percentageValue = tooltipItem.raw;
                //                 return `Gross Profit Margin: ${percentageValue}%`;
                //             }
                //             else if (datasetLabel === 'Gross Profit' && result_values_Gross_Profit.length >= dataIndex) {
                //                 const actualValue = Number(result_values_Gross_Profit[dataIndex]).toLocaleString('en-US');
                //                 return `Gross Profit: ${actualValue}`;
                //             } else if (datasetLabel === 'OPEX' && result_values_OPEX.length >= dataIndex) {
                //                 const actualValue = Number(result_values_OPEX[dataIndex]).toLocaleString('en-US');
                //                 return `OPEX: ${actualValue}`;
                //             } else if (datasetLabel === 'Net Profit Margin %') {
                //                 // Assuming you want to show the percentage value as it is
                //                 const percentageValue = tooltipItem.raw;
                //                 return `Net Profit Margin: ${percentageValue}%`;
                //             }

                //             // Default return if no dataset matches
                //             return `${datasetLabel}: ${tooltipItem.raw}`;
                //         }
                //     }
            },
            datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function(value, context) {
                return value + '%';
            },
        },
          },
        scales: {
            responsive: true,
            yAxes: [{
                ticks: {
                    beginAtZero: true // Optional, ensures y-axis starts at 0
                },
                gridLines: { // Grid line for benchmark
                    drawBorder: false, // Hide the border of the y-axis
                    color: 'rgba(255, 99, 132, 0.2)', // Semi-transparent red for the grid line
                    lineWidth: 1,
                },
            }]
        }
    };

    const options6 = {
        plugins: {
            tooltip: {
                callbacks: {
                        
                    }
            },
        //     datalabels: {
        //     anchor: 'end',
        //     align: 'end',
        //     formatter: function(value, context) {
        //         return value + '%';
        //     },
        // },
          },
        scales: {
            'y-percentage': {
                type: 'linear',
                position: 'left',
                // min: 0,
                max: 100,
                title: {
                  display: true,
                  text: 'Percentage (%)',
                },
              },
              'y-large-numbers': {
                type: 'linear',
                position: 'right',
                title: {
                  display: true,
                  text: 'Large Numbers',
                },
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
            responsive: true,
            yAxes: [{
                ticks: {
                    beginAtZero: true // Optional, ensures y-axis starts at 0
                },
                gridLines: { // Grid line for benchmark
                    drawBorder: false, // Hide the border of the y-axis
                    color: 'rgba(255, 99, 132, 0.2)', // Semi-transparent red for the grid line
                    lineWidth: 1,
                },
            }]
        }
    };

    const options = {
        maintainAspectRatio: false, // Prevent automatic resizing
        cutout: '70%', // Adjust cutout percentage for a wider gauge ring
        rotation: -1.5 * Math.PI, // Rotate chart for starting point at top
        circumference: 2 * Math.PI, // Full circle
        animation: { // Optional animation configuration
            animateRotate: false, // Disable rotation animation
        },
        plugins: {
            legend: { // Optional legend configuration
                display: false, // Hide legend (if not needed)
            }
        }
    };
// /////////////////////////
console.log(result_revenue_benchmark.split(" | ")[0])
const data_data7 = [result_revenue_benchmark.split(" | ")[0].slice(0, -1), result_cogs_benchmark.split(" | ")[0].slice(0,-1), result_gross_profit.split(" | ")[0].slice(0,-1), (opex_current_year/1000000).toFixed(2), result_net_profit.split(" | ")[0].slice(0,-1)]
const barColors = [(result_revenue_benchmark.match(/\(([^)]+)%\)/)?result_revenue_benchmark.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":"#287E8F"),(result_cogs_benchmark.match(/\(([^)]+)%\)/)?result_cogs_benchmark.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":"#287E8F"),(result_gross_profit.match(/\(([^)]+)%\)/)?result_gross_profit.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":"#287E8F"), (opex_current_year > set_opex_last_year)?"#E85E76":"#287E8F",result_net_profit.match(/\(([^)]+)%\)/)?result_net_profit.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":"#287E8F" ]
const data7 = {
    labels: ['Revenue', 'COGS', 'Gross Profit', 'OPEX', 'Net Profit'],
    datasets: [
        {
            label: 'Income Statement',
            data: data_data7,
            backgroundColor: function(context) {
                // const value = context.dataset.data[context.dataIndex];
                const target = barColors[context.dataIndex];
                return target; // Green if target is met, Red if not
            },
        }
    ],
};

const options7 = {
    indexAxis: 'y', // Display the bars horizontally
    scales: {
        x: {
            stacked: true, // Stack the bars along the x-axis
            display: true,
            beginAtZero: true,
            
            },
        y: {
            stacked: true, // Stack the bars along the y-axis
            display: true,
        },
    },
    plugins: {
        tooltip: {
          callbacks: {
                label: function(tooltipItem) {
                    return tooltipItem.dataset.label + ': ' + tooltipItem.raw + 'M';
                }
            }
        },
        datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function(value, context) {
                return value + 'M';
            },
        },
      }
};
// /////////////////////////
    const api_call = () => {
        fetch(api.base_url + api.report_kpis, {
          method: "POST",
          body: JSON.stringify({
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + localStorage.getItem("session_id"),
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data["status"] === "error") {
              navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
            } else if (data["status"] === "success") {
                setChartData(data["result_json_revenue"]);
                set_result_revenue_benchmark(data["result_revenue_benchmark"]);
            }
            setLoading1(false);
          })
          .catch((err) => {
            console.log(err.message);
          });



          fetch(api.base_url + api.report_kpis_cogs, {
            method: "POST",
            body: JSON.stringify({
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: "bearer " + localStorage.getItem("session_id"),
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data["status"] === "error") {
                navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
              } else if (data["status"] === "success") {
                  setChartDataCogs(data["result_json_revenue"]);
                  set_result_cogs_benchmark(data["result_revenue_benchmark"]);
              }
              setLoading1(false);
            })
            .catch((err) => {
              console.log(err.message);
            });

            fetch(api.base_url + api.report_kpis_gross_profit, {
                method: "POST",
                body: JSON.stringify({
                }),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: "bearer " + localStorage.getItem("session_id"),
                  "Access-Control-Allow-Origin": "*",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data["status"] === "error") {
                    navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                  } else if (data["status"] === "success") {
                      setChartDataGrossProfit(data["result_json_revenue"]);
                      set_result_gross_profit(data["result_revenue_benchmark"]);
                  }
                  setLoading1(false);
                })
                .catch((err) => {
                  console.log(err.message);
                });


                fetch(api.base_url + api.report_kpis_net_profit, {
                    method: "POST",
                    body: JSON.stringify({
                    }),
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: "bearer " + localStorage.getItem("session_id"),
                      "Access-Control-Allow-Origin": "*",
                    },
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      if (data["status"] === "error") {
                        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                      } else if (data["status"] === "success") {
                          setChartDataNetProfit(data["result_json_revenue"]);
                          set_result_net_profit(data["result_revenue_benchmark"]);
                      }
                      setLoading1(false);
                    })
                    .catch((err) => {
                      console.log(err.message);
                    });

                    fetch(api.base_url + api.report_kpis_gross_profit_margin, {
                        method: "POST",
                        body: JSON.stringify({
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          Authorization: "bearer " + localStorage.getItem("session_id"),
                          "Access-Control-Allow-Origin": "*",
                        },
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          if (data["status"] === "error") {
                            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                          } else if (data["status"] === "success") {
                              setChartDataGrossProfitMargin(data["result_json"]);
                           }
                          setLoading1(false);
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });

                    fetch(api.base_url + api.report_kpis_operating_expense_ratio, {
                        method: "POST",
                        body: JSON.stringify({
                        }),
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: "bearer " + localStorage.getItem("session_id"),
                            "Access-Control-Allow-Origin": "*",
                        },
                        })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data["status"] === "error") {
                            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                            } else if (data["status"] === "success") {
                                setChartDataOperatingExpenseRatio(data["result_json"]);
                            }
                            setLoading1(false);
                        })
                        .catch((err) => {
                            console.log(err.message);
                        });

                        fetch(api.base_url + api.report_kpis_net_profit_margin, {
                            method: "POST",
                            body: JSON.stringify({
                            }),
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                Authorization: "bearer " + localStorage.getItem("session_id"),
                                "Access-Control-Allow-Origin": "*",
                            },
                            })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data["status"] === "error") {
                                navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                } else if (data["status"] === "success") {
                                    setChartDataNetProfitMargin(data["result_json"]);
                                }
                                setLoading1(false);
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });

                            fetch(api.base_url + api.report_chart_revenue_cogs_gpm, {
                                method: "POST",
                                body: JSON.stringify({
                                }),
                                headers: {
                                    "Content-type": "application/json; charset=UTF-8",
                                    Authorization: "bearer " + localStorage.getItem("session_id"),
                                    "Access-Control-Allow-Origin": "*",
                                },
                                })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data["status"] === "error") {
                                    navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                    } else if (data["status"] === "success") {
                                        setChartData5(data["result_json"]);
                                    }
                                    setLoading1(false);
                                })
                                .catch((err) => {
                                    console.log(err.message);
                                });


                                fetch(api.base_url + api.report_chart_gross_profit_opex_npm, {
                                    method: "POST",
                                    body: JSON.stringify({
                                    }),
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8",
                                        Authorization: "bearer " + localStorage.getItem("session_id"),
                                        "Access-Control-Allow-Origin": "*",
                                    },
                                    })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data["status"] === "error") {
                                        navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                        } else if (data["status"] === "success") {
                                            setChartData6(data["result_json"]);
                                        }
                                        setLoading1(false);
                                    })
                                    .catch((err) => {
                                        console.log(err.message);
                                    });

                                    fetch(api.base_url + api.report_kpis_period, {
                                        method: "POST",
                                        body: JSON.stringify({
                                        }),
                                        headers: {
                                            "Content-type": "application/json; charset=UTF-8",
                                            Authorization: "bearer " + localStorage.getItem("session_id"),
                                            "Access-Control-Allow-Origin": "*",
                                        },
                                        })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (data["status"] === "error") {
                                            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                            } else if (data["status"] === "success") {
                                                setReportPeriod(data["result_json_report_data"][0]);
                                            }
                                            setLoading1(false);
                                        })
                                        .catch((err) => {
                                            console.log(err.message);
                                        });

                                        fetch(api.base_url + api.report_kpis_opex, {
                                            method: "POST",
                                            body: JSON.stringify({
                                            }),
                                            headers: {
                                                "Content-type": "application/json; charset=UTF-8",
                                                Authorization: "bearer " + localStorage.getItem("session_id"),
                                                "Access-Control-Allow-Origin": "*",
                                            },
                                            })
                                            .then((response) => response.json())
                                            .then((data) => {
                                                if (data["status"] === "error") {
                                                navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                                } else if (data["status"] === "success") {
                                                    // setReportPeriod(data["result_json_report_data"][0]);
                                                    // console.log(data)
                                                    set_opex_current_year(data["opex_current_year"]);
                                                    set_opex_last_year(data["opex_last_year"]);
                                                }
                                                setLoading1(false);
                                            })
                                            .catch((err) => {
                                                console.log(err.message);
                                            });

                                            // fetch(api.base_url + api.report_values_revenue_cogs_gp_opex, {
                                            //     method: "POST",
                                            //     body: JSON.stringify({
                                            //     }),
                                            //     headers: {
                                            //         "Content-type": "application/json; charset=UTF-8",
                                            //         Authorization: "bearer " + localStorage.getItem("session_id"),
                                            //         "Access-Control-Allow-Origin": "*",
                                            //     },
                                            //     })
                                            //     .then((response) => response.json())
                                            //     .then((data) => {
                                            //         if (data["status"] === "error") {
                                            //         navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                                            //         } else if (data["status"] === "success") {
                                            //             // setReportPeriod(data["result_json_report_data"][0]);
                                            //             // console.log(data)
                                            //             set_result_values_Revenue(data["Revenue"]);
                                            //             set_result_values_COGS(data["COGS"]);
                                            //             set_result_values_Gross_Profit(data["Gross Profit"]);
                                            //             set_result_values_OPEX(data["OPEX"]);
                                            //         }
                                            //         setLoading1(false);
                                            //     })
                                            //     .catch((err) => {
                                            //         console.log(err.message);
                                            //     });

                                            
                                        

      };

    useEffect(() => {
            api_call();
    }, []);

  const navigate = useNavigate();


   
    return (
        <PageLayout>
            <Row>
                <h3>{reportPeriod['period']}</h3>
            </Row>
            <Row>
                <Col xs={3} xl={3}>
                    <div>
                        <h6 style={{textAlign:"center",  color:"#6B6666"}}>Revenue</h6>
                        <h4 style={{textAlign:"center",  color:result_revenue_benchmark.match(/\(([^)]+)%\)/)?result_revenue_benchmark.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":null, paddingTop:10}}>{result_revenue_benchmark.split(" | ")[0]} {result_revenue_benchmark.match(/\(([^)]+)%\)/)?result_revenue_benchmark.match(/\(([^)]+)%\)/)[1]>0?"✔":" !":null}</h4>
                        <Line data={chartData} 
                            options= {
                                {
                                'plugins': {
                                    tooltip: {
                                    callbacks: {
                                            label: function(tooltipItem) {
                                                return  tooltipItem.raw + '$';
                                            }
                                        }
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        formatter: function(value, context) {
                                            return value + '$';
                                        },
                                    },
                                'filler': {
                                    'propagate': false,
                                    },
                                title: {
                                    'display': true,
                                    'text': (ctx) => result_revenue_benchmark.split(" | ")[1]
                                    }
                                },
                                'interaction': {
                                    'intersect': false,
                                    }
                                }
                                }
                            fill= {'start'}
                            label ='Dataset'
                        />
                    </div>
                </Col>

                <Col xs={3} xl={3}>
                    <div>
                        <h6 style={{textAlign:"center",  color:"#6B6666"}}>COGS</h6>
                        <h4 style={{textAlign:"center",  color:result_cogs_benchmark.match(/\(([^)]+)%\)/)?result_cogs_benchmark.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":null, paddingTop:10}}>{result_cogs_benchmark.split(" | ")[0]} {result_cogs_benchmark.match(/\(([^)]+)%\)/)?result_cogs_benchmark.match(/\(([^)]+)%\)/)[1]>0?"✔":" !":null}</h4>
                        <Line data={chartDataCogs} 
                            options= {
                                {
                                'plugins': {
                                    tooltip: {
                                    callbacks: {
                                            label: function(tooltipItem) {
                                                return  tooltipItem.raw + '$';
                                            }
                                        }
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        formatter: function(value, context) {
                                            return value + '$';
                                        },
                                    },
                                'filler': {
                                    'propagate': false,
                                    },
                                title: {
                                    'display': true,
                                    'text': (ctx) => result_cogs_benchmark.split(" | ")[1]
                                    }
                                },
                                'interaction': {
                                    'intersect': false,
                                    }
                                }
                                }
                            fill= {'start'}
                            label ='Dataset'
                        />
                    </div>
                </Col>

                <Col xs={3} xl={3}>
                    <div>
                        <h6 style={{textAlign:"center",  color:"#6B6666"}}>Gross Profit</h6>
                        <h4 style={{textAlign:"center",  color:result_gross_profit.match(/\(([^)]+)%\)/)?result_gross_profit.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":null, paddingTop:10}}>{result_gross_profit.split(" | ")[0]} {result_gross_profit.match(/\(([^)]+)%\)/)?result_gross_profit.match(/\(([^)]+)%\)/)[1]>0?"✔":" !":null}</h4>
                        <Line data={chartDataGrossProfit} 
                            options= {
                                {
                                'plugins': {
                                    tooltip: {
                                    callbacks: {
                                            label: function(tooltipItem) {
                                                return  tooltipItem.raw + '$';
                                            }
                                        }
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        formatter: function(value, context) {
                                            return value + '$';
                                        },
                                    },
                                'filler': {
                                    'propagate': false,
                                    },
                                title: {
                                    'display': true,
                                    'text': (ctx) => result_gross_profit.split(" | ")[1]
                                    }
                                },
                                'interaction': {
                                    'intersect': false,
                                    }
                                }
                                }
                            fill= {'start'}
                            label ='Dataset'
                        />
                    </div>
                </Col>

                <Col xs={3} xl={3}>
                    <div>
                        <h6 style={{textAlign:"center",  color:"#6B6666"}}>Net Profit</h6>
                        <h4 style={{textAlign:"center",  color:result_net_profit.match(/\(([^)]+)%\)/)?result_net_profit.match(/\(([^)]+)%\)/)[1]>0?"#287E8F":"#E85E76":null, paddingTop:10}}>{result_net_profit.split(" | ")[0]} {result_net_profit.match(/\(([^)]+)%\)/)?result_net_profit.match(/\(([^)]+)%\)/)[1]>0?"✔":" !":null}</h4>
                        <Line data={chartDataNetProfit} 
                            options= {
                                {
                                'plugins': {
                                    tooltip: {
                                    callbacks: {
                                            label: function(tooltipItem) {
                                                return  tooltipItem.raw + '$';
                                            }
                                        }
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        formatter: function(value, context) {
                                            return value + '$';
                                        },
                                    },
                                'filler': {
                                    'propagate': false,
                                    },
                                title: {
                                    'display': true,
                                    'text': (ctx) => result_net_profit.split(" | ")[1]
                                    }
                                },
                                'interaction': {
                                    'intersect': false,
                                    }
                                }
                                }
                            fill= {'start'}
                            label ='Dataset'
                        />
                    </div>
                </Col>
            </Row>
            <Row> <br/><br/><br/></Row>
            <Row>
            <Col xs={2} xl={2}>
                <div>
                    <h6 style={{textAlign:"center",  color:"#6B6666"}}>Gross Profit Margin</h6>
                    <Doughnut data={chartDataGrossProfitMargin} options={options5} />
                </div>
            </Col>
            <Col xs={1} xl={1}>
            </Col>
            <Col xs={2} xl={2}>
                <div>
                <h6 style={{textAlign:"center",  color:"#6B6666"}}>Operating Expense Ratio</h6>
                    <Doughnut data={chartDataOperatingExpenseRatio} options={options5} />
                </div>
            </Col>
            <Col xs={1} xl={1}>
            </Col>
            <Col xs={2} xl={2}>
                <div>
                    <h6 style={{textAlign:"center",  color:"#6B6666"}}>Net Profit Margin</h6>
                    <Doughnut data={chartDataNetProfitMargin} options={options5} />
                </div>
            </Col>
            <Col xs={4} xl={4}>
                    <Bar data={data7} options={options7} />
            </Col>
           
            </Row>
            <Row> <br/><br/><br/></Row>
            <Row>
            <Col xs={4} xl={4}>
            <div>
                <Bar data={chartData5} options={options6} />
            </div>
            </Col>
            <Col xs={5} xl={4}>
            <div>
                <Bar data={chartData6} options={options6} />
            </div>
            </Col>
            </Row>
            <br/>
            <Row>
            <Col xs={4} xl={4}>
            <div>
                <h3 style={{backgroundColor:"#D0E3E9"}}>Total Assets</h3>
                
                
                <div style={{marginTop:10, marginLeft:5}}>
                    <h5 >Current Assets</h5>
                    <div style={{marginTop:10}}>
                        <div style={{float:"left", backgroundColor: "#2B6E7C", width:5, height:85
                            }}>
&nbsp;
                        </div>
                        <div style={{float:"left", marginLeft:10}}>
                            <h6><b>234234</b></h6>
                            <h6>ABC</h6>
                            <h6 style={{marginTop:10}}><b>234234</b></h6>
                            <h6>ABC</h6>
                        </div>
                        <div style={{float:"left", marginLeft:200}}>
                            <h6><b>2234234</b></h6>
                            <h6>ABC</h6>
                            <h6 style={{marginTop:10}}><b>2234234</b></h6>
                            <h6>ABC</h6>
                        </div>
                    </div>
                </div>
            </div>
            </Col>
            <Col xs={4} xl={4}>
            <div>
                <h3 style={{backgroundColor:"#F8DCE1"}}>Total Liabilities</h3>
                
                
                <div style={{marginTop:10, marginLeft:5}}>
                    <h5 >Current Liabilities</h5>
                    <div style={{marginTop:10}}>
                        <div style={{float:"left", backgroundColor: "#E08F9B", width:5, height:85
                            }}>
&nbsp;
                        </div>
                        <div style={{float:"left", marginLeft:10}}>
                            <h6><b>234234</b></h6>
                            <h6>ABC</h6>
                            <h6 style={{marginTop:10}}><b>234234</b></h6>
                            <h6>ABC</h6>
                        </div>
                        <div style={{float:"left", marginLeft:200}}>
                            <h6><b>2234234</b></h6>
                            <h6>ABC</h6>
                            <h6 style={{marginTop:10}}><b>2234234</b></h6>
                            <h6>ABC</h6>
                        </div>
                    </div>
                </div>

            </div>
            </Col>
            </Row>
            <Row>
                
            </Row>
        </PageLayout>
    )
}