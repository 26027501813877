import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor, Button, Text} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { MaterialReactTable } from 'material-react-table';
import { IconField, IconTextarea, LabelField, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import { confirmAlert } from 'react-confirm-alert'; 

export default function CRM() {
    useEffect(() => {
        api_call();
    }, []); 

  const navigate = useNavigate();
  const [tableS, setTableS] = useState([])
  const [loading, setLoading] = useState(false);

  const fnToggleUser = (user_uuid, status, user) =>{
    confirmAlert({
        title: 'Confirmation!',
        message: 'Are you sure you want to ' + (status?'disable':'enable') + ' the user ' + user + '?',
        buttons: [
            {
            label: 'Yes',
            onClick: () => api_call(api.toggle_user_status, user_uuid)
            },
            {
            label: 'No'
            }
        ]
        });
    }
    
  const dict_class_css = {true:'INTLINTLRETAIL-class', false:'None-class'}
  const distinctClassValues = new Set();
  const filterFn = (rowData, col_name, value) => {
    return rowData.original.is_active_org === (value);
  };
  const customClassSortingFunction = (row1, row2) => {
    if (row1.original.is_active_org === row2.original.is_active_org) {
        return 0;
      } else {
        return (row1.original.is_active_org.toString()).localeCompare(row2.original.is_active_org.toString());
      }
  };
  
  const api_call = ( api_end_point = api.list_users, user_uuid=null) => {
    if  (localStorage.getItem("session_id")=== null)
      return
    setLoading(true)
    fetch(api.base_url + api_end_point, {
      method: "POST",
      body: JSON.stringify({
        user_uuid:user_uuid
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let dataset = data['result_json'];
        dataset.forEach(item => {
            item.active_inactive = item.is_active?
            <Button key={123} icon="archive" className="mc-btn red sm" onClick={()=>{fnToggleUser(item.user_uuid, item.is_active_org, item.user_name)}} >{"Disable"}</Button> // Placeholder for where we'll render our button
            :
            <Button key={123} icon="unarchive" className="mc-btn green sm" onClick={()=>{fnToggleUser(item.user_uuid, item.is_active_org, item.user_name)}} >{"Enable"}</Button>

            item.is_active_org=item.is_active
            item.is_active = <Text className={"mc-table-badge " + dict_class_css[item.is_active] }>{item.is_active?'Active':'In-Active'}</Text>
            distinctClassValues.add(item.is_active);
        });
        setTableS(data['result_json'])
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
    };

  const columns = [
    {accessorKey: 'user_name', header: 'User Name', size: 120},
    {accessorKey: 'title', header: 'Title', size: 80},
    {accessorKey: 'created_at', header: 'Created At', size: 80},
    {accessorKey: 'is_active', header: 'Active Status', size: 50, filterVariant: 'select',
        filterSelectOptions: [{
            value: true,
            text: <Text className={`mc-table-badge ` + dict_class_css[true]}>{'Active'}</Text>,
        },
        {
            value: false,
            text: <Text className={`mc-table-badge ` + dict_class_css[false]}>{'In-Active'}</Text>,
        }],
        filterFn: filterFn,
        sortingFn: customClassSortingFunction
    },

    {accessorKey: 'active_inactive', header: 'Enable / Disable User', size: 50}
  ]
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.breadcrumbuserlisting.title }>
                        {data?.breadcrumbuserlisting.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :"" }
                <Col xl={12}>
                    {tableS.length>0?
                        <MaterialReactTable
                            columns = { columns }
                            data = { tableS }
                            initialState={{
                                pagination: {
                                pageSize: 200,
                                },
                                showColumnFilters:true,
                                density: 'compact'
                            }}
                            //optionally override the default column widths
                            defaultColumn={{
                                maxSize: 400,
                                minSize: 1,
                                size: 100, //default size is usually 180
                            }}
                            enableColumnResizing
                            columnResizeMode="onChange" //default
                        />
                    : <Spinner animation="border" />}
                </Col>
            </Row>
        </PageLayout>
    )
}