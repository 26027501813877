import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";


export default function TasksCard({ title, name, height="500px" }) {

    const [dataSet, setDataSet] = useState(null)
    const [titleS, setTitleS] = useState(title)
    const [loading, setLoading] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [newTask, setNewTask] = useState(false)
    const [task, setTask] = useState(null)
    const [dueDate, setDueDate] = useState(null)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [activeUpdateLIst, setActiveUpdateLIst] = useState([])

    const api_call = () => {
        setLoading(true)
        fetch(api.base_url + api.task_list, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    setDataSet(data['result_json'])
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const create_task = () => {
        if(task === null ){
            setAlert('Missing Task')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        if(dueDate === null ){
            setAlert('Missing Date')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        setLoading(true)
        fetch(api.base_url + api.task_create, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name,
                task: task,
                due_date: dueDate,
                is_active: true
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    setDataSet(data['result_json'].concat(dataSet))
                }
                setLoading(false)
                setNewTask(false);
                setSuccess('Task Created!')
                setTimeout(()=>setSuccess(null), 3000)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const update_task = () => {
        if(task === null || task === '' ){
            setAlert('Missing Task')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        if(dueDate === null || dueDate === '' ){
            setAlert('Missing Date')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        setLoading(true)
        fetch(api.base_url + api.task_update, {
            method: 'POST',
            body: JSON.stringify({
                task_uuid: dataSet[editIndex].task_uuid,
                customer_name: name,
                task: task,
                due_date: dueDate
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    let var_data = dataSet
                    var_data[editIndex]['task'] = task
                    var_data[editIndex]['due_date'] = dueDate
                    setDataSet(var_data)
                    setNewTask(false);
                    trOnClick(null);
                    setSuccess('Task Updated!')
                    setTimeout(()=>setSuccess(null), 3000)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const update_task_checked = (task_uuid, is_active, data_index) => {
        setActiveUpdateLIst([...activeUpdateLIst, data_index])
        fetch(api.base_url + api.task_update, {
            method: 'POST',
            body: JSON.stringify({
                task_uuid: task_uuid,
                is_active: is_active
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                } else if (data['status'] === 'success') {
                    let var_data = dataSet
                    var_data[data_index]['is_active'] = is_active
                    setDataSet(var_data)
                    setNewTask(false);
                    trOnClick(null);
                    //setSuccess('Task Updated!')
                    //setTimeout(()=>setSuccess(null), 3000)
                }
                let var_activeUpdateList = activeUpdateLIst
                var_activeUpdateList.splice(var_activeUpdateList.indexOf(data_index), 1)
                setActiveUpdateLIst(var_activeUpdateList)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    useEffect(() => {
        api_call();
    }, []); 

    function trOnClick(index){
        setEditIndex(index)
    }

    let date = new Date();
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let formattedDate = year + "-" + month + "-" + day;

    return (
        <Box className="mc-card mc-mb25">
            <Heading as="h4" className="text-capitalize m-4"><Anchor href={"/notification"}>{titleS} <ArrowRight  size={24} /></Anchor></Heading>
            {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :"" }
            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}
            <Box className="thin-scrolling" style={{ height: height }}>
                <Table>
                    <Tbody className="mc-table-body even">
                        <Tr>
                            <Td>
                                <PlusSquare className={'mc-mt10 mc-mb10'} title='Add' style={{'cursor':'pointer'}} size={24} onClick = {()=>{setDueDate(null); setTask(null); setNewTask(!newTask); trOnClick(null);}} />

                                <Inbox className={'mc-mt10 mc-mb10 mc-ml20'} title='Active' style={{'cursor':((isActive)?'auto':'pointer')}} size={24} fill={(isActive)?'blue':'gray'} onClick={()=>{setIsActive(true)}} />

                                <Archive className={'mc-mt10 mc-mb10 mc-ml20'} title='Archived' style={{'cursor':((isActive)?'pointer':'auto')}} size={24} fill={(isActive)?'gray':'blue'} onClick={()=>{setIsActive(false)}} />

                                <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
                            </Td>
                        </Tr>
                        {newTask?
                            <Tr key={ 10000 }>
                                <Td title={ 10000 }>
                                    <div>
                                        <Input placeholder={'New Task'} onChange={(e)=>{setTask(e.target.value); }} />
                                        <div className="mc-float-left">
                                            <div className="mc-float-left">
                                                <LabelField type = 'date' fieldSize = "h-md" defaultValue={dueDate} onChange={(e)=>{setDueDate(e.target.value); }}/>
                                            </div>
                                            <CheckLg className="mc-mt10 mc-ml10" size={24} title='Ok' style={{'cursor':'pointer'}} onClick={()=>{create_task(); trOnClick(null); }} />
                                            <FileEarmarkX className="mc-mt10 mc-ml10" size={24} title='Cancel' style={{'cursor':'pointer'}} onClick={()=>{setNewTask(false)}} />
                                        </div>
                                    </div>
                                </Td>
                            </Tr>
                            :null
                        }
                        {dataSet?.map((item, index) => (
                            (item.is_active === isActive)?
                            <Tr key={ index }> 
                                <Td title={ index + 1 }>
                                    {editIndex===index? 
                                        <div>
                                            <Input defaultValue={item.task} onChange={(e)=>{setTask(e.target.value); }} />
                                            <div className="mc-float-left">
                                                <div className="mc-float-left">
                                                    <LabelField type = 'date' fieldSize = "h-md" defaultValue={item.due_date} onChange={(e)=>{setDueDate(e.target.value); }} />
                                                </div>
                                                <CheckLg className="mc-mt10 mc-ml10" size={24} title='Ok' style={{'cursor':'pointer'}} onClick={()=>{update_task(); }} />
                                                <FileEarmarkX className="mc-mt10 mc-ml10" size={24} title='Cancel' style={{'cursor':'pointer'}} onClick={()=>{setNewTask(false); trOnClick(null);}} />
                                            </div>
                                        </div>
                                    :
                                        <div onClick={(event)=>{if (event.detail===2) {setDueDate(item.due_date); setTask(item.task); setNewTask(false); trOnClick(index); } }}>
                                            <Box className="mc-table-check">
                                                <Input
                                                    type="checkbox" 
                                                    name={item.task}
                                                    checked={ activeUpdateLIst.indexOf(index)>-1?item.is_active:!item.is_active }
                                                    onChange={(e)=>{update_task_checked(item.task_uuid, !e.target.checked, index) }}
                                                />
                                                {(!item.is_active || activeUpdateLIst.indexOf(index)>-1)?<strike><Text>{ item.task }</Text></strike>:
                                                <Text style={{color:(item.due_date<=formattedDate?'red':null)}}>{ item.task }</Text>
                                                }
                                            </Box>
                                            <Box className="mc-table-check" style={{'textAlign':'center', 'display':'block'}}>
                                                {(!item.is_active || activeUpdateLIst.indexOf(index)>-1)?<strike><Text>{ item.due_date }</Text></strike>:<Text>{ item.due_date }</Text>}
                                            </Box>
                                        </div>
                                    }
                                </Td>
                            </Tr>:null
                        )
                        )}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}