import React, { useState, useEffect } from "react";
import { Box, Label, Icon } from "../elements";
import CardHeader from "./CardHeader";
import { LabelField } from "../fields";
import { RepSalesTable, RevenueItemTable } from "../tables";
import { Row, Col } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export default function RepSalesCard({ name }) {

    const [tableS, setTableS] = useState('');
    const [tableItem, setTableItem] = useState('');
    const [tableItemPrediction, setTableItemPrediction] = useState([]);
    const [productList, setProductList] = useState('');
    const [underPerformingProductList, setUnderPerformingProductList] = useState([]);
    const [overPerformingProductList, setOverPerformingProductList] = useState([]);
    const [classS, setClassS] = useState('All')
    const [limitS, setLimitS] = useState(25)
    const [offsetS, setOffsetS] = useState(0)
    const [nameAddressS, setNameAddress] = useState('')
    const [loading, setLoading] = useState(true);
    const [loading_item, setLoadingItem] = useState(true)
    const [firstTime, setFirstTime] = useState(true)
    const [fromMonth, setFromMonth] = useState(localStorage.getItem('fromMonth')?localStorage.getItem('fromMonth'):'Jan')
    const [fromDate, setFromDate] = useState(localStorage.getItem('fromDate')?localStorage.getItem('fromDate'):'01')
    const [toMonth, setToMonth] = useState(localStorage.getItem('toMonth')?localStorage.getItem('toMonth'):'Dec')
    const [toDate, setToDate] = useState(localStorage.getItem('toDate')?localStorage.getItem('toDate'):'31')

    useEffect(() => {
        // console.log(fromMonth)
        if(localStorage.getItem('fromMonth') !== fromMonth){
            localStorage.setItem('fromMonth', fromMonth);
        }
    }, [fromMonth]);
    useEffect(() => {
        if(localStorage.getItem('fromDate') !== fromDate){
            localStorage.setItem('fromDate', fromDate);
        }
    }, [fromDate]);
    useEffect(() => {
        if(localStorage.getItem('toMonth') !== toMonth){
            localStorage.setItem('toMonth', toMonth);
        }
    }, [toMonth]);
    useEffect(() => {
        if(localStorage.getItem('toDate') !== toDate){
            localStorage.setItem('toDate', toDate);
        }
    }, [toDate]);

    const paginationChange = i => {
            setOffsetS((i-1) * limitS)
    }

    const dotsMenuTableS =  {
        "dots": "more_horiz",
        "dropdown": [
            { "icon": "download", "text": "download", "onClick":()=>{exportToExcel(tableS.tbody, name + ' - Revenue Breakdown by Year & Rep'); } }
        ]
    };
    const dotsMenuTableItem =  {
        "dots": "more_horiz",
        "dropdown": [
            { "icon": "download", "text": "download", "onClick":()=>{exportToExcel(tableItem.tbody, name+' - Revenue Breakdown by Year & Item'); } }
        ]
    };
    const dotsMenuProductList =  {
        "dots": "more_horiz",
        "dropdown": [
            { "icon": "download", "text": "download", "onClick":()=>{exportToExcel(productList.tbody, name+' - Products not ordered'); } }
        ]
    };


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async (vdata, filename) => {
        const ws = XLSX.utils.json_to_sheet(vdata);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
    }

    const api_call = () => {
        setLoading(true);
        fetch(api.base_url + api.customer_sales_by_rep, {
            method: 'POST',
            body: JSON.stringify({
                name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setTableS(data['result_json']['table'])
                }
                setLoading(false);
                setFirstTime(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const api_call_under_performing_products = () => {
        setLoading(true);
        fetch(api.base_url + api.customer_under_performing_products, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setUnderPerformingProductList(data['result_json'])
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message);
            });
    }

    const api_call_over_performing_products = () => {
        setLoading(true);
        fetch(api.base_url + api.customer_top_performing_products, {
            method: 'POST',
            body: JSON.stringify({
                customer_name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setOverPerformingProductList(data['result_json'])
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message);
            });
    }

    const api_call_item = () => {
        setLoadingItem(true);
        fetch(api.base_url + api.customer_sales_by_item, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                fromDate: fromDate,
                fromMonth: fromMonth,
                toDate: toDate,
                toMonth: toMonth
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setTableItem(data['result_json']['table'])
                    // console.log(data['result_json']['table'])
                }
                setLoadingItem(false);
                setFirstTime(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
        fetch(api.base_url + api.customer_prediction_by_item, {
                method: 'POST',
                body: JSON.stringify({
                    name: name
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                    "Access-Control-Allow-Origin": "*"
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data['status'] === 'error') {
                        //     setAlertMessage(data['message']);
                    } else if (data['status'] === 'success') {
                        //    setAlertMessage('Signed in as ' + data['title']);
                        setTableItemPrediction(data['result_json'])
                        // console.log(data['result_json']['table'])
                    }
                    setLoadingItem(false);
                    setFirstTime(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
    }

    const api_call_product = () => {
        setLoadingItem(true);
        fetch(api.base_url + api.product_not_ordered, {
            method: 'POST',
            body: JSON.stringify({
                name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setProductList(data['result_json']['table'])
                }
                setLoadingItem(false);
                setFirstTime(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    useEffect(() => {
            api_call();
            api_call_item();
            api_call_product();
            api_call_under_performing_products();
            api_call_over_performing_products();
    }, []); 


    const funcCurrencyToFloatConversion = (val) => {
        if (val === null){
            return '';
        }
        if (val.startsWith('$')) {
            return parseFloat(val.replace(/[^0-9.-]+/g, "")).toString();
        }
        return val;
    }

    
    const customSortingFunction = (first_col, col, row1, row2) => {

        if (row1.original[col] === row2.original[col] || row2.original[first_col] === 'Total' || row1.original[first_col] === 'Total') {
            return 0;
          } else {
            let a = funcCurrencyToFloatConversion(row1.original[col])
            let b = funcCurrencyToFloatConversion(row2.original[col])
            if(!isNaN(a) && !isNaN(b)){
              return a - b;
            }
            return a.localeCompare(b, undefined, { numeric: true });
          }
      };
    

    const columns = (tableS.thead)?.map((th, index) => {
        return {
          accessorKey : tableS.tCol[index],
          header: th,
          sortingFn: (row1, row2) => {
            return customSortingFunction(tableS.tCol[0], tableS.tCol[index], row1, row2);
          },
          size:150
        };
      });

      const columns2 = [];

      // Assuming tableItem.thead is an array of headers and tableItem.tCol is an array of accessor keys
      if(tableItem !== '' && tableItem.thead !== undefined){
        tableItem.thead.forEach((th, index) => {
            // Before adding the 'Total' column, insert the 'Predicted Amount' column
            if (th === 'Total') {
                // Insert the Predicted Amount column before Total
                columns2.push({
                    accessorKey: 'predicted_amount', // This key should match the key used in your dataset for predicted amounts
                    header: 'Predicted Amount',
                    sortingFn: 'default', // You might want to define a sorting function if needed
                    size: 150 // Assuming a standard size; adjust as necessary
                });
            }
        
            // Add the current column
            columns2.push({
                accessorKey: tableItem.tCol[index],
                header: th,
                sortingFn: (row1, row2) => {
                    return customSortingFunction(tableItem.tCol[0], tableItem.tCol[index], row1, row2);
                },
                size: tableItem.tCol[index].includes('Description') ? 600 : 150
            });
        });
    }

      const columns3 = (productList.thead)?.map((th, index) => {
        return {
          accessorKey : productList.tCol[index],
          header: th,
          sortingFn: (row1, row2) => {
            return customSortingFunction(productList.tCol[0], productList.tCol[index], row1, row2);
          },
          size:((productList.tCol[index]).includes('Description')? 600 : 150)
        };
      });
      
    // console.log(columns2)

    const CustomDatePicker = (props) => {
        const [date, setDate] = useState(null);
      
        return (
          <p>test</p>
          
        );
      };

      const handleTableLoaded = () => {
        // Set the toggle density to dense
        this.setState({
          density: 'dense',
        });
      };

      function transform_dataset(dataTbody, tcol){
        console.log("Tbody",dataTbody, "tcol", tcol)
        const tableS_dataset = [];
        if(dataTbody !== undefined && tcol !== undefined){
        dataTbody?.forEach((item) => {
            const row = {};
        
            tcol.forEach((item2, index2) => {
                if (
                    dataTbody !== undefined && dataTbody.length > 0 &&
                    (dataTbody?.slice(-1)[0][Object.keys(dataTbody.slice(-1)[0])[index2]] !== null || 
                    Object.keys(dataTbody.slice(-1)[0])[index2].includes('Date') || Object.keys(dataTbody.slice(-1)[0])[index2].includes('Unit')  )
                ) {
                    row[item2] = (!isNaN(item[item2]) && item[item2] !== null)
                        ? parseFloat(item[item2]).toLocaleString('en-US', {style: 'currency', currency: 'USD',})
                        : item[item2];
                }
            });
        
            tableS_dataset.push(row);
        });
        }
        console.log('tableS_dataset',tableS_dataset)
        return tableS_dataset
      }

      function mergeDatasets(dataset1, dataset2) {
        // Convert dataset2 into a map for quick lookup
        const predictionsMap = new Map(dataset2.map(item => [item.item_code, item.predicted_amount]));
    
        // Map over dataset1 to include the predicted_amount if available
        const mergedData = dataset1.map(item => {
            if (predictionsMap.has(item.Item_Code)) {
                // Insert predicted_amount before Total
                return {
                    ...item,
                    predicted_amount: predictionsMap.get(item.Item_Code),
                    Total: item.Total,
                    Last_Date: item.Last_Date,
                    Unit_Price:item.Unit_Price
                };
            }
            return item;
        });
    
        return mergedData;
    }
    console.log('tbody', tableS.tbody, 'tcol', tableS.tCol)
    const tableS_dataset = transform_dataset(tableS.tbody, tableS.tCol)
    let tableItem_dataset = []
    console.log('tableItemPrediction',tableItemPrediction,'tableItem.tbody',tableItem.tbody,'tableItem.tCol',tableItem.tCol)
    if(tableItemPrediction !== undefined && tableItemPrediction.length >0 && tableItem.tbody !== undefined && tableItem.tbody.length > 0 && tableItem.tCol !== undefined && tableItem.tCol.length > 0 ){
        tableItem_dataset = mergeDatasets(transform_dataset(tableItem.tbody, tableItem.tCol), tableItemPrediction)
    }
    // console.log("Revenue Breakdown by Year & Rep",  tableS_dataset)
    console.log("main dataset:", transform_dataset(tableItem.tbody, tableItem.tCol), tableItem)
    console.log("Revenue Breakdown By Year & Item", tableItem_dataset)
    // console.log("Products Not Ordered", productList.tbody)
    // console.log("over performing products:", overPerformingProductList)
    // console.log("under performing products:", underPerformingProductList)
    return (
        
        <Row >
                
                {tableS.filter? tableS.filter.map((item, index)=> (
                    <Col key={ index }>
                    <Box className="mc-card">
                        <LabelField
                            type = { item.type }
                            label = { item.label }
                            option = { item.option }
                            placeholder = { item.placeholder }
                            labelDir = "label-col"
                            fieldSize="w-100 h-md"
                            onChange={(item.type1 === "show by") ? (e) => setLimitS(parseInt(e.target.value)) : (item.type1 === "class by") ? (e) => setClassS(e.target.value) : (e) => setNameAddress(e.target.value)}
                        />
                    </Box>
                    </Col>
                )) : ""}


                
                
            <Col xs={12} sm={12} xl={12}>
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            
            (tableS_dataset !== undefined && columns !== undefined )?
                <Box className="mc-card">
                
                <CardHeader 
                            title={ "Revenue Breakdown by Year & Rep" } dotsMenu={ dotsMenuTableS } 
                        />

                        <MaterialReactTable
                                columns = { columns }
                                data = { tableS_dataset }
                                initialState={{
                                    pagination: {
                                    pageSize: 20,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                    
                                }}
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                        />

            {/* <RepSalesTable
                    thead={tableS.thead}
                    tbody={tableS.tbody}
                    tcol={tableS.tCol}
                />  */}
             
                </Box>
                :"" 
            }
            </Col>
                
            <Col xs={12} sm={12} xl={12}>
                {loading_item ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            tableItem.thead?
                <Box className="mc-card">
                <CardHeader 
                            title={ "Revenue Breakdown by Year & Item" } dotsMenu={ dotsMenuTableItem }
                        />
                
                <Row xs={3} md={4} xl={12} className="mc-mb15">
                    <Col xs={4}  md={2} >
                        <LabelField onChange={(e) => setFromMonth(e.target.value)} value={fromMonth} fieldSize="w-100" option={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]} />
                    </Col>
                    <Col xs={4} md={2} xl={2}>
                        <LabelField onChange={(e) => setFromDate(e.target.value)} value={fromDate} fieldSize="w-100" option={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]} />
                    </Col>
                    <Col xs={1}  md={1} xl={1} style={{'textAlign':'center'}}>
                        <Label>-</Label>         
                    </Col>
                    <Col xs={4} md={2} xl={2}>
                        <LabelField onChange={(e) => setToMonth(e.target.value)} value={toMonth} fieldSize="w-100" option={["Dec", "Nov", "Oct", "Sep", "Aug", "Jul", "Jun", "May", "Apr", "Mar", "Feb", "Jan"]} />
                    </Col>
                    <Col xs={4} md={2} xl={2}>
                        <LabelField onChange={(e) => setToDate(e.target.value)}  value={toDate} fieldSize="w-100" option={["31", "30", "29", "28", "27", "26", "25", "24", "23", "22", "21", "20", "19", "18", "17", "16", "15", "14", "13", "12", "11", "10", "09", "08", "07", "06", "05", "04", "03", "02", "01"]} />
                    </Col>
                    <Col xs={1}  md={1} xl={1} style={{'textAlign':'center', "cursor":"pointer", "title":"Apply"}} onClick={api_call_item}>
                        <Icon type={ "sync" }/>
                    </Col>
                </Row>
                {(tableItem_dataset !== undefined && tableItem_dataset.length > 0 && columns2 !== undefined)?
                <MaterialReactTable
                                columns = { columns2 }
                                data = { tableItem_dataset }
                                initialState={{
                                    pagination: {
                                    pageSize: 20,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                                // onTableLoaded={handleTableLoaded}
                                // filterComponent= {(props) => <CustomDatePicker {...props} />}

                        />
                :null}
                {/* <RevenueItemTable
                    thead={ tableItem.thead }
                    tbody={ tableItem.tbody }
                    tcol={tableItem.tCol}
                />  */}
             
                </Box>
                :"" 
            }
            </Col>            

            <Col xs={12} sm={12} xl={12}>
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            (productList.tbody !== undefined && productList.tbody.length > 0 && columns3 !== undefined )?
                <Box className="mc-card">
                <CardHeader 
                            title={ "Products not ordered" } dotsMenu={ dotsMenuProductList } 
                        />


                        <MaterialReactTable
                                columns = { columns3 }
                                data = { productList.tbody }
                                initialState={{
                                    pagination: {
                                    pageSize: 20,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                        />
                </Box>
                :"" 
            }
            </Col>


            <Col xs={12} sm={12} xl={12}>
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            (underPerformingProductList !== undefined && underPerformingProductList.length>0)?
                <Box className="mc-card">
                <CardHeader 
                            title={ "Under Performing Products" } dotsMenu={ dotsMenuProductList } 
                        />


                        <MaterialReactTable
                                columns = { Object.keys(underPerformingProductList[0])?.map((th, index) => {
                                            return {
                                            accessorKey : th,
                                            header: th
                                            };
                                            }) 
                                        }
                                data = { underPerformingProductList }
                                initialState={{
                                    pagination: {
                                    pageSize: 20,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                        />
                </Box>
                :"" 
            }
            </Col>


            <Col xs={12} sm={12} xl={12}>
                {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            (overPerformingProductList !== undefined && overPerformingProductList.length>0)?
                <Box className="mc-card">
                <CardHeader 
                            title={ "Over Performing Products" } dotsMenu={ dotsMenuProductList } 
                        />
                        <MaterialReactTable
                                columns = { (Object.keys(overPerformingProductList[0]))?.map((th, index) => {
                                            return {
                                            accessorKey : th,
                                            header: th
                                            };
                                            })
                                        }
                                data = { overPerformingProductList }
                                initialState={{
                                    pagination: {
                                    pageSize: 20,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                        />
                </Box>
                :"" 
            }
            </Col>      




            </Row>
    )
}