import React, { useState, useEffect } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { LegendField, LegendTextarea, IconField } from "../../components/fields";
import { Item, Anchor, Box, Button, Image } from "../../components/elements";
import { CardLayout, TabCard, RolesCard, UserRoleCard, UserPermissionsCard } from "../../components/cards";
import { Breadcrumb, FileUpload } from "../../components"; 
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/myAccount.json";
import Spinner from 'react-bootstrap/Spinner'
import { Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

export default function MyAccount() {
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);

    const [optionsClass, setOptionsClass] = useState([]);
    const [optionsClass1, setOptionsClass1] = useState([]);
    const [optionsRep, setOptionsRep] = useState([]);
    const [optionsPriceGroup, setOptionsPriceGroup] = useState([]);
    const [selectedOptionsClass, setSelectedOptionsClass] = useState(null);
    const [selectedOptionsRep, setSelectedOptionsRep] = useState(null);
    const [selectedOptionsPriceGroup, setSelectedOptionsPriceGroup] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        doGetClass();
        doGetRep();
        doGetPriceGroup();
    }, []); 

    
    const handleSubmit = (e) => {
        e.preventDefault();
        doProfileUpdate();
    }; 

    const doGetClass = async () => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.get_class, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    if ( data['status'] === 'success' ){
                        setOptionsClass1(data['result_json'])
                    }
            })
            .catch((err) => {
                console.log(err.message);
            });
        
          const permissions = JSON.parse(localStorage.getItem('permissions'));
          
          const allowedValues = ["Read", "Write"];
          const filteredKeys = [];
          
          for (const key in permissions) {
            if (key.startsWith("Class ") && allowedValues.includes(permissions[key])) {
              const modifiedKey = key.replace("Class ", "");
              filteredKeys.push(modifiedKey);
            }
          }
          setOptionsClass(filteredKeys)
        setLoading(false)
    };
    const doGetRep = async () => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.get_rep, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    if ( data['status'] === 'success' ){
                        setOptionsRep(data['result_json'])
                    }
            })
            .catch((err) => {
                console.log(err.message);
            });
        setLoading(false)
    };
    const doGetPriceGroup = async () => {
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.get_price_group, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    if ( data['status'] === 'success' ){
                        setOptionsPriceGroup(data['result_json'])
                    }
            })
            .catch((err) => {
                console.log(err.message);
            });
        setLoading(false)
    };

    const doProfileUpdate = async () => {
        console.log(address)
        console.log(selectedOptionsPriceGroup)
        if(name === null || name === '' || address === null || address === '' || selectedOptionsClass === null || selectedOptionsClass === 'Select Option' || selectedOptionsRep === null || selectedOptionsRep === 'Select Option' || selectedOptionsPriceGroup === null || selectedOptionsPriceGroup === 'Select Option' ){
            setAlertMessage('Required fields are missing');
            return;            
        }
        setLoading(true)
        setAlertMessage('');
        await fetch(api.base_url+api.create_customer, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                AllAddress : address,
                defaultSalesmanId: optionsRep.find(item => item.label === selectedOptionsRep)?.value,
                defaultSalesman: selectedOptionsRep,
                GroupName: selectedOptionsPriceGroup,
                Class: selectedOptionsClass,
                group_id: optionsPriceGroup.find(item => item.label === selectedOptionsPriceGroup)?.value,
                class_id: optionsClass1.find(item => item.label === selectedOptionsClass)?.value
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    setAlertMessage( data['message'] );
                    if(data['status'] === 'success'){
                        localStorage.removeItem('tableS');
                        setIsSuccess(true);
                    }
            })
            .catch((err) => {
                console.log(err.message);
            });
        setLoading(false)
    };


    let valert;
    if (alertMessage !== '' ) {
        valert = <Alert key="abc123" variant="warning" onClose={() => setAlertMessage('')} dismissible>{alertMessage}</Alert>
    } else {
        valert = ""
    }
    const navigate = useNavigate();
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={()=>{navigate("/");}}></Button>
                    <Breadcrumb title={ data?.pageTitle2 }>
                        {data?.breadcrumb2.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs">
                            <Tab eventKey="profile" title="Customer" className="mc-tabpane profile">
                                <TabCard title="Customer">
                                <Box className="mc-register">
                                            <Form className="mc-register-form">
                                                    {valert}
                                                {data?.input2.map((item, index) => (
                                                    <p style={{marginBottom:"20px"}}>
                                                    <LegendField
                                                        key = { index }
                                                        icon = { item.icon }
                                                        type = { item.type }
                                                        classes = { item.fieldSize }
                                                        placeholder = { item.placeholder }
                                                        title = {item.placeholder.replace('Enter ', '')}
                                                        passwordVisible={item.passwordVisible}
                                                        onChange={(item.name === "name") ? (e) => setName(e.target.value) : (item.name === "address") ? (e) => setAddress(e.target.value) : "" }
                                                    />
                                                    </p>
                                                ))}
                                                    <p style={{marginBottom:"20px"}}>
                                                    <LegendField title="Class" fieldSize="w-100 h-sm" className="mb-4" option={optionsClass} onChange={(event)=>{setSelectedOptionsClass(event.target.value);}} />
                                                        {/* <Select options={optionsClass} value = {selectedOptionsClass} onChange={(selected)=>{setSelectedOptionsClass(selected);}} placeholder = {'Class'}  /> */}
                                                    </p>
                                                    <p style={{marginBottom:"20px"}}>
                                                        <LegendField title="Price Group" fieldSize="w-100 h-sm" className="mb-4" option={optionsPriceGroup.map(item => item.label)} onChange={(selected)=>{setSelectedOptionsPriceGroup(selected.target.value);}} />
                                                        {/* <Select options={optionsPriceGroup} value = {selectedOptionsPriceGroup} onChange={(selected)=>{setSelectedOptionsPriceGroup(selected);}} placeholder = {'Price Group'} /> */}
                                                    </p>
                                                    <p style={{marginBottom:"20px"}}>
                                                        <LegendField title="Rep" fieldSize="w-100 h-sm" className="mb-4" option={optionsRep.map(item => item.label)} onChange={(selected)=>{setSelectedOptionsRep(selected.target.value);}} />
                                                        {/* <Select options={optionsRep} value = {selectedOptionsRep} onChange={(selected)=>{setSelectedOptionsRep(selected);}} placeholder = {'Rep'} /> */}
                                                    </p>
                                                    {isSuccess?
                                                    <p style={{marginBottom:"20px"}}>
                                                    <Anchor href={"/customer-view?name=".concat(encodeURIComponent(name))} title={name} target={"_blank"}>
                                                        Go To Customer
                                                    </Anchor>
                                                    </p>:null
                                                    }
                                                    <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} onClick={handleSubmit}>{ loading ?  <Spinner animation="border" /> : data?.button.text }</Button>
                                            </Form>
                                        </Box>
                                </TabCard>
                            </Tab>
                        </Tabs>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}