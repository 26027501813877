import React from "react";
import { Box, Icon, Item, List, Text } from "./elements";
import Spinner from 'react-bootstrap/Spinner'

export default function Pagination({ limit, count, dataset_count, offsetS, paginationChange, loading }) {
    const rows = [];

    let i = (offsetS / limit) + 1;
    let max = Math.ceil(parseFloat(count) / parseFloat(limit));
    let cls = 'mc-paginate-item'
    let cls2 = 'mc-paginate-item active'

    if (i == 1) {
        rows.push(<Item key={1} className={cls2} onClick={event => paginationChange(1)} >{1}</Item>)
    } else {
        rows.push(<Item key={1} className={cls} onClick={event => paginationChange(1)} >{1}</Item>)
    }

    if (i > 2) {
        rows.push(<Item key={2} className={cls}>...</Item>)
    }

    if (i > 1) {
        rows.push(<Item key={i} className={cls2} onClick={event => paginationChange(i)} >{i}</Item>)
    }

    if ( max > i+1 ) {
        rows.push(<Item key={2} className={cls}>...</Item>)
    }

    if (max > i) {
        rows.push(<Item key={max} className={cls} onClick={event => paginationChange(max)} >{max}</Item>)
    }



    return (
        <Box className="mc-paginate">
            
            <Text className="mc-paginate-title">Showing <b>{dataset_count}</b> of <b>{count}</b> Results</Text>
            {loading ?
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" />
                </div>
                : ""}
            <List className="mc-paginate-list">
                <Item className="mc-paginate-item" onClick={event => paginationChange((offsetS / limit) > 0 ? (offsetS / limit) : 1 ) }>
                    <Icon type="chevron_left" />
                </Item>


                

                {  rows?.map((item, index) => (
                    item
                    ))
                
                }

                <Item className="mc-paginate-item" onClick={event => paginationChange(((offsetS / limit) + 2) < Math.ceil(parseFloat(count) / parseFloat(limit)) ? ((offsetS / limit) + 2) : Math.ceil(parseFloat(count) / parseFloat(limit)) ) }>
                    <Icon type="chevron_right" />
                </Item>
            </List>
        </Box>
    )
}