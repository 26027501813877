import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/master/customerView.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CustomerEmailCard } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function CustomerView() {
    
    const search = useLocation().search;
    const customer_name = new URLSearchParams(search).get('name')
    
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/");
    }

  return (
    <PageLayout>
      <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={customer_name}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-lg-5">
        <Row>
          <Col xl={9}>
            <CustomerInfoCard
              title={"Customer Info"}
              dotsMenu=""
              customerInfoData={""}
              name={customer_name}
            />
          </Col>

          <Col xl={3}>
            <TasksCard title={"Actionable Items"} name={customer_name} />
            <MeetingNotesCard title={"Meeting Notes"} name={customer_name} />
            <CustomerEmailCard title={"Customer Emails"} name={customer_name} />
          </Col>
        </Row>
      </CardLayout>
    </PageLayout>
  );
}
