import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";

export default function ProfileDropdown({ name, username, image, dropdown }) {
    const fullName = name!== null?name.split(' '):'';
    const initials = name !== null && fullName.length > 1 ? (fullName.shift().charAt(0) + fullName.pop().charAt(0)).toUpperCase() : fullName.length === 1 ? fullName[0][0] : '';
	

    return (
        <Dropdown className="mc-header-user">
            <Dropdown.Toggle className="mc-dropdown-toggle">
                <p data-letters={initials}></p>
                <DuelText title={ name } descrip={ username } size="xs" />
				
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="mc-dropdown-paper">
                {dropdown.map((item, index) => (
                    <Anchor
                        key={index}
                        href={item.path}
                        icon={item.icon}
                        text={item.text}
                        className="mc-dropdown-menu"
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}