import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import balance_sheet_accounts from "../../data/master/balance_sheet_accounts.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto';
import { Pie, Line, Doughnut, Bar } from 'react-chartjs-2';

export default function CRM() {
    const [infoData, setInfoData] = useState(localStorage.getItem('infoData')?JSON.parse(localStorage.getItem('infoData')):null);
    const [infoDataSales, setInfoDataSales] = useState(localStorage.getItem('infoDataSales')?JSON.parse(localStorage.getItem('infoDataSales')):null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [budgetEdit, setBudgetEdit] = useState(false);

    const [classS, setClassS] = useState(localStorage.getItem('classS')?localStorage.getItem('classS'):'All')
    const [nameAddressS, setNameAddress] = useState(localStorage.getItem('nameAddressS')?localStorage.getItem('nameAddressS'):'')
    const [changeStats, setChangeStats] = useState(false)
    // const [result_json_report_data, set_result_json_report_data] = useState([])
    // const [result_json_report_cols, set_result_json_report_cols] = useState([])
    const [result_data, set_result_data] = useState(null)

    const api_call = (report_name = "balance_sheet_current_year_to_date") => {
        setLoading1(true);
        fetch(api.base_url + api.report_kpis_reports, {
          method: "POST",
          body: JSON.stringify({
            "report_name":report_name
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + localStorage.getItem("session_id"),
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data["status"] === "error") {
              navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
            } else if (data["status"] === "success") {
                // set_result_json_report_data(data["result_json_report_data"]);
                // set_result_json_report_cols(data["result_json_report_cols"]);
                set_result_data(data);
            }
            setLoading1(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      };

    useEffect(() => {
            api_call();
    }, []);

  const navigate = useNavigate();

  function DataToTable(data) {
    const { result_json_report_data, result_json_report_cols } = data;
  
    // Extract period names from result_json_report_cols
    const periodNames = result_json_report_cols.slice(1).map(item => item.value.split(",")[0]);
  
    // Function to handle account names (assuming colID is null for names)
  
    const uniqueRowNumbers = [...new Set(result_json_report_data.map(item => item.rowNumber))];
    console.log(uniqueRowNumbers)
  
    function formatString(input) {
      // Check if the input is a valid number
      if (!isNaN(input) && input.trim() !== '') {
          // Convert the input to a number and format it with thousand commas
          return Number(input).toLocaleString();
      } else {
          // If it's not a valid number, return it as is
          return input;
      }
    }

    return (
      <table className="report-table">
        <thead>
          <tr>
            <th className="report-table-header">Heading</th>
            <th className="report-table-header">Label</th>
            {/* Render table header cells for each period with data */}
            {periodNames.map((period, index) => (
              <th key={index} className="report-table-header">{period}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Loop through data and render rows with account names */}
          {uniqueRowNumbers.map((row, index) => (
              <tr key={row} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                    <td key={0}>{result_json_report_data.filter(item => item.rowNumber == row && item.colID === null).map(item => (item.value.slice(item.value.indexOf(';')+1)).trim())}</td>
                {result_json_report_cols.map((row_col) => (
                    <td key={row_col.colID} className="report-table-cell">{result_json_report_data.filter(item => item.rowNumber == row && item.colID == row_col.colID).map(item => formatString((item.value.slice(item.value.indexOf(';')+1)).trim()) )}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
  
  const handleChange = (event) => {
    if (event.target.checked){
        api_call('balance_sheet_last_year_to_date');
    } else{
        api_call();
    }
  }
   
    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Breadcrumb title={ "Balance Sheet" }>
                        {data?.breadcrumbanalytics6.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
            </Col>
            </Row>
            <Row>
                <Col>
                    <input key={'a1'} type="checkbox" id="idAccount_Type" name="idAccount_Type" value={'Account_Type'} className={'mc-float-left mc-ml5 mc-mt15'} onChange={handleChange} />
                    <label style={{marginLeft:"5px"}} htmlFor="idAccount_Type" className={'mc-float-left mc-mt10'}>Last Year</label>
                </Col>
            </Row>
            <Row>
            <Col>
                {result_data?DataToTable(result_data):null}
            </Col>
            </Row>
        </PageLayout>
    )
}