import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
  Form,
  Input
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
// import data from "../../data/master/customerView.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import api from "../../data/master/api.json";
import ReactHtmlParser from 'html-react-parser'; 
import Spinner from 'react-bootstrap/Spinner'
import { alignPropType } from "react-bootstrap/esm/types";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';
import { Archive, Download, Files, Upload } from 'react-bootstrap-icons';
import {stateToHTML} from 'draft-js-export-html';
import { DotsMenu, DuelText, RoundAvatar } from "../../components";
import IconField from "../../components/fields/IconField"
import data from "../../data/master/message.json";

export default function CustomerView() {
    
    const search = useLocation().search;
    const customer_name = new URLSearchParams(search).get('name')
    const [tableS, setTableS] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState('');


    useEffect(() => {
      api_call()
        }, []);

        const api_call = () => {
          setLoading(true);
          fetch(api.base_url + api.customer_emails, {
              method: 'POST',
              body: JSON.stringify({
                  name: customer_name
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                  "Access-Control-Allow-Origin": "*"
              },
          })
              .then((response) => response.json())
              .then((data) => {
                  if (data['status'] === 'error') {
                      //     setAlertMessage(data['message']);
                  } else if (data['status'] === 'success') {
                      //    setAlertMessage('Signed in as ' + data['title']);
                      setTableS(data['result_json'])
                      setSelectedEmail(data['result_json'].length>0 ? data['result_json'][0].email_from:null)
                  }
                  setLoading(false);
              })
              .catch((err) => {
                  console.log(err.message);
              });
      }

      function getTimeDifference(dateString) {
        // Parse the provided date-time string
        const pastDate = new Date(dateString);
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const diffMilliseconds = now - pastDate;
    
        // Define the lengths of time intervals in milliseconds
        const minute = 1000 * 60;
        const hour = minute * 60;
        const day = hour * 24;
    
        // Calculate the time difference in days, hours, and minutes
        const days = Math.floor(diffMilliseconds / day);
        const hours = Math.floor((diffMilliseconds % day) / hour);
        const minutes = Math.floor((diffMilliseconds % hour) / minute);
    
        // Build a string to show the difference in a simplified format
        let result = '';
        if (days > 0) {
            result += days + 'd ';
        }
        if (hours > 0) {
            result += hours + 'h ';
        }
        if (minutes > 0) {
            result += minutes + 'm';
        }
    
        return result.trim();
    }

    function getUniqueRecords(data) {
        const uniqueMap = new Map();
    
        data.forEach(item => {
            const key = `${item.email_from}`;
            if (!uniqueMap.has(key)) {
                uniqueMap.set(key, item);
            }
        });
    
        return Array.from(uniqueMap.values());
    }
    const navigate = useNavigate();
    const back_clicked = () => {
        navigate("/customer-view".concat("?name=").concat(encodeURIComponent(customer_name)));
      }

  return (
    <PageLayout>
    <CardLayout className="mb-4">
      <Button icon = {'arrow_back'} className={'mc-mr5 mc-float-left'} onClick={back_clicked}></Button>
        <Breadcrumb title={customer_name}>
          {data?.breadcrumbmemo.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                item.path==="/customer-view"?
                <Anchor className="mc-breadcrumb-link" href={item.path+"?name="+encodeURIComponent(customer_name)}>
                  {item.text}
                </Anchor>
                :
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
    {loading?<Spinner animation="border" />:
            <Row>
                <Col md={5} xl={4}>
                    <CardLayout className="p-0">
                         <Box className="mc-message-user">
                          {/*  <Box className="mc-message-user-filter">
                                <IconField 
                                    type={ data?.search.type }
                                    icon={ data?.search.icon }
                                    classes={ data?.search.fieldSize }
                                    placeholder={ data?.search.placeholder }
                                />
                                <DotsMenu 
                                    dots={ data?.dots.icon }
                                    dropdown={ data?.dots.menu } 
                                />
                            </Box> */}
                            <List className="mc-message-user-list thin-scrolling">
                                {tableS?getUniqueRecords(tableS).map((item, index) => (
                                    <Item key={ index } className={`mc-message-user-item ${ ((item.email_from === selectedEmail)? "active" : "") }`}
                                        onClick={()=>{setSelectedEmail(item.email_from)}}
                                    >
                                        <RoundAvatar 
                                            src={ "images/avatar/01.webp" }
                                            alt={ "avatar" } 
                                            size={`xs ${ "" }`}
                                        />
                                        <DuelText 
                                            title={ item.email_from }
                                            timesTamp={ getTimeDifference(item.received_time) }
                                            descrip = { "" }
                                            size={`xs ${ "" }`}
                                            gap="4px" 
                                        />
                                        {/* { item.mark && <Text as="sup">{ item.badge }</Text> } */}
                                        {/* <DotsMenu dots={ item.more.icon } dropdown={ item.more.menu }  /> */}
                                    </Item>
                                )):null}
                            </List>
                        </Box>
                    </CardLayout>
                </Col>
                <Col md={7} xl={8}>
                    <CardLayout>
                        <Box className="mc-message-chat">
                            <Box className="mc-message-chat-header">
                                <RoundAvatar src="images/avatar/01.webp" alt="avatar" size="xs" />
                                <DuelText title={selectedEmail} descrip="" size="xs" gap="4px" />
                                <Box className="mc-message-chat-action-group">
                                    {/* {data?.actions.map((item, index) => (
                                        <Icon 
                                            key={ index } 
                                            type={ item.icon } 
                                            title={ item.title }
                                            onClick={ item.event } 
                                        />
                                    ))} */}
                                </Box>
                            </Box>
                            <List className="mc-message-chat-list thin-scrolling">
                                {tableS?tableS.filter(item => item.email_from === selectedEmail).map((item, index) => (
                                    <Item key={ index } className="mc-message-chat-item">
                                    {/* <Text key={ index }>{item.email_to}</Text> */}
                                        <RoundAvatar src={ "images/avatar/01.webp" } alt="avatar" size="mc-message-chat-user" />
                                        <Box className="mc-message-chat-group">
                                            <Text>To:</Text><Text className="mc-message-chat-datetime">{ item.email_to }</Text>
                                            <Text>CC:</Text><Text className="mc-message-chat-datetime">{ item.cc }</Text>
                                            <Text>Subject:</Text><Text className="mc-message-chat-datetime">{ item.subject }</Text>
                                            <Text>Received Time:</Text><Text className="mc-message-chat-datetime">{ item.received_time }</Text>
                                            {/* {chat.text.map((message, index) => ( */}
                                                {/* <Box key={ index } className="mc-message-chat-text"> */}
                                                    <Text key={ index }><div dangerouslySetInnerHTML={{ __html:  item.body  }} /></Text>
                                                    {/* {message.icon.map((icon, index) => (
                                                        <Icon key={ index } type={ icon } />
                                                    ))} */}
                                                {/* </Box> */}
                                            {/* ))} */}
                                           
                                        </Box>
                                    </Item>
                                )):null}
                            </List>
                        </Box>
                    </CardLayout>
                </Col>
            </Row>
    }
        </PageLayout>
  );
}
