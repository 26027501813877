import React, {useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/email/email.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, setMessage, setCountry, fetchEmail} from '../../actions';
import { Alert } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';

// Map Related Code End
export default React.memo(function LeadList() {
    const PREVIEW_TEXT = "CAUTION: This email originated from outside of the organization. Do not click links or open attachments unless you recognize the sender and know the content is safe."
    useEffect(() => {
        dispatch(fetchEmail());
      }, []);
    
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/");
    }

    const dispatch = useDispatch()
    const loading_item = useSelector((state) => state.loading);
    let tableItem = useSelector((state) => state.emails);
    const message = useSelector((state) => state.message);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async (vdata) => {
        const ws = XLSX.utils.json_to_sheet(vdata);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Excel Export' + fileExtension);
    }

const excluded_cols = ['body', 'Company_Org', 'Lead_UUID', 'Country_Code'];
const special_fileter_org = ['Company'];



      const filterFnCommonIncludes = (rowData, col_name, value) => {
        return rowData.original[col_name+'_Org'].toLowerCase().includes(value.toLowerCase());
      };
      const customNameSortingFunction = (row1, row2) => {
        if (row1.original.Company_Org === row2.original.Company_Org) {
            return 0;
          } else {
            return (row1.original.Company_Org).localeCompare(row2.original.Company_Org);
          }
      };
    const vleadColumn = (tableItem && tableItem !== undefined && tableItem.length > 0) ? (Object.keys(tableItem[0])).filter((th) => !excluded_cols.includes(th)).map((th, index) => {
        return {
        accessorKey : th,
        header: th.replace(/_/g, ' '),
        size:100,
        filterFn: special_fileter_org.includes(th)?filterFnCommonIncludes:null,
        sortingFn: special_fileter_org.includes(th)?customNameSortingFunction:null
        };
    }
  ) : [];

   tableItem.forEach(item => {
    //Check if the body_preview field exists and is a string
    if (typeof item.body_preview === 'string') {
        // Replace the specified string with an empty string
        item.body_preview = item.body_preview.replace(PREVIEW_TEXT, "");
    }
  })

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={'Email Dashboard'}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      {/* <CardLayout> */}
        <Row>
        <Col xs={12} sm={12} xl={12}>
            {loading_item? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:
            (message && message !== '')? 
            <div className="d-flex justify-content-center">
                <Alert key="abc123" variant="warning" onClose={() => {dispatch(clearMessage());}} dismissible>{message}</Alert>
            </div>: null }
                  <div className="mb-2"><b>Email List</b>
                  <span className="m-lg-4"></span>
                  <Button key={0} className="btn btn-secondary btn-sm m-2" onClick={()=>{dispatch(fetchEmail());}}>Refresh</Button>
                  <Button key={0} className="btn btn-secondary btn-sm m-2" onClick={()=>{exportToExcel(tableItem);}}>Download</Button>
                  </div>
                <MaterialReactTable
                    columns = { vleadColumn }
                    data = { tableItem
                          }
                    initialState={{
                        pagination: {
                        pageSize: 100,
                        },
                        showColumnFilters:true,
                        density: 'compact'
                    }}
                    //optionally override the default column widths
                    defaultColumn={{
                        maxSize: 400,
                        minSize: 1,
                        size: 100, //default size is usually 180
                    }}
                    enableColumnResizing
                    columnResizeMode="onChange" //default
                />
            </Col>
        </Row>
    </PageLayout>
  );
}
)
;
