import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner';
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'react-bootstrap-icons';

export default function MeetingNotesCard({ title, table, name }) {
    const PREVIEW_TEXT = "CAUTION: This email originated from outside of the organization. Do not click links or open attachments unless you recognize the sender and know the content is safe."
    const [tableS, setTableS] = useState(null);
    const [titleS, setTitleS] = useState(title)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const add_clicked = () => {
        navigate("/emails?".concat("name=").concat(encodeURIComponent(name)));
    }

    const edit_clicked = () => {
        console.log('edit is clicked')
    }

    const [dotsMenu1, setDotsMenu1] = useState({
        "dots": "more_horiz",
        "dropdown": [
            { "icon": "add", "text": "add", "onClick":add_clicked },
            { "icon": "download", "text": "View All", "onClick":add_clicked }
        ]
    })

    const [dotsMenu2, setDotsMenu2] = useState({
        "dots": "more_horiz",
        "dropdown": [
            { "icon": "edit", "text": "edit" },
            { "icon": "edit", "text": "View All" }
        ]
    })



    const api_call = () => {
        setLoading(true);
        fetch(api.base_url + api.customer_emails, {
            method: 'POST',
            body: JSON.stringify({
                name: name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    //     setAlertMessage(data['message']);
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    setTableS(data['result_json'])
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }



    useEffect(() => {
        api_call();
    }, []); 

    return (
        <Box className="mc-card thin-scrolling" style={{ height: "1480px" }}>
            <Heading as="h4" className="text-capitalize m-4"><Anchor href={"/emails?".concat("name=").concat(encodeURIComponent(name))}>{titleS} <ArrowRight  size={24} /></Anchor></Heading>
            {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :"" }

            {tableS?.map((item, index) => (
            
            <Box key={index} className="mc-card mc-mb25" >
                
            <div className="mc-mb10 pointer"  onClick={add_clicked}>
                { item.subject }
            </div>
            <div className="mc-mb10 pointer"  onClick={add_clicked}>
            {/* ReactHtmlParser */}
                {  (item.body_preview.replace(PREVIEW_TEXT, "")) }
            </div>
                
                <p><i>From: </i><i>{item.email_from}</i></p>
                <div style={{ wordWrap: 'break-word' }}>To: {(item.email_to)}</div>
                <p style={{ wordWrap: 'break-word' }}><i>CC: </i><i>{item.cc}</i></p>
                
            </Box>

            ))}


        </Box>
    )
}