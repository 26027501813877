import React, { useState } from "react";
import { Box, Text, Form, Image, Button, Heading } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import IconField from "../../components/fields/IconField";
import data from "../../data/master/register.json";
import { Alert } from "react-bootstrap";
import api from "../../data/master/api.json";

export default function Register() {
    const [full_name, setFullName] = useState('');
    const [user_name, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        doLogin(user_name, full_name, password, password2);
    }; 

    const doLogin = async (user_name, full_name, password, password2) => {
        if (user_name === "" || full_name === "" || password === "" || password2 === "") {
            setAlertMessage('Required information is missing!');
            return;
        }
        if (password !== password2) {
            setAlertMessage('Passwords are not matched!');
            return;
        }
        setAlertMessage('');
        await fetch(api.base_url + api.create_user, {
            method: 'POST',
            body: JSON.stringify({
                user_name: user_name,
                title: full_name,
                password: password
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                    setAlertMessage(data['message']);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };


    let valert;
    if (alertMessage !== '') {
        valert = <Alert key="abc123" variant="warning" onClose={() => setAlertMessage('')} dismissible>{alertMessage}</Alert>
    } else {
        valert = ""
    }

    return (
        <PageLayout>
        <Box className="mc-register">
            <Box className="mc-register-banner">
                <Image 
                    className="mc-register-banner-pattern" 
                    src={ data?.pattern.src } 
                    alt={ data?.pattern.alt } 
                />
                <Box className="mc-register-banner-content">
                    <Heading as="h2" className="mc-register-banner-title"></Heading>
                    <Text as="p" className="mc-register-banner-descrip"></Text>
                
                </Box>
                </Box>
                
                <Form className="mc-register-form">
                    
                    <Heading as="h4" className="mc-auth-title">{data?.title.from}</Heading>
                    {valert}
                {data?.input.map((item, index) => (
                    <IconField 
                        key = { index }
                        icon = { item.icon }
                        type = { item.type }
                        classes = { item.fieldSize }
                        placeholder = { item.placeholder }
                        passwordVisible={item.passwordVisible}
                        onChange={(item.name === "user_name") ? (e) => setUserName(e.target.value) : (item.name === "full_name") ? (e) => setFullName(e.target.value) : (item.name === "password") ? (e) => setPassword(e.target.value) : (item.name === "password2") ? (e) => setPassword2(e.target.value) : "" }
                    />
                ))}
                
                    <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type} onClick={handleSubmit}>{ data?.button.text }</Button>
   

            </Form>
            </Box>
            </PageLayout>
    )
}