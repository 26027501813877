import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { Item, Anchor, Button} from "../../components/elements";
import { DealsCard, CustomerInfoCard, MeetingNotesCard, EcommerceCard, SalesCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/crm.json";
import data_ecom from "../../data/master/ecommerce.json";
import api from "../../data/master/api.json";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { MaterialReactTable } from 'material-react-table';
import { IconField, IconTextarea, LabelField, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import { confirmAlert } from 'react-confirm-alert'; 

export default function CRM() {
    useEffect(() => {
        api_call();
    }, []); 

  const navigate = useNavigate();
  const [tableS, setTableS] = useState([])
  const [fromList, setFromList] = useState([])
  const [toList, setToList] = useState([])

  const [fromClassList, setFromClassList] = useState(['-- Any --'])
  const [toClassList, setToClassList] = useState(['-- Any --'])

  const [fromClass, setFromClass] = useState('-- Any --')
  const [toClass, setToClass] = useState('-- Any --')

  const [fromCustomer, setFromCustomer] = useState('-- Select From Customer --')
  const [toCustomer, setToCustomer] = useState('-- Select To Customer --')

  const [isBusy, setBusy] = useState(false);

  const [result_json_from_list, setResult_Json_From_List] = useState([])
  const [result_json_to_list, setResult_Json_To_List] = useState([])

  const fnApiCallSyncSalesFullRefresh = () =>{
    confirmAlert({
        title: 'Confirmation!',
        message: 'Are you sure you want to Synchronize CRM data?',
        buttons: [
            {
            label: 'Yes',
            onClick: () => api_call_sync_sales_full_refresh()
            },
            {
            label: 'No'
            }
        ]
        });
    }

  const fnDoMapping = () =>{
    if(fromCustomer === '-- Select From Customer --' || toCustomer === '-- Select To Customer --'){
        confirmAlert({
            title: 'Alert!',
            message: 'Please select From Customer and To Customer  ',
            buttons: [
                {
                label: 'Ok'
                }
            ]
            });
    }else{
        confirmAlert({
        title: 'Confirmation!',
        message: 'Are you sure to map "' + fromCustomer + '" to "' + toCustomer + '"?',
        buttons: [
            {
            label: 'Yes',
            onClick: () => api_call_do_mapping()
            },
            {
            label: 'No'
            }
        ]
        });
    }
  }


  const fnDeleteMapping = (Customer, Customer_2, v_fromClass) =>{
        confirmAlert({
        title: 'Confirmation!',
        message: 'Are you sure to delete mapping of "' + Customer + '" to "' + Customer_2 + '"?',
        buttons: [
            {
            label: 'Yes',
            onClick: () => api_call_delete_mapping(Customer, Customer_2, v_fromClass)
            },
            {
            label: 'No'
            }
        ]
        });
  }

  const api_call_do_mapping = () => {
    if  (localStorage.getItem("session_id")=== null)
      return
    setBusy(true);
    fetch(api.base_url + api.create_customer_mapping, {
      method: "POST",
      body: JSON.stringify({
        Customer:fromCustomer,
        Customer_2:toCustomer,
        Customer_Class:fromClass,
        Customer_2_Class:toClass
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFromCustomer('-- Select From Customer --');
        setToCustomer('-- Select To Customer --');
        api_call();
        api_call_fetch_customer_list();
        setBusy(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
    };

    const api_call_delete_mapping = (Customer, Customer_2, v_fromClass) => {
        if  (localStorage.getItem("session_id")=== null)
          return
        setBusy(true);
        fetch(api.base_url + api.delete_customer_mapping, {
          method: "POST",
          body: JSON.stringify({
            Customer:Customer,
            Customer_2:Customer_2,
            Customer_Class:v_fromClass
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + localStorage.getItem("session_id"),
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setFromCustomer('-- Select From Customer --');
            setToCustomer('-- Select To Customer --');
            api_call();
            api_call_fetch_customer_list();
            setBusy(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
        };
    
  const api_call = () => {
    if  (localStorage.getItem("session_id")=== null)
      return
    fetch(api.base_url + api.fetch_customer_mapping, {
      method: "POST",
      body: JSON.stringify({
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "bearer " + localStorage.getItem("session_id"),
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let dataset = data['result_json'];
        dataset.forEach(item => {
            item.Action = <Button key={123} icon="delete" className="mc-btn red sm" onClick={()=>{fnDeleteMapping(item['Customer'],item['Customer_2'], item['Customer_Class'])}} >{"Delete"}</Button>; // Placeholder for where we'll render our button
          });
        setTableS(data['result_json'])
      })
      .catch((err) => {
        console.log(err.message);
      });

      api_call_fetch_customer_list();

    };

    const api_call_sync_sales_full_refresh = () => {
        if  (localStorage.getItem("session_id")=== null)
          return
        fetch(api.base_url + api.sync_sales_full_refresh, {
          method: "POST",
          body: JSON.stringify({
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + localStorage.getItem("session_id"),
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then((data) => {
          })
          .catch((err) => {
            console.log(err.message);
          });
    
        };
    

    const api_call_fetch_customer_list = () => {
        if  (localStorage.getItem("session_id")=== null)
          return

          fetch(api.base_url + api.fetch_customer_mapping_list, {
            method: "POST",
            body: JSON.stringify({
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: "bearer " + localStorage.getItem("session_id"),
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setResult_Json_From_List(data['result_json_from_list'])
              setResult_Json_To_List(data['result_json_to_list'])
              setFromList(data['result_json_from_list'].map(item => item.name))
              setToList(data['result_json_to_list'].map(item => item.name))
            })
            .catch((err) => {
              console.log(err.message);
            });
        };

      function extractClassesFromName(json_list, name) {
          const item = json_list.find(item => item.name === name);
          if (item) {
            let list_a = (item.Class_List.map(cls => cls.class));
              list_a.unshift('-- Any --')
              return list_a
          } else {
            return ['-- Any --']
          }
        }

  const columns = [
    {accessorKey: 'Customer', header: 'From Customer', size: 120},
    {accessorKey: 'Customer_Class', header: 'From Class', size: 80},
    {accessorKey: 'Customer_2', header: 'To Customer', size: 120},
    {accessorKey: 'Customer_2_Class', header: 'To Class', size: 80},
    {accessorKey: 'Created_By', header: 'Created By', size: 80},
    {accessorKey: 'Created_AT', header: 'Created At', size: 80},
    {accessorKey: 'Action', header: 'Action', size: 50}
  ]
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Breadcrumb title={ data?.breadcrumbmapping.title }>
                        {data?.breadcrumbmapping.items.map((item, index) => (
                            <Item key={ index } className="mc-breadcrumb-item">
                                {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                            </Item>
                        ))}
                    </Breadcrumb>
                </Col>
                {
                    isBusy?
                <Row>
                    <Col>
                        <Spinner animation="border" />
                    </Col>
                </Row>:
                <>
                <Row>
                    <Col xl={6} lg={8} md={8} sm={8}>
                        <LabelField label="From Customer" fieldSize="w-100 h-md" labelDir="label-col mb-4" option={fromList} onChange={(event)=>{setFromCustomer(event.target.value); setFromClassList(extractClassesFromName(result_json_from_list, event.target.value))}} />
                    </Col>
                    <Col xl={6} lg={8} md={8} sm={8}>
                        <LabelField label="To Customer" fieldSize="w-100 h-md" labelDir="label-col mb-4" option={toList} onChange={(event)=>{setToCustomer(event.target.value); setToClassList(extractClassesFromName(result_json_to_list, event.target.value))}} />
                    </Col>
                    
                    <Col xl={4} lg={8} md={8} sm={8}>
                        <LabelField label="From Class" fieldSize="w-100 h-md" labelDir="label-col mb-4" option={fromClassList} onChange={(event)=>{setFromClass(event.target.value)}} />
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={8}>
                        <LabelField label="To Class" fieldSize="w-100 h-md" labelDir="label-col mb-4" option={toClassList} onChange={(event)=>{setToClass(event.target.value)}} />
                    </Col>

                    <Col xl={1} lg={3} md={3} sm={3}>
                        <Button key={ 123 } icon="save" className="mc-btn blue sm m-2 mt-4" onClick={()=>{fnDoMapping()}}> {"Save"} </Button>
                    </Col>
                    <Col xl={1} lg={3} md={3} sm={3}>
                        <Button key={ 123 } icon="save" className="mc-btn purple sm m-2 mt-4" onClick={()=>{fnApiCallSyncSalesFullRefresh()}}> {"Synchronize CRM Data"} </Button>
                    </Col>
                </Row>
                </>
                }
                <Col xl={12}>
                    {tableS.length>0?
                        <MaterialReactTable
                            columns = { columns }
                            data = { tableS }
                            initialState={{
                                pagination: {
                                pageSize: 200,
                                },
                                showColumnFilters:true,
                                density: 'compact'
                            }}
                            //optionally override the default column widths
                            defaultColumn={{
                                maxSize: 400,
                                minSize: 1,
                                size: 100, //default size is usually 180
                            }}
                            enableColumnResizing
                            columnResizeMode="onChange" //default
                        />
                    : <Spinner animation="border" />}
                </Col>
            </Row>
        </PageLayout>
    )
}