import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

  
  export const FuncexportToExcel3 = async (institutionRateDataSet, headers, tcol, filename) => {

    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();


    let worksheet = workbook.addWorksheet("Sheet1", {
      views: [{ state: "frozen", ySplit: 1 }],
  });
    FuncexportToExcel2Sheet(institutionRateDataSet, headers, tcol, worksheet);

 

    // Export the workbook to a Blob
    const buffer = await workbook.xlsx.writeBuffer();
    // Download the file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    const dt_YYMMDD_HHMMSS = getCurrentDateTime('YYMMDD-HHMMSS');
    const fileName2 = filename + ' - ' + dt_YYMMDD_HHMMSS + '.xlsx';

    saveAs(blob, fileName2);
  }

  export const FuncexportToExcel2Sheet = async (institutionRateDataSet, headers, tcol, worksheet) => {
    
    // Header Row
    worksheet.addRow(headers).height=30;
    const headerRowIndex = 1;
    const headerRow = worksheet.getRow(headerRowIndex);
    // worksheet.freezeRows(1);
    headerRow.alignment = { vertical:'middle', horizontal: 'center', wrapText: true };
    headerRow.font = { bold: true, size: 8, name: 'Tahoma', color: { argb: 'FFFFFFFF' } };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF000000' },
    };
    headerRow.border = {
      right: { style: 'thin', color: { argb: 'FFFFFF' } },
    };
 



    let column_formatting_arr = []
    tcol.forEach( function (item, index){
      if(item === 'Customer_Type')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 15 })
      else if (item === 'Account')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 30 })
      else if (item === 'Last_ship_date_category')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 33 })
      else if (item === 'Country_dividedby_State')
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 8 })
      else
        column_formatting_arr.push({ key: 'k'+index.toString(), width: 12 })
    })
     // Apply formatting and Width
     worksheet.columns = column_formatting_arr;


 // Add data of Report  
  (institutionRateDataSet).forEach( function (item_row, index){
    let row_data_arr = tcol.map((item2, index2) => (
                   (!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' )
                   ? item2.startsWith('percent')?parseFloat(item_row[item2])/100 :parseFloat(item_row[item2]):item_row[item2] 
                  //   ?parseFloat(item_row[item2]).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):(!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' && item2.startsWith('percent')?parseFloat(item_row[item2]).toFixed(2) + '%':item_row[item2])    
                  ))

    // let row_data_arr = Object.values(item_row)
    let row = worksheet.addRow(row_data_arr);


    // TotalStyling Start
    const totalOtherStyle = {
      font: { size: 8, name: 'Tahoma', bold: true },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F5F5F5' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }

    const totalCurrencyStyle = {
      numFmt: '#,##0.00',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F5F5F5' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { size: 8, name: 'Tahoma', bold: true },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }
    const totalPercentStyleRed = {
      numFmt: '0.0%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F5F5F5' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { size: 8, bold: true },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }
    const totalPercentStyleGreen = {
      numFmt: '0.0%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F5F5F5' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { size: 8, name: 'Tahoma', bold: true },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }

    // Styling Start
    const otherStyle = {
      font: { size: 8, name: 'Tahoma' },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }

    const currencyStyle = {
      numFmt: '#,##0.00',
      font: { size: 8, name: 'Tahoma' },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }
    const percentStyleRed = {
      numFmt: '0.0%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { size: 8 },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }
    const percentStyleGreen = {
      numFmt: '0.0%',
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' } // Change the color as needed (e.g., 'FFFF00' for yellow)
      },
      font: { size: 8, name: 'Tahoma' },
      border : {
        top: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
      }
    }


    

    tcol.map((item2, index2) => (
      item_row[tcol[0]] === 'Total'?
      (
      (!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' )
      ? item2.includes('percent')?
      item_row[item2]>=0?row.getCell(index2+1).style = totalPercentStyleGreen
      : item_row[item2]<0?row.getCell(index2+1).style = totalPercentStyleRed : null
      :row.getCell(index2+1).style = totalCurrencyStyle
        :row.getCell(index2+1).style = totalOtherStyle
      )
      :
      (
      (!isNaN(item_row[item2]) && item_row[item2] !== null && item_row[item2] !== '' )
      ? item2.includes('percent')?
      item_row[item2]>=0?row.getCell(index2+1).style = percentStyleGreen
      : item_row[item2]<0?row.getCell(index2+1).style = percentStyleRed : null
      :row.getCell(index2+1).style = currencyStyle
        :row.getCell(index2+1).style = otherStyle
      )
     
        ))
     row.height = 13

    // row.getCell(indexCol+1).style = firstDataRowDataColStyle;




 })
    

 
  };



  function getCurrentDateTime(format= 'YYMMDD-HHMMSS') {
    const date = new Date();
    let year = date.getFullYear().toString().slice(-2);
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let hour = ('0' + date.getHours()).slice(-2);
    let minute = ('0' + date.getMinutes()).slice(-2);
    let second = ('0' + date.getSeconds()).slice(-2);

    return `${year}${month}${day}-${hour}${minute}${second}`;
}