import React, { useContext, useState, useEffect, useRef } from 'react';
import { LanguageDropdown, WidgetDropdown, ProfileDropdown } from '../components/header';
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from '../context/Drawer';
import { ThemeContext } from '../context/Themes';
import { Logo } from '../components';
import data from "../data/master/header.json";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api from "../data/master/api.json";

export default function Header() {

    const { drawer, toggleDrawer } = useContext(DrawerContext);
    const { theme, toggleTheme } = useContext(ThemeContext);
    const searchRef = useRef();
    const [scroll, setScroll] = useState("fixed");
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const [notifyData, setNotifyData] = useState(localStorage.getItem('notifyData')?JSON.parse(localStorage.getItem('notifyData')):null);

    const searchLoc = useLocation().search;
    let search_term = new URLSearchParams(searchLoc).get('search-term')
    let forward = new URLSearchParams(searchLoc).get('forward')

    let v_session_id = localStorage.getItem('session_id');
    let v_title = localStorage.getItem('title');
    let v_user_name = localStorage.getItem('user_name');
    let v_user_uuid = localStorage.getItem('user_uuid');

    useEffect(() => {
        localStorage.setItem('notifyData', JSON.stringify(notifyData));
    }, [notifyData]);

    useEffect(() => {
        if (v_session_id == null || v_user_uuid == null)  {            
            localStorage.clear();
            navigate('/login?forward=' + (forward?forward:encodeURIComponent(window.location.pathname)) + window.location.search );
        }
    }, []); 

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) setScroll("sticky");
        else setScroll("fixed");
    });

    document.addEventListener('mousedown', (event) => {
        if (!searchRef.current?.contains(event.target)) {
            setSearch("");
        }
    })

    useEffect(() => {
          api_call();
    }, []); 

    const api_call = () => {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        fetch(api.base_url + api.list_notifications, {
            method: 'POST',
            body: JSON.stringify({
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    if(forward)
                        navigate('/logout?forward=' + forward )
                    else
                        navigate('/logout')
                } else if (data['status'] === 'success') {

                    let var_items = []
                    data['result_json'].forEach(function(row) {
                        let note = row['notification'] + ' | ' + row['customer_name']
                        var_items.push({
                    "path": "/customer-view?name=".concat(encodeURIComponent(row['customer_name'])),
                    "addClass": "active",
                    "notify": { "image": "images/avatar/010.png", "variant": "red", "icon": "task" },
                    "note": note,
                    "longMoment": row['date'],
                    "more": {
                        "icon": "more_vert",
                        "menu": [
                            { "icon": "mark_chat_read", "text": "mark as unread" }
                            ]
                        }
                    })
                      });

                let var_notifyData = {
                "icon": "notifications",
                "title": "notification",
                "addClass": "notify",
                "badge": { "text": data['result_json'].length, "variant": "primary" },
                "dropdown": {
                    "title": "notifications",
                    "dotsMenu": {
                        "dots": "settings",
                        "dropdown": [
                            { "icon": "drafts", "text": "mark all as unread" }
                        ]
                    },
                    "button": { 
                        "path": "/notification", 
                        "label": "view all notification" 
                    },
                    "items": var_items
                        }
                    }
                    setNotifyData(var_notifyData)
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }


    return (
        <Section as="header" className={`mc-header ${ scroll }`}>
            <Logo 
                src = { data?.logo.src }
                alt = { data?.logo.alt }
                name = { data?.logo.name }
                href = { data?.logo.path } 
            />
            <Box className="mc-header-group">
                <Box className="mc-header-left">
                    <Button 
                        icon={ data?.search.icon } 
                        className="mc-header-icon search" 
                        onClick={()=> setSearch("show")}
                    />
                    <Button 
                        icon={ drawer ? "menu_open" : "menu" } 
                        className="mc-header-icon toggle" 
                        onClick={ toggleDrawer } 
                    />

                    <Box className={`mc-header-search-group ${ search }`}>
                        <form className="mc-header-search" ref={ searchRef } action="/search" method="get">
                            <Button type={"submit"} className="material-icons">{ data?.search.icon }</Button>
                            <Input type="search" name="search-term" placeholder={ data?.search.placeholder } defaultValue={search_term?search_term:''} />
                            <Input type="hidden" name="LIMIT" defaultValue="10" />
                            <Input type="hidden" name="OFFSET" defaultValue="0" />
                        </form>
                    </Box>
                </Box>
                <Box className="mc-header-right">
                    <Button 
                        icon={ theme }
                        title={ data.theme.title }
                        onClick={ toggleTheme }
                        className={`mc-header-icon ${ data.theme.addClass }`}
                    />
                    <WidgetDropdown 
                        icon={ notifyData?.icon }
                        title={ notifyData?.title }
                        badge={ notifyData?.badge }
                        addClass={ notifyData?.addClass }
                        dropdown={ notifyData?.dropdown }
                    />
                    <ProfileDropdown 
                        name={v_title }
                        image={ data.profile.image }
                        username={ v_user_name }
                        dropdown={ data.profile.dropdown }
                    />
                </Box>
            </Box>
        </Section>
    );
}

