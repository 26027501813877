import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider} from 'react-redux';
import {stateReducer} from './stateReducer';
import { configureStore } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';

const store = configureStore({reducer: stateReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk)
});

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );