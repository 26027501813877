import React, {useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import LabelTextarea from "../../components/fields/LabelTextarea";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/lead/lead.json";
import { CustomerInfoCard, MeetingNotesCard, TasksCard, CardHeader } from "../../components/cards";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch} from "react-redux";
import {fetchLead, clearMessage, setMessage, setCountry, fetchSampleRequestReport} from '../../actions';
import { Alert } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import LeadListMap from './LeadListMap';

// Map Related Code End
export default React.memo(function LeadList() {


    useEffect(() => {
        dispatch(fetchSampleRequestReport());
      }, []);

    
    const navigate = useNavigate();
    const back_clicked = () => {
      navigate("/");
    }

    const dispatch = useDispatch()
    const delivery_list = useSelector((state) => state.sample_order_report);
    const loading_item = useSelector((state) => state.loading);
    const country_code = useSelector((state) => state.country_code);
    console.log(country_code)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async (vdata) => {
        const ws = XLSX.utils.json_to_sheet(vdata);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Excel Export' + fileExtension);
    }

  const v_delivery_list_Column = delivery_list.length > 0 ? (Object.keys(delivery_list[0])).filter((th) => th !== "Country_Code").map((th, index) => {
    return {
    accessorKey : th,
    header: th.replace(/_/g, ' '),
    size:100
    };
}
) : [];
 
 

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={'All Sample Requests'}>
          {data?.breadcrum_all_sample_requests.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <Button key={0} className="btn btn-secondary btn-sm m-2 mb-4" onClick={()=>{exportToExcel(delivery_list);}}>Download</Button>
        <Row>
            <Col xs={12} sm={12} xl={12}>
            {loading_item? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>:null}
                                <Row>
                                  <Col>
                                  <MaterialReactTable
                                  columns = { v_delivery_list_Column }
                                  data = { delivery_list }
                                  initialState={{
                                      pagination: {
                                      pageSize: 100,
                                      },
                                      showColumnFilters:true,
                                      density: 'compact'
                                  }}
                                  //optionally override the default column widths
                                  defaultColumn={{
                                      maxSize: 400,
                                      minSize: 1,
                                      size: 100, //default size is usually 180
                                  }}
                                  enableColumnResizing
                                  columnResizeMode="onChange" //default
                          />
                          </Col>
                                </Row>
            </Col>
        </Row>
    </PageLayout>
  );
}
)
;
