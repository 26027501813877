import React, { useState, useEffect } from "react";
import { Box, Heading, Anchor, Icon, Text, Input,  } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col, Alert } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import ReactHtmlParser from 'html-react-parser'; 
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, Pen, Save } from 'react-bootstrap-icons';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { IconField, IconTextarea, LabelTextarea, LegendField, LegendTextarea } from "../../components/fields";
import { FileUploader } from "react-drag-drop-files";

export default function TasksCard({ product }) {

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isActive, setIsActive] = useState(product?product.is_active:true)

    const [productUUID, setProductUUID] = useState(product?product.product_uuid:null)
    const [productName, setProductName] = useState(product?product.product_name:"")
    const [imgName, setImgName] = useState(product?product.img_name:"")
    const [addImgName, setAddImgName] = useState(product?product.additional_img_name:"")
    
    const [img, setImg] = useState(null)
    const [addImg, setAddImg] = useState(null)

    const [miscInputField, setMiscInputField] = useState(product?product.misc_input_field:"")
    const [productDescription, setProductDescription] = useState(product?product.product_description:"")
    const [longDescription1, setLongDescription1] = useState(product?product.long_description_1:"")
    const [longDescription2, setLongDescription2] = useState(product?product.long_description_2:"")
    const [busy, setBusy] = useState(true);
    const [token, setToken] = useState(null);
    const handleCheckboxChange = (event) => {
        setIsActive(event.target.checked);
    }
    const navigate = useNavigate();
    const fileTypes = ["JPG", "PNG", "GIF", "HEIC"];

    const new_product = () => {
        setProductUUID(null)
        setProductName("")
        setMiscInputField("")
        setProductDescription("")
        setLongDescription1("")
        setLongDescription2("")
        setImgName("")
        setAddImgName("")
        setImg(null)
        setAddImg(null)
        setIsActive(true)
    }

    function api_call_get_token() {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
        fetch(api.base_url + api.get_token, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        }).then((response) => response.json())
        .then((data) => {
          if (data["status"] === "error") {
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
          } else if (data["status"] === "success") {
            setToken(data.token);
          }    
    })
        .catch((err) => {
            console.log(err.message);
        });
      }

    const update_product = () => {
        
        if(productName === null || productName === '' ){
            setAlert('Missing Product Name')
            setTimeout(()=>setAlert(null), 3000)
            return
        }
        setLoading(true)
        fetch(api.base_url + api.save_product, {
            method: 'POST',
            body: JSON.stringify({
                "productUUID":productUUID,
                "productName":productName,
                "imgName":imgName,
                "miscInputField":miscInputField,
                "productDescription":productDescription,
                "longDescription1":longDescription1,
                "longDescription2":longDescription2,
                "addImgName":addImgName,
                "isActive":isActive
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    setAlert(data['message'])
                    setTimeout(()=>setAlert(null), 3000)
                } else if (data['status'] === 'success') {
                    setSuccess(data['message'])
                    setTimeout(()=>setSuccess(null), 3000)
                    setProductUUID(data['product_uuid'])
                    // list_product()
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false)
            });

    }

    const api_call2 = (image, set_img_name) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("name", "products");    
        formData.append("folder", "products");
        formData.append(image.name, image);
      
        fetch(api.base_url + api.mobile_upload_file, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem('session_id')
            },
        })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
        //   api_call(false);
            if (data['status'] === 'error') {
                //     setAlertMessage(data['message']);
            } else if (data['status'] === 'success') {
                //    setAlertMessage('Signed in as ' + data['title']);
                console.log(data['file_name'])
                set_img_name(data['file_name'])
            }          
        })
        .catch((err) => {
            console.log(err.message);
            setLoading(false);
        });
      }

    const handleChangeUploadFiles = (file) => {
          for(let index = 0; index < file.length; index++){
            api_call2(file[index]);
          //   setSelectedImage(file[index])
          }
          if(file.length === undefined){
              api_call2(file, setImgName);
          }
    }
    const handleChangeUploadFiles2 = (file) => {
        for(let index = 0; index < file.length; index++){
          api_call2(file[index]);
        //   setSelectedImage(file[index])
        }
        if(file.length === undefined){
            api_call2(file, setAddImgName);
        }
  }





  
    const api_call_get_files = (file_name, setFileDict) => {
        console.log('going to fetch file')
      fetch((api.get_mobile_bucket_object_url +"products%2F" + "products" + "%2F" + file_name), {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('response of get file')
        console.log(data)
        var file_dict = {};
        var file_name = data['name'].split('/').pop();
        file_dict = {timeCreated:data['timeCreated'], 'file_name':file_name, title: file_name, caption: file_name};
  
        fetch(data['mediaLink'], {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + token
            },
        })
        .then(resp => resp.blob())
        .then((blob ) => {
          file_dict['url'] = URL.createObjectURL(blob)
          setFileDict(file_dict);
        })
      .catch((err) => {
          console.log(err.message);
      });
    })
    .catch((err) => {
        console.log(err.message);
      });
  }

    useEffect(() => {
        api_call_get_token();
        //api_call();
        setProductUUID(product?product.product_uuid:null)
        setProductName(product?product.product_name:"")
        setMiscInputField(product?product.misc_input_field:"")
        setProductDescription(product?product.product_description:"")
        setLongDescription1(product?product.long_description_1:"")
        setLongDescription2(product?product.long_description_2:"")
        setImgName(product?product.img_name:"")
        setAddImgName(product?product.additional_img_name:"")
        setIsActive(product?product.is_active:true)
        console.log('product is:')
        console.log(product)
    }, [product]);

    useEffect(() => {
        api_call_get_files( imgName, setImg);
    }, [imgName]);

    useEffect(() => {
        api_call_get_files( addImgName, setAddImg);
    }, [addImgName]);

    return (
        <Box className="mc-card mc-mb25">

            {alert?<Alert key={ 1 } variant={ 'danger' } >{alert} </Alert>:null}
            {success?<Alert key={ 1 } variant={ 'success' } >{success} </Alert>:null}

            {loading ?<Spinner animation="border" /> : <Save className={'mc-mt10 mc-mb10 mc-ml20 mb-4'} title='Save' style={{'cursor':'pointer'}} size={24} onClick={update_product}/>}
            <PlusSquare className={'mc-mt10 mc-mb10 mc-ml20 mb-4'} title='New' style={{'cursor':'pointer'}} size={24} onClick={new_product}/>
            <input type="checkbox" checked={isActive} onChange={handleCheckboxChange} style={{'marginLeft':20, marginRight:5}} />
            <label>Active</label>

            <Row xs={1} md={2} xl={3}>
                <Col>
                    <LegendField type="text" fieldSize="w-100 h-sm" className="mb-4" title="Product Name" placeholder={"Product Name"} value={productName} onChange={(e)=>{setProductName(e.target.value); }}/>
                    <FileUploader key={0} name="myImage" types={fileTypes} multiple={false} handleChange={handleChangeUploadFiles} />
                    <img alt="No Preview Available!" src={img?img.url:null} style={{maxWidth: "100%"}}/>
                </Col>
                <Col>
                    <LegendField type="text" fieldSize="w-100 h-sm" className="mb-4" title="Misc. Input Field" placeholder={"Misc. Input Field"} value={miscInputField} onChange={(e)=>{setMiscInputField(e.target.value); }}/>
                    <FileUploader key={0} name="myImage" types={fileTypes} multiple={false} handleChange={handleChangeUploadFiles2} />
                    <img alt="No Preview Available!" src={addImg?addImg.url:null} style={{maxWidth: "100%"}}/>
                </Col>
                <Col>
                    <div className="mb-4"><LegendTextarea fieldSize="w-100 h-text-sm" title="Product Description" placeholder={"Product Description"} value={productDescription} onChange={(e)=>{setProductDescription(e.target.value); }}/></div>
                    <div className="mb-4"><LegendTextarea fieldSize="w-100 h-text-sm" title="Long Description 1" placeholder={"Long Description 1"} value={longDescription1} onChange={(e)=>{setLongDescription1(e.target.value); }}/></div>
                    <div className="mb-4"><LegendTextarea fieldSize="w-100 h-text-sm" title="Long Description 2" placeholder={"Long Description 2"} value={longDescription2} onChange={(e)=>{setLongDescription2(e.target.value); }}/></div>
                </Col>
            </Row>
        </Box>
    )
}
