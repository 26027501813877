import React, { useState, useEffect } from "react";
import { Box, Text, Anchor } from "../elements";
import CardHeader from "./CardHeader";
// import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise } from 'react-bootstrap-icons';
import { MaterialReactTable } from 'material-react-table';
// import { Pagination } from "@mui/material";

export default function DealsCard({ title, dotsMenu, table, setSelectedCustomer, setChangeStats }) {

    const [tableS, setTableS] = useState(localStorage.getItem('tableS')?JSON.parse(localStorage.getItem('tableS')):'')
    const [titleS, setTitleS] = useState('Customers')
    const [classS, setClassS] = useState(localStorage.getItem('classS')?localStorage.getItem('classS'):'All')
    const [limitS, setLimitS] = useState(localStorage.getItem('limitS')?localStorage.getItem('limitS'):2000)
    const [offsetS, setOffsetS] = useState(localStorage.getItem('offsetS')?localStorage.getItem('offsetS'):0)
    const [nameAddressS, setNameAddress] = useState(localStorage.getItem('nameAddressS')?localStorage.getItem('nameAddressS'):'')
    const [loading, setLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true)
    // console.log(tableS.tbody)
    // console.log(tableS.thead)
     useEffect(() => {
        if(localStorage.getItem('classS') !== classS){
            localStorage.setItem('classS', classS);
            setChangeStats(true);
        }
    }, [classS]); 
    useEffect(() => {
        localStorage.setItem('limitS', limitS);
    }, [limitS]); 
    useEffect(() => {
        localStorage.setItem('offsetS', offsetS);
    }, [offsetS]); 
    useEffect(() => {
        if(localStorage.getItem('nameAddressS') !== nameAddressS){
            localStorage.setItem('nameAddressS', nameAddressS);
            setChangeStats(true);
        }
    }, [nameAddressS]); 
    useEffect(() => {
        localStorage.setItem('tableS', JSON.stringify(tableS));
    }, [tableS]); 

    const navigate = useNavigate();
    const paginationChange = i => {
            setOffsetS((i-1) * limitS)
    }
    
    const api_call = () => {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        if(firstTime){
            setFirstTime(false);
            if(tableS !== ''){
                return;
            }
        }
        setLoading(true);
        fetch(api.base_url + api.customer_listing, {
            method: 'POST',
            body: JSON.stringify({
                limit: limitS,
                offset: offsetS,
                class: classS,
                nameAddress: nameAddressS
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                } else if (data['status'] === 'success') {
                    let dataset = data['result_json']['table'];
                    setTableS(dataset)
                }
                setLoading(false);
                setFirstTime(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
// //////////////////////////////////*|*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const distinctClassValues = new Set();
    const distinctDefaultSalesmanValues = new Set();
    const distinctGroupNameValues = new Set();

    const dict_class_css = {"H.DR":"HDR-class", "DOM PRO":"DOMPRO-class", "None":"None-class", "INTL:INTL;RETAIL":"INTLINTLRETAIL-class", "DOT COM":"DOTCOM-class", "INTL:INT'L;PROF":"INTLINTLPROF-class", "DOM RETAIL":"DOMRETAIL-class", "DOM CLOSEOUT":"DOMCLOSEOUT-class", "DOM PROF. RETAIL":"DOMPROFRETAIL-class"}
    const data2 = tableS['tbody']?tableS['tbody'].map((item) => {
        distinctClassValues.add(item.Class);
        distinctDefaultSalesmanValues.add(item.DefaultSalesman);
        distinctGroupNameValues.add(item.GroupName);
        let returnItem = item
        returnItem = { ...returnItem, 
            Class_Formatted: <Text className={"mc-table-badge " + dict_class_css[returnItem.Class] }>{returnItem.Class}</Text>,
            row_num : <Anchor href={"/customer-view?name=".concat(encodeURIComponent(item.name))} title={item.name}>{item.row_num}</Anchor>,
            name_formatted:<Anchor href={"/customer-view?name=".concat(encodeURIComponent(item.name))} title={item.name}>
            <p data-letters={item.name_initials}>{item.name}</p>
            </Anchor>
        };
        return returnItem;
      }):[];
// //////////////////////////////////*|*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    useEffect(() => {
            api_call();
    }, []); 



    useEffect(() => {
        if (!firstTime) {
            if (offsetS !== 0) {
                setOffsetS(0)
            } else {
                api_call();
            }
        }
    }, [limitS, classS]); 

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!firstTime) {
                if (offsetS !== 0) {
                    setOffsetS(0)
                } else {
                    api_call();
                }
            }
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [nameAddressS])

    const filterFn = (rowData, col_name, value) => {
        return rowData.original.Class.includes(value);
      };
    const filterFnCommon = (rowData, col_name, value) => {
        return rowData.original[col_name.replace(/_formatted$/, "")] === value;
      };
    const filterFnCommonIncludes = (rowData, col_name, value) => {
        return rowData.original[col_name.replace(/_formatted$/, "")].toLowerCase().includes(value.toLowerCase());
      };
    const customClassSortingFunction = (row1, row2) => {
        if (row1.original.Class === row2.original.Class) {
            return 0;
          } else {
            return (row1.original.Class).localeCompare(row2.original.Class);
          }
      };
      const customNameSortingFunction = (row1, row2) => {
        if (row1.original.name === row2.original.name) {
            return 0;
          } else {
            return (row1.original.name).localeCompare(row2.original.name);
          }
      };
      const CustomColumnFilter = () => {
        return <div />;
      };
    const columns = 
        [
            {accessorKey:"row_num",
                header: "#",
                size: 1,
                enableColumnFilter: false,
            },
            {accessorKey:"name_formatted",
                header: "Name",
                filterFn: filterFnCommonIncludes,
                sortingFn: customNameSortingFunction
            },
            {accessorKey:"AllAddress",
                header: "Address"
            },
            {accessorKey:"Class_Formatted",
                header: "Class",
                filterVariant: 'select',
                filterSelectOptions: [...distinctClassValues].sort().map((classValue) => ({
                    value: classValue,
                    text: <Text className={`mc-table-badge ` + dict_class_css[classValue]}>{classValue}</Text>,
                  })),
                  filterFn: filterFn,
                  sortingFn: customClassSortingFunction
            },
            {accessorKey:"DefaultSalesman",
                header: "Rep",
                filterVariant: 'select',
                filterSelectOptions: [...distinctDefaultSalesmanValues].sort().map((DefaultSalesman) => ({
                    value: DefaultSalesman,
                    text: DefaultSalesman,
                  })),
                filterFn: filterFnCommon
            },
            {accessorKey:"GroupName",
                header: "PriceClass",
                filterVariant: 'select',
                filterSelectOptions: [...distinctGroupNameValues].sort().map((GroupName) => ({
                    value: GroupName,
                    text: GroupName,
                  })),
                filterFn: filterFnCommon
            }
        ]

    return (
        <Box className="mc-card">
            <CardHeader title={titleS}  dotsMenu={dotsMenu} />

            {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :"" }
            <Row xs={1} sm={2} xl={4} className="mb-4">
                
                <Col style={{marginTop:'48px'}}>
                    <PlusSquare className={'mc-mt10 mc-mb10'} title='Add' style={{'cursor':'pointer'}} size={24} onClick = {()=>{navigate('/add-customer')}} />
                    <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
                </Col>
            </Row>

            {tableS.dataset_count?
            <MaterialReactTable
                                columns = { columns }
                                data = { data2 }
                                initialState={{
                                    pagination: {
                                    pageSize: 200,
                                    },
                                    showColumnFilters:true,
                                    density: 'compact'
                                }}
                                //optionally override the default column widths
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 1,
                                    size: 100, //default size is usually 180
                                }}
                                enableColumnResizing
                                columnResizeMode="onChange" //default
                            />
            : ""}
        </Box>
    )
}