import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Heading, Anchor, Icon, Box, Text, Input, Image, Button } from "../elements";
import { useLinkClickHandler } from "react-router-dom";
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise, FileMinus } from 'react-bootstrap-icons';

import { SortAlphaDown, SortAlphaUpAlt } from 'react-bootstrap-icons';
export default function DealsTable( {thead, tbody, tcol, fetch_detail_call_back, fn_remove_child, dontShowPlus} ) {
    const [sortField, setSortField] = useState(tcol[0]);
    const [sortOrder, setSortOrder] = useState('ASC');
    const [dataTbody, setDataTbody] = useState([]);
    const [busyIndex, setbusyIndex] = useState(-1);

    useEffect(()=> { setDataTbody(tbody); setbusyIndex(-1) }, [tbody]);

    const sortBy = (function () {
        var toString = Object.prototype.toString,
            // default parser function
            parse = function (x) { return x; },
            // gets the item to be sorted
            getItem = function (x) {
              var isObject = x != null && typeof x === "object";
              var isProp = isObject && this.prop in x;
              return this.parser(isProp ? x[this.prop] : x);
            };
            
        /**
         * Sorts an array of elements.
         *
         * @param  {Array} array: the collection to sort
         * @param  {Object} cfg: the configuration options
         * @property {String}   cfg.prop: property name (if it is an Array of objects)
         * @property {Boolean}  cfg.desc: determines whether the sort is descending
         * @property {Function} cfg.parser: function to parse the items to expected type
         * @return {Array}
         */
        return function sortby (array, cfg) {
          if (!(array instanceof Array && array.length)) return [];
          if (toString.call(cfg) !== "[object Object]") cfg = {};
          if (typeof cfg.parser !== "function") cfg.parser = parse;
          cfg.desc = !!cfg.desc ? -1 : 1;
          return array.sort(function (a, b) {
            a = getItem.call(cfg, a);
            b = getItem.call(cfg, b);
            return cfg.desc * (a < b ? -1 : +(a > b));
          });
        };
        
      }());

      const headerClicked = (col, sort) =>{
        setSortField(col);
        setSortOrder(sort);
        var conf = {};
        if (sort === 'DESC'){
            conf = { prop: col, parser: (t) => (!isNaN(t) && t !== null) ? parseFloat(t) : t, desc:"desc" };
        } else{
            conf = { prop: col, parser: (t) => (!isNaN(t) && t !== null) ? parseFloat(t) : t}
        }
        var data2 = sortBy(dataTbody.slice(0, dataTbody.length-1), conf).concat(dataTbody.slice(-1)[0]);
        setDataTbody(data2);
    }
console.log(window.innerHeight);
    return (
        <Box className="mc-table-responsive thin-scrolling" style={{ height: (window.innerHeight-230) }}>
            <Table className="mc-table cust-analytics1-table">
                <Thead className="mc-table-head primary sticky">
                    <Tr >
                        {thead.map((item, index) => (

                                <Th  key={ index } >{ item } <br/> <SortAlphaDown className={(sortField === tcol[index] && sortOrder === 'ASC')?"":" mc-hover"} size="14" color={(sortField === tcol[index] && sortOrder === 'ASC')?"blue":"yellow"} onClick={()=>{headerClicked(tcol[index], 'ASC')}} /> <SortAlphaUpAlt size="14" className={(sortField === tcol[index] && sortOrder === 'DESC')?"":"mc-hover"} color={(sortField === tcol[index] && sortOrder === 'DESC')?"blue":"yellow"} onClick={()=>{headerClicked(tcol[index], 'DESC')}} /></Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                    {dataTbody?.map((item, index) => (
                        <Tr key={ index }> 
                        {tcol.map((item2, index2) => (
                            <Td key={index2} className={ tcol[index2].startsWith('percent')? parseFloat(item[item2])>0?'green-rep1':parseFloat(item[item2])<0?'red-rep1':'':''}>
                                { (index2 === 0 )?
                                    busyIndex === index && item['icon'] === 'plus'? <ArrowClockwise className={'mc-mt10 mc-mb10'} title='Busy' style={{'cursor':'pointer', marginRight:"5px"}} size={14}   />
:
dontShowPlus===false?
                                    item['icon'] === 'plus' ? 
                                         <PlusSquare className={'mc-mt10 mc-mb10'} title='Expand' style={{'cursor':'pointer', marginRight:"5px"}} size={14} onClick = {()=>{ setbusyIndex(index); fetch_detail_call_back(item)} } />
                                     : item['icon'] === 'minus' ? <FileMinus className={'mc-mt10 mc-mb10'} title='Collapse' style={{'cursor':'pointer', marginRight:"5px"}} size={14} onClick = {()=>{setbusyIndex(-1); fn_remove_child(item, index)}} />
                                    : null : null
:null
                                }
                                {tcol[index2]==='Customer_Name'?
                                <Anchor href={"/customer-view?name=".concat(encodeURIComponent(item[item2]))} title={item[item2]} target={"_blank"}>
                                    {item[item2]}
                                </Anchor>
                                : 
                                (!isNaN(item[item2]) && item[item2] !== null && item[item2] !== '' && !tcol[index2].startsWith('percent')  ) ?parseFloat(item[item2]).toLocaleString('en-US', {style: 'currency',currency: 'USD',}):(!isNaN(item[item2]) && item[item2] !== null && item[item2] !== '' && tcol[index2].startsWith('percent')?parseFloat(item[item2]).toFixed(2) + '%':item[item2])
                                }
                            </Td>
                        ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    )
}