import React, { useState, useEffect } from "react";
import { Box } from "../elements";
import CardHeader from "./CardHeader";
import Pagination from "../Pagination";
import { LabelField } from "../fields";
import { DealsTable } from "../tables";
import { Row, Col } from "react-bootstrap";
import api from "../../data/master/api.json";
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router-dom';
import { ArrowRight, PlusSquare, Archive, Inbox, CheckLg, FileEarmarkX, ArrowClockwise } from 'react-bootstrap-icons';

export default function CustomerPriceListCard({ title, dotsMenu, table, setSelectedCustomer, setChangeStats }) {

    const [tableS, setTableS] = useState(localStorage.getItem('tableS')?JSON.parse(localStorage.getItem('tableS')):'')
    const [titleS, setTitleS] = useState('Customers')
    const [classS, setClassS] = useState(localStorage.getItem('classS')?localStorage.getItem('classS'):'All')
    const [limitS, setLimitS] = useState(localStorage.getItem('limitS')?localStorage.getItem('limitS'):200)
    const [offsetS, setOffsetS] = useState(localStorage.getItem('offsetS')?localStorage.getItem('offsetS'):0)
    const [nameAddressS, setNameAddress] = useState(localStorage.getItem('nameAddressS')?localStorage.getItem('nameAddressS'):'')
    const [loading, setLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true)

    useEffect(() => {
        if(localStorage.getItem('classS') !== classS){
            localStorage.setItem('classS', classS);
            setChangeStats(true);
        }
    }, [classS]); 
    useEffect(() => {
        localStorage.setItem('limitS', limitS);
    }, [limitS]); 
    useEffect(() => {
        localStorage.setItem('offsetS', offsetS);
    }, [offsetS]); 
    useEffect(() => {
        if(localStorage.getItem('nameAddressS') !== nameAddressS){
            localStorage.setItem('nameAddressS', nameAddressS);
            setChangeStats(true);
        }
    }, [nameAddressS]); 
    useEffect(() => {
        localStorage.setItem('tableS', JSON.stringify(tableS));
    }, [tableS]); 

    const navigate = useNavigate();
    const paginationChange = i => {
            setOffsetS((i-1) * limitS)
    }
    
    const api_call = () => {
        if  (localStorage.getItem("session_id")=== null)
            return
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth() + 1; // Months are zero-based
        const day = new Date().getUTCDate();
        const hour = new Date().getUTCHours();
        const minute = new Date().getUTCMinutes();
        const utcMinus10Date = new Date(Date.UTC(year, month - 1, day, hour, minute) - (10 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const hasForwardParam = window.location.href.includes('forward=');
        if  (localStorage.getItem("session_id_date") !== utcMinus10Date && !hasForwardParam)
            navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
    
        if(firstTime){
            setFirstTime(false);
            if(tableS !== ''){
                return;
            }
        }
        setLoading(true);
        fetch(api.base_url + api.customer_listing, {
            method: 'POST',
            body: JSON.stringify({
                limit: limitS,
                offset: offsetS,
                class: classS,
                nameAddress: nameAddressS
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('session_id'),
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['status'] === 'error') {
                    navigate('/logout?forward=' + encodeURIComponent(window.location.pathname + window.location.search ))
                } else if (data['status'] === 'success') {
                    //    setAlertMessage('Signed in as ' + data['title']);
                    let dataset = data['result_json']['table'];
                    // for (let i = 0; i < dataset.length; i++) {
                    //     dataset[i].id = i + 1;
                    //   }
                    // // Create a new array to store the dataset with the ID column added.
                    // let newDataset = [];
                    // // Add the `id` property to the beginning of each row in the new dataset.
                    // for (let i = 0; i < dataset.length; i++) {
                    //     newDataset.push({
                    //     id: dataset[i].id,
                    //     ...dataset[i]
                    //     });
                    // }
                    // // Replace the original dataset with the new dataset.
                    // dataset = newDataset;
                    setTableS(dataset)
                }
                setLoading(false);
                setFirstTime(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {
            api_call();
    }, [offsetS]); 



    useEffect(() => {
        if (!firstTime) {
            if (offsetS !== 0) {
                setOffsetS(0)
            } else {
                api_call();
            }
        }
    }, [limitS, classS]); 

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!firstTime) {
                if (offsetS !== 0) {
                    setOffsetS(0)
                } else {
                    api_call();
                }
            }
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [nameAddressS])

    return (
        <Box className="mc-card">

            {loading ? 
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
            :"" }
            <Row xs={1} sm={2} xl={4} className="mb-4">
                {tableS.filter? tableS.filter.map((item, index)=> (
                    <Col key={ index }>
                        <LabelField
                            type = { item.type }
                            label = { item.label }
                            option = { item.option }
                            placeholder = { item.placeholder }
                            labelDir = "label-col"
                            fieldSize="w-100 h-md"
                            value={item.type1 === "show by"?String(limitS).concat(' row'):item.type1 === "class by"?classS:nameAddressS}
                            onChange={(item.type1 === "show by") ? (e) => setLimitS(parseInt(e.target.value)) : (item.type1 === "class by") ? (e) => setClassS(e.target.value) : (e) => setNameAddress(e.target.value)}
                        />
                    </Col>
                )) : ""}

                <Col>
                    <PlusSquare className={'mc-mt10 mc-mb10'} title='Add' style={{'cursor':'pointer'}} size={24} onClick = {()=>{}} />
                </Col>                
                <Col>
                    <ArrowClockwise className={'mc-mt10 mc-mb10 mc-ml20'} title='Refresh' style={{'cursor':'pointer'}} size={24} onClick={api_call} />
                </Col>

            </Row>

            {tableS.thead ?
                <DealsTable
                    thead={tableS.thead}
                    tbody={tableS.tbody}
                    dict_class_css={{"H.DR":"HDR-class", "DOM PRO":"DOMPRO-class", "None":"None-class", "INTL:INTL;RETAIL":"INTLINTLRETAIL-class", "DOT COM":"DOTCOM-class", "INTL:INT'L;PROF":"INTLINTLPROF-class", "DOM RETAIL":"DOMRETAIL-class", "DOM CLOSEOUT":"DOMCLOSEOUT-class", "DOM PROF. RETAIL":"DOMPROFRETAIL-class"}}
                    setSelectedCustomer={setSelectedCustomer}
                /> :""
            }
            {tableS.count ?
                <Pagination
                    limit={limitS}
                    count={tableS.count}
                    dataset_count={tableS.dataset_count}
                    offsetS={offsetS}
                    paginationChange={paginationChange}
                    loading={loading}
                    
                />
                : ""
            }
        </Box>
    )
}